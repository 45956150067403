import * as React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../components/organisms/login";
import { CustomerAuthorizedRoute, EmployeeAuthorizedRoute, GuestRoute } from "./routesTypes";
import ScannerPage from "../components/organisms/scannerPage";
import Home from "../components/organisms/home";
import ListingPage from "../components/organisms/plp";
import EmployeeLogin from "../components/organisms/employee-login/EmployeeLogin";
import WelcomeSplashScreen from "../components/organisms/welcome-screen";
import EmployeeHomePage from "../components/organisms/employee-home-page";
import EmployeeCart from "../components/organisms/employee-cart";
import StoryTellingVideo from "../components/organisms/pdp";
import MyAccount from "../components/organisms/my-account";
import {
  FURNITURE_CHECKOUT_PATH,
  FURNITURE_DELIVERY_PATH,
  PRODUCT_PAGE_ROUTE_PATH,
  INITIAL_PAGE_ROUTE_PATH,
  LOGIN_ROUTE_PATH,
  SCAN_ROUTE_PATH,
  HOME_PAGE_ROUTE_PATH,
  EMPLOYEE_LOGIN_ROUTE_PATH,
  PLP_CAFETERIA_MENU_PAGE_ROUTE_PATH,
  EMPLOYEE_LANDING_PAGE_ROUTE_PATH,
  EMPLOYEE_CART_PAGE_ROUTE_PATH,
  MY_ACCOUNT_ROUTE_PATH,
  ORDER_DETAILS_ROUTE_PATH,
  MY_ACCOUNT_EDIT_PROFILE_PAGE_ROUTE_PATH,
  MY_ACCOUNT_BILLING_ADDRESS_LIST_PAGE_ROUTE_PATH,
  MY_ACCOUNT_SHIPPING_ADDRESS_LIST_PAGE_ROUTE_PATH,
  MY_ACCOUNT_EDIT_ADDRESS_PAGE_ROUTE_PATH,
  MY_ACCOUNT_ADD_NEW_ADDRESS_PAGE_ROUTE_PATH,
  EMPLOYEE_CHECKOUT_PAGE_ROUTE_PATH,
  ORDER_CONFIRMATION_PAGE_ROUTE_PATH,
  CART_PAGE_PATH,
  MY_ACCOUNT_MY_ADDRESSES_LIST_PAGE_ROUTE_PATH,
  CHECKOUT_ORDER_CONFIRMATION_PAGE,
  ORDER_SUCCESS_PAGE,
  ORDER_FAILURE_PAGE,
  CHECKOUT_PAYMENT_PATH,
  CHECKOUT_PAYMENT_CARD_FORM_PATH,
  SPECIAL_ORDER_CUSTOMER_CART_SCREEN_ROUTE_PATH,
  MANAGE_ORDER,
  VIEW_ORDER,
  APPROVAL_PENDING_ORDER_ROUTE_PATH,
  EMPLOYEE_DASHBOARD_ROUTE_PATH,
  CUSTOMER_PENDING_PAYMENT_ROUTE_PATH,
  REQUEST_ASSISTANCE_PATH,
  ASSISTANCE_PATH,
  NOTIFICATION_LISTING_ROUTE_PATH,
  TRAINING_RESOURCES_ROUTE_PATH,
  LOGIN_TERMS_AND_CONDITION_ROUTE_PATH,
  LOGIN_PRIVACY_POLICY_ROUTE_PATH,
  WISHLIST_ROUTE_PATH,
  CAFETERIA_CASH_REGISTERY,
  EMPLOYEE_PROFILE_PATH,
  VIEW_EMPLOYEE_ORDER_DETAILS,
  ORDERS_ROUTE_PATH,
  NOTIFICATION_PERMISSION_PATH,
  ENQUIRY_DETAILS_PATH,
  CUSTOMER_ORDERS_ROUTE_PATH,
  CUSTOMER_ORDER_DETAILS_ROUTE_PATH
} from "./routes-constant";
import EditProfilePage from "../components/organisms/my-account/my-profile";
import AddressForm from "../components/organisms/my-account/my-profile/shared-components/AddressForm";
import ShippingAddressListPage from "../components/organisms/my-account/my-profile/shipping-address";
import BillingAddressPage from "../components/organisms/my-account/my-profile/billing-address";
import EmployeeCheckout from "../components/organisms/employee-checkout";
import EmployeeOrderConfirmation from "../components/organisms/employee-order-confirmation";
import OrderDetails from "../components/organisms/my-account/my-orders/OrderDetails";
import CustomerCheckout from "../components/organisms/customer-checkout";
import DeliveryMethod from "../components/organisms/customer-checkout/delivery-method";
import CartView from "../components/organisms/cart";
import CustomerOrderConfirmation from "../components/organisms/customer-checkout/order-confirmation";
import OrderSuccess from "../components/organisms/customer-checkout/order-success";
import OrderFailure from "../components/organisms/customer-checkout/order-failure";
import CustomerCheckoutPayment from "../components/organisms/customer-checkout/payment-method";
import EmployeeSpecialOrderCart from "../components/organisms/employee-special-order-cart";
import SpecialOrderCartScreen from "../components/organisms/employee-special-order-cart/special-cart";
import EmployeeOrderApprovalRequests from "../components/organisms/employee-order-requests";
import Dashboard from "../components/organisms/employee-dashboard";
import CustomerPendingPayment from "../components/organisms/customer-pending-payment";
import CustomerAssistance from "../components/organisms/assistance";
import NotificationListing from "../components/organisms/notification-listing";
import TrainingMaterial from "../components/organisms/training";
import MyAddressListPage from "../components/organisms/my-account/my-profile/my-addresses/myAddressPage";
import AddressFormNew from "../components/organisms/my-account/my-profile/shared-components/AddressFormNew";
import LoginTermsAndCondition from "../components/organisms/login/terms-and-condition";
import LoginPrivacyPolicy from "../components/organisms/login/privacy-policy";
import WishListPage from "../components/organisms/wishlist/Index";
import CashRegistery from "../components/organisms/cash-registery";
import EmployeeProfile from "../components/organisms/employee-profile";
import OrderManagement from "../components/organisms/employee-manage-order/new-list";
import EmployeeOrderDetails from "../components/organisms/employee-manage-order/order-details";
import NotificationPermission from "../components/organisms/notification-listing/notification-permission";
import MyOrders from "../components/organisms/my-account/my-orders";
import EnquiryDetails from "../components/organisms/employee-special-order-cart/customer-listing/detailed";

const Router: React.FC = () => {
  return (
    <section id="page_container">
      <Routes>
        <Route
          path={PRODUCT_PAGE_ROUTE_PATH}
          element={
            <GuestRoute>
              <StoryTellingVideo />
            </GuestRoute>
          }
        />
        <Route
          path={INITIAL_PAGE_ROUTE_PATH}
          element={
            <GuestRoute>
              <WelcomeSplashScreen />
            </GuestRoute>
          }
        />
        <Route
          path={LOGIN_ROUTE_PATH}
          element={
            <GuestRoute>
              <Login />
            </GuestRoute>
          }
        />
        <Route
          path={LOGIN_TERMS_AND_CONDITION_ROUTE_PATH}
          element={
            <GuestRoute>
              <LoginTermsAndCondition />
            </GuestRoute>
          }
        />
        <Route
          path={LOGIN_PRIVACY_POLICY_ROUTE_PATH}
          element={
            <GuestRoute>
              <LoginPrivacyPolicy />
            </GuestRoute>
          }
        />
        <Route
          path={SCAN_ROUTE_PATH}
          element={
            <GuestRoute>
              <ScannerPage />
            </GuestRoute>
          }
        />
        <Route
          path={MANAGE_ORDER}
          element={
            <EmployeeAuthorizedRoute>
              <OrderManagement />
            </EmployeeAuthorizedRoute>
          }
        />
        <Route
          path={VIEW_EMPLOYEE_ORDER_DETAILS}
          element={
            <EmployeeAuthorizedRoute>
              <EmployeeOrderDetails />
            </EmployeeAuthorizedRoute>
          }
        />
        <Route
          path={CAFETERIA_CASH_REGISTERY}
          element={
            <CashRegistery />
          }
        />
        <Route
          path={EMPLOYEE_PROFILE_PATH}
          element={
            <EmployeeAuthorizedRoute>
              <EmployeeProfile />
            </EmployeeAuthorizedRoute>
          }
        />
        <Route
          path={VIEW_ORDER}
          element={
            <EmployeeAuthorizedRoute>
              <EmployeeCart isManageOrder={true} />
            </EmployeeAuthorizedRoute>
          }
        />
        <Route
          path={HOME_PAGE_ROUTE_PATH}
          element={
            <CustomerAuthorizedRoute>
              <Home />
            </CustomerAuthorizedRoute>
          }
        />
        <Route
          path={EMPLOYEE_LOGIN_ROUTE_PATH}
          element={
            <GuestRoute>
              <EmployeeLogin />
            </GuestRoute>
          }
        />
        <Route
          path={PLP_CAFETERIA_MENU_PAGE_ROUTE_PATH}
          element={
            <EmployeeAuthorizedRoute>
              <ListingPage />
            </EmployeeAuthorizedRoute>
          }
        />
        <Route
          path={EMPLOYEE_LANDING_PAGE_ROUTE_PATH}
          element={
            <EmployeeAuthorizedRoute>
              <EmployeeHomePage />
            </EmployeeAuthorizedRoute>
          }
        />
        <Route
          path={TRAINING_RESOURCES_ROUTE_PATH}
          element={
            <EmployeeAuthorizedRoute>
              <TrainingMaterial />
            </EmployeeAuthorizedRoute>
          }
        />
        <Route
          path={FURNITURE_CHECKOUT_PATH}
          element={
            <CustomerAuthorizedRoute>
              <CustomerCheckout />
            </CustomerAuthorizedRoute>}
        />
        <Route
          path={FURNITURE_DELIVERY_PATH}
          element={
            <CustomerAuthorizedRoute>
              <DeliveryMethod />
            </CustomerAuthorizedRoute>}
        />
        <Route
          path={CHECKOUT_ORDER_CONFIRMATION_PAGE}
          element={
            <CustomerAuthorizedRoute>
              <CustomerOrderConfirmation />
            </CustomerAuthorizedRoute>}
        />
        <Route
          path={CUSTOMER_PENDING_PAYMENT_ROUTE_PATH}
          element={
            <CustomerAuthorizedRoute>
              <CustomerPendingPayment />
            </CustomerAuthorizedRoute>}
        />
        <Route
          path={ORDER_SUCCESS_PAGE}
          element={
            <CustomerAuthorizedRoute>
              <OrderSuccess />
            </CustomerAuthorizedRoute>}
        />
        <Route
          path={ORDER_FAILURE_PAGE}
          element={
            <CustomerAuthorizedRoute>
              <OrderFailure />
            </CustomerAuthorizedRoute>}
        />
        <Route
          path={NOTIFICATION_PERMISSION_PATH}
          element={
            <NotificationPermission />
          }
        />
        <Route
          path={CHECKOUT_PAYMENT_PATH}
          element={
            <CustomerAuthorizedRoute>
              <CustomerCheckoutPayment />
            </CustomerAuthorizedRoute>}
        />
        <Route
          path={CHECKOUT_PAYMENT_CARD_FORM_PATH}
          element={
            <CustomerAuthorizedRoute>
              <CustomerCheckoutPayment />
            </CustomerAuthorizedRoute>}
        />
        <Route
          path={REQUEST_ASSISTANCE_PATH}
          element={
            <CustomerAuthorizedRoute>
              <CustomerAssistance />
            </CustomerAuthorizedRoute>}
        />
        <Route
          path={EMPLOYEE_CART_PAGE_ROUTE_PATH}
          element={
            <EmployeeAuthorizedRoute>
              <EmployeeCart />
            </EmployeeAuthorizedRoute>}
        />
        <Route
          path={ASSISTANCE_PATH}
          element={<EmployeeSpecialOrderCart />}
        />
        <Route
          path={ENQUIRY_DETAILS_PATH}
          element={<EnquiryDetails />}
        />
        <Route
          path={EMPLOYEE_CHECKOUT_PAGE_ROUTE_PATH}
          element={
            <EmployeeAuthorizedRoute>
              <EmployeeCheckout />
            </EmployeeAuthorizedRoute>}
        />
        <Route
          path={APPROVAL_PENDING_ORDER_ROUTE_PATH}
          element={
            <EmployeeAuthorizedRoute>
              <EmployeeOrderApprovalRequests />
            </EmployeeAuthorizedRoute>}
        />
        <Route
          path={ORDER_CONFIRMATION_PAGE_ROUTE_PATH}
          element={
            <EmployeeAuthorizedRoute>
              <EmployeeOrderConfirmation />
            </EmployeeAuthorizedRoute>}
        />
        <Route
          path={MY_ACCOUNT_ROUTE_PATH}
          element={
            <CustomerAuthorizedRoute>
              <MyAccount />
            </CustomerAuthorizedRoute>
          }
        />
        <Route
          path={ORDERS_ROUTE_PATH}
          element={
            <CustomerAuthorizedRoute>
              <MyOrders />
            </CustomerAuthorizedRoute>
          }
        />
        <Route
          path={CUSTOMER_ORDERS_ROUTE_PATH}
          element={
            <EmployeeAuthorizedRoute>
              <MyOrders />
            </EmployeeAuthorizedRoute>
          }
        />
        <Route
          path={ORDER_DETAILS_ROUTE_PATH}
          element={
            <CustomerAuthorizedRoute>
              <OrderDetails />
            </CustomerAuthorizedRoute>
          }
        />
        <Route
          path={CUSTOMER_ORDER_DETAILS_ROUTE_PATH}
          element={
            <EmployeeAuthorizedRoute>
              <OrderDetails />
            </EmployeeAuthorizedRoute>
          }
        />
        <Route
          path={MY_ACCOUNT_MY_ADDRESSES_LIST_PAGE_ROUTE_PATH}
          element={
            <CustomerAuthorizedRoute>
              <MyAddressListPage />
            </CustomerAuthorizedRoute>
          }
        />
        <Route
          path={MY_ACCOUNT_EDIT_PROFILE_PAGE_ROUTE_PATH}
          element={
            <CustomerAuthorizedRoute>
              <EditProfilePage />
            </CustomerAuthorizedRoute>
          }
        />
        <Route
          path={WISHLIST_ROUTE_PATH}
          element={
            <CustomerAuthorizedRoute>
              <WishListPage />
            </CustomerAuthorizedRoute>
          }
        />
        <Route
          path={MY_ACCOUNT_BILLING_ADDRESS_LIST_PAGE_ROUTE_PATH}
          element={
            <CustomerAuthorizedRoute>
              <BillingAddressPage />
            </CustomerAuthorizedRoute>
          }
        />
        <Route
          path={MY_ACCOUNT_EDIT_ADDRESS_PAGE_ROUTE_PATH}
          element={
            <CustomerAuthorizedRoute>
              <AddressFormNew />
            </CustomerAuthorizedRoute>
          }
        />
        <Route
          path={MY_ACCOUNT_ADD_NEW_ADDRESS_PAGE_ROUTE_PATH}
          element={
            <CustomerAuthorizedRoute>
              <AddressForm />
            </CustomerAuthorizedRoute>
          }
        />
        <Route
          path={MY_ACCOUNT_SHIPPING_ADDRESS_LIST_PAGE_ROUTE_PATH}
          element={
            <CustomerAuthorizedRoute>
              <ShippingAddressListPage />
            </CustomerAuthorizedRoute>
          }
        />
        <Route
          path={CART_PAGE_PATH}
          element={
            <CustomerAuthorizedRoute>
              <CartView />
            </CustomerAuthorizedRoute>
          }
        />
        <Route
          path={SPECIAL_ORDER_CUSTOMER_CART_SCREEN_ROUTE_PATH}
          element={
            <EmployeeAuthorizedRoute>
              <SpecialOrderCartScreen />
            </EmployeeAuthorizedRoute>
          }
        />
        {/* <Route
          path={NOTIFICATION_LISTING_ROUTE_PATH}
          element={
            <EmployeeAuthorizedRoute>
              <NotificationListing />
            </EmployeeAuthorizedRoute>
          }
        /> */}
        <Route
          path={NOTIFICATION_LISTING_ROUTE_PATH}
          element={
            // <CustomerAuthorizedRoute>
            < NotificationListing />
            // </CustomerAuthorizedRoute>
          }
        />
        <Route
          path={EMPLOYEE_DASHBOARD_ROUTE_PATH}
          element={
            <EmployeeAuthorizedRoute>
              <Dashboard />
            </EmployeeAuthorizedRoute>
          }
        />
      </Routes>
    </section>
  );
};

export default Router;