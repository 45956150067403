import styled, { css } from "styled-components";
import { media } from "../../../../helpers/breakpoints";
import LoginBackground from '../../../../globals/images/Background/LoginBackground.svg';

export const Content = styled.div<any>`
  ${(p: any) => {
    return css`
    height: 100vh;
    width: 100%;
    background-image: url(${LoginBackground});
    background-size: cover; /* Ensures image covers the entire container */
    background-position: center; /* Centers the image horizontally and vertically */
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .login-container{
      width: 100%;
      min-height: var(--main-content-height);
    }
    .login-container-header{
      text-align:center;
      .login-logo{
        padding-top:130px;
        width:130px;
        height:31.99px;
        mix-blend-mode: darken;
      }

      .login-title{
        /* Sign in */

          position: absolute;
          width: 81px;
          height: 26px;
          left: 50%;
          top: 260px;
          transform: translateX(-50%);

          /* H3 */
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 110%;
          /* or 26px */
          text-align: center;

          /* Black */
          color: #1C1C1C;
    }
    }
    .login-content{
      margin-top:168px;
    }
    .login-button-container{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 16px;

      position: absolute;
      width: 370px;
      height: 244px;
      left: calc(50% - 370px/2);
      top: 420px;


      @media screen and ${media.largeMobileAndUpMax}{
        margin-top:15px;
      }
    }
    .login-button-container .login-button{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 7px 52px;
      gap: 40px;

      width: 370px;
      height: 50px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height */
      letter-spacing: 0.03em;

      /* White */
      color: #FFFFFF;

      background: #6F6855;
      border-radius: 100px;
      border: none;

      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
    }
      .terms-and-policy {
        width: 370px;
        height: 15px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        /* identical to box height */

        /* Sand */
        color: #6F6855;


        /* Inside auto layout */
        flex: none;
        order: 3;
        align-self: stretch;
        flex-grow: 0;

      }

      .terms-and-conditions, .privacy-policy {
        font-family: Poppins;
        font-size: 10px;
        font-weight: 700;
        line-height: 15px;
        text-align: left;
      }
        .user-data{
          display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size:30px;
        }
     
      }

      .employee-cta {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 10px;

        position: absolute;
        width: 140.55px;
        height: 35.55px;
        left: 30px;
        bottom: 30px;
      }
      .bottom-logo {
        /* Group */

        width: 35.55px;
        height: 35.55px;


        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;

      }

      .employee-login-text {
       
        width: 95px;
        height: 18px;

        /* Paragraph 3 */
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height */

        /* Black */
        color: #1C1C1C;


        /* Inside auto layout */
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 0px;
      }
    
    `
  }}
`
export const TermsAndCondition = styled.div`

.heading{
font-weight: 500;
        font-size: 24px;
        line-height: 26.4px;
}
/* Auto layout */
 
padding: 0px 0px 100px;
gap: 24px;

position: absolute;
width: 370px;
height: 8395px;
left: calc(50% - 370px/2);
margin-top: 32px;
.text-content{
font-weight: 400;
        font-size: 16px;
        line-height: 20.8px;
}
`

export const PrivacyPolicy = styled.div`
.heading{
font-weight: 500;
        font-size: 24px;
        line-height: 26.4px;
}
/* Auto layout */
 
padding: 0px 0px 100px;
gap: 24px;

position: absolute;
width: 370px;
height: 8395px;
left: calc(50% - 370px/2);
margin-top: 32px;
.text-content{
font-weight: 400;
        font-size: 16px;
        line-height: 20.8px;
}
`
