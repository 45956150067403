import {
  SUBTOTAL_LABEL,
  ESTIMATED_TAX_LABEL,
  SHIPPING_FEE_LABEL,
  TOTAL_LABEL,
  OFFER_LABEL,
} from "../../../../lib/constant";

const INFO_LIST = ["Product name", "SKU", "Price", "Qty", "Subtotal"];
const SHIPMENT_LIST = ["Product name", "SKU", "Qty"];
const PAYMENT_DETAILS = [
  SUBTOTAL_LABEL,
  ESTIMATED_TAX_LABEL,
  SHIPPING_FEE_LABEL,
  OFFER_LABEL,
  TOTAL_LABEL
];
const CAFETERIA_PAYMENT_DETAILS = [
  SUBTOTAL_LABEL,
  ESTIMATED_TAX_LABEL,
  OFFER_LABEL,
  TOTAL_LABEL
];
const SHIPMENT_DETAILS = [
  "Billing Address",
  "Delivery Address",
  "Delivery Method",
];
const LIST_PAGE_LABELS = {
  orderStatus: { en: "Order Status" },
  products: { en: "Products" },
  productName: { en: "Product Name:" },
  sku: { en: "SKU:" },
  qty: { en: "Qty:" },
  ordered: { en: "Ordered:" },
  subtotal: { en: "Subtotal:" },
  grandTotal: { en: "Grand Total" },
};
const ORDER_DETAILS_PAGE_LABELS = {
  orderStatus: { en: "Order Status" },
  purchasedFrom: { en: "Purchased from" },
  name: { en: "Name" },
  email: { en: "Email" },
  subtotal: { en: "Subtotal" },
  tax: { en: "Tax" },
  shippingHandling: { en: "Delivery Handling" },
  grandTotal: { en: "Grand Total" },
  totalPaid: { en: "Total Paid" },
  totalDue: { en: "Total Due" },
  balance: { en: "Balance" },
  shipmentDetails: { en: "Billing and Delivery Details" },
  paymentMethods: { en: "Payment Methods" },
  invoices: { en: "Invoices" },
  payBalance: { en: "Pay Remaining Balance" },
  paymentMethod: { en: "Payment Method" },
  paymentAmount: { en: "Payment Amount" },
  payemtnStatus: { en: "Payment Status" },
  noteLabel: { en: "Note: " },
  orderNumber: { en: "Order #" },
  invoiceNumber: { en: "Invoice #" },
};
const ADDRESS_LABELS = {
  street: "Street",
  city: "City",
  region: "State",
  telephone: "Phone",
  postcode: "Zip code",
  country_id: "Country",
};
const NO_DETAILS_FOUND = "No Details Found!";
const NO_ORDERS_FOUND = "No Orders Yet!";
const tabMap = {
  1: ["complete"],
  2: ["full_return", "partial_return"],
};
const statusMap = {
  full_return: "Returned",
  partial_return: "Returned Partially",
  full_cancel: "Cancelled",
  partial_cancel: "Cancelled Partially",
};
export {
  INFO_LIST,
  PAYMENT_DETAILS,
  SHIPMENT_DETAILS,
  LIST_PAGE_LABELS,
  ORDER_DETAILS_PAGE_LABELS,
  ADDRESS_LABELS,
  NO_DETAILS_FOUND,
  NO_ORDERS_FOUND,
  SHIPMENT_LIST,
  tabMap,
  statusMap,
  CAFETERIA_PAYMENT_DETAILS
};
