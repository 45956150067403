export const ADDRESS_FORM_STRINGS = {
    updateSuccess: {
        en: "Address updated successfully",
        ar: "تم تحديث العنوان بنجاح"
    },
    addSuccess: {
        en: "New address added successfully",
        ar: "تم إضافة عنوان جديد بنجاح"
    },
    updateError: {
        en: "Failed to update address - No data received",
        ar: "فشل في تحديث العنوان - لم يتم استلام البيانات"
    },
    genericUpdateError: {
        en: "Failed to update address. Please try again later.",
        ar: "فشل في تحديث العنوان. يرجى المحاولة لاحقاً."
    },
    loginNeeded: {
        en: "Please log in to continue.",
        ar: "الرجاء تسجيل الدخول للمتابعة."
    },
    formInvalidError: {
        en: "Form is invalid, check errors",
        ar: "النموذج غير صالح، تحقق من الأخطاء"
    },
    formValidProceed: {
        en: "Form is valid, proceed with saving",
        ar: "النموذج صالح، استمر في الحفظ"
    }
};

export const ADDRESS_FORM_UI_STRINGS = {
    addressType: {
        billing: {
            en: "Billing Address",
            ar: "عنوان الفواتير"
        },
        shipping: {
            en: "Delivery Address",
            ar: "عنوان الشحن"
        }
    },
    actions: {
        addStreet: {
            en: "Add Another Street +",
            ar: "أضف شارع آخر +"
        },
        removeStreet: {
            en: "Remove Street",
            ar: "إزالة الشارع"
        },
        selectCountry: {
            en: "Select Country",
            ar: "اختر البلد"
        },
        selectState: {
            en: "Select State",
            ar: "اختر الولاية"
        },
        saveButton: {
            en: "Save",
            ar: "حفظ"
        },
        defaultShipping: {
            en: "Default Delivery Address",
            ar: "عنوان الشحن الافتراضي"
        },
        defaultBilling: {
            en: "Default Billing Address",
            ar: "عنوان الفواتير الافتراضي"
        },
        noDataDisplay: {
            en: "No data to display",
            ar: "لا توجد بيانات لعرضها"
        }
    },
    labels: {
        state_province_area: {
            en: "State/province/area",
            ar: "الدولة/المقاطعة/المنطقة:"
        },
        zip_code: {
            en: "Zip code",
            ar: "الرمز البريدي:"
        },
        country: {
            en: "Country",
            ar: "البلد:"
        },
    }
};

export const TOGGLE_BUTTON_LABELS = {
    show: "See Full Details...",
    hide: "Hide Details..."
}

export const REMOVE_ACTION_CONSTANTS = {
    check_for_default: {
        en: "Please choose another address as default before removing this one."
    },
    check_for_default_billing: {
        en: "Please choose another address as default billing before removing this one."
    },
    check_for_default_shipping: {
        en: "Please choose another address as default delivery before removing this one."
    }

}

