import apiList, { EMPLOYEES, EMPLOYEES_ROLES } from "../lib/constant";
import { doActionGet } from "./httpRequest";

export const isEmptyString = (value: string) => {
  return value?.trim().length === 0;
};

export const formatPrice = (price: number) => {
  const num = Number(price);

  if (Number.isNaN(num) || !Number.isFinite(num)) {
    return price;
  }

  if (num % 1 !== 0) {
    return num.toFixed(2);
  } else {
    return num.toString();
  }
};

export function formatPriceAndCurrency(
  price?: number,
  roundOffDigits?: number
): string {
  let formattedPrice: string | undefined;
  const currencySymbol = getStaticConstantValue("currency_symbol");

  if (price !== undefined) {
    if (Number.isInteger(price)) {
      formattedPrice = price.toString();
    } else if (roundOffDigits !== undefined) {
      formattedPrice = price?.toFixed(roundOffDigits);
    } else {
      formattedPrice = price?.toString();
    }
  }

  return `${currencySymbol} ${formattedPrice}`;
}

export const setStaticConstants = async (userType: string) => {
  try {
    let response = await doActionGet({
      url: apiList.staticConstantAPI,
      userType,
    });
    response = await response?.data;
    const data = JSON.stringify(response);
    localStorage.setItem("static-constants", data);
  } catch (error) {
    console.error("error while calling API>>", error);
  }
};

export const getStaticConstantValue = (key: string) => {
  let value = "";
  try {
    const localStaticConstants = localStorage.getItem("static-constants");
    const staticConstants = localStaticConstants
      ? JSON.parse(localStaticConstants)
      : [];

    value = staticConstants.length > 0 ? staticConstants[0][key] : "";
  } catch (error) {
    console.log("Error while getting currency symbol", error);
  }
  return value;
};

export const setDynamicHeights = (isUser: boolean, isEmployee: boolean) => {
  const updateHeights = () => {
    const windowHeight = window.innerHeight;
    const headerHeight = (10 / 100) * windowHeight; // 10vh equivalent
    const footerHeight = (10 / 100) * windowHeight; // 10vh equivalent
    let mainContentHeight;

    if (isUser) {
      mainContentHeight = windowHeight - headerHeight - footerHeight; // 80vh equivalent
    } else if (isEmployee) {
      mainContentHeight = windowHeight - headerHeight - footerHeight; // 80vh equivalent
    } else {
      mainContentHeight = windowHeight; // 100vh equivalent
    }

    document.documentElement.style.setProperty(
      "--header-height",
      `${headerHeight}px`
    );
    document.documentElement.style.setProperty(
      "--footer-height",
      `${footerHeight}px`
    );
    document.documentElement.style.setProperty(
      "--main-content-height",
      `${mainContentHeight}px`
    );
  };

  window.addEventListener("resize", updateHeights); // Update on resize
  updateHeights(); // Initial call
};

export const getHashParams = (): Record<string, string> => {
  const hash = window.location.hash.substring(1);
  return hash.split("&").reduce((result, item) => {
    const [key, value] = item.split("=");
    result[key] = decodeURIComponent(value);
    return result;
  }, {} as Record<string, string>);
};

export const getQueryParams = (): Record<string, string> => {
  const params = new URLSearchParams(window.location.search);
  const result: Record<string, string> = {};
  params.forEach((value, key) => {
    result[key] = value;
  });
  return result;
};

export const isCafeteriaManager = () =>
  EMPLOYEES.CAFETERIA_MANAGER.includes(getStaticConstantValue("requested_by"));

export const isStoreManager = () =>
  getStaticConstantValue("requested_by") === EMPLOYEES.STORE_MANAGER;

export const isManager = (role = "") =>
  EMPLOYEES_ROLES.MANAGER.includes(
    role ?? getStaticConstantValue("requested_by")
  );
