import styled, { css } from "styled-components";
import { media } from "../../../../helpers/breakpoints";

export const Content = styled.div<any>`
  ${(p: any) => {
    return css`
      background: var(--white);

      .header {
        grid-template-columns: 1fr 6fr 1fr;
        display: grid;
        align-content: center;
        // margin-top:10px;
      }

      .header-content {
        display: flex;
        justify-content: center;
      }

      .delivery-section {
        padding: 16px;
      }

      .delivery-content {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 0.2fr 2fr 4fr;
        align-items: center;
      }

      .delivery-content-non-input {
        margin-top: 18px;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 0.2fr 6fr;
        align-items: center;
        // @media screen and (max-width:620px){
        //   grid-template-columns:0.2fr 0.3fr 0.1fr 7fr;
        // }
        // @media screen and (max-width:620px){
        //   grid-template-columns:0.2fr 0.3fr 0.1fr 7fr;
        // }
      }

      input[type="radio"] {
        accent-color: var(--sand);
        width: 20px;
        height: 20px;
      }

      .delivery-hidden-input {
        visibility: hidden;
      }

      .delivery-hidden-input-area {
        visibility: hidden;
        @media screen and (max-width: 620px) {
          display: none;
        }
      }

      .quantity_button:disabled {
        color: #dadedf;
      }

      .delivery-icon {
        width: 32px;
        height: 32px;
        display: flex;
        justify-self: flex-start;
        align-self: center;
      }

      .delivery-line {
        display: flex;
        justify-content: flex-start;
      }

      .delivery-text {
        font-weight: 500;
        size: 18px;
        line-height: 19.6px;
      }

      .delivery-input {
        width: 100%;
        height: 33px;
        padding-top: 1.5px;
        padding-left: 8px;
        background-color: var(--cultured);
        border: none;
        border-radius: 40px;
        color: var(--input-field-text-color);
        font-weight: 600;
        size: 14px;
        line-height: 21px;
      }

      .delivery-input::placeholder {
        color: var(--sand);
        font-family: poppins;
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
      }

      .delivery-input-area {
        margin-right: 34px;
      }

      .delivery-input:focus,
      input:focus {
        outline: none;
      }

      .delivery-input-toggler {
        display: flex;
        justify-content: flex-end;
      }

      .order-header {
        font-weight: 500;
        font-size: 16px;
        line-height: 17.6px;
        color: var(--sand);
      }

      .order-item-list-section {
        padding: 16px;
        display: flex;
        flex-direction: column;
        // gap: 8px;
      }
      .item-sku {
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        color: var(--silver-foil);
      }

      .item-name {
        font-weight: 400;
        font-size: 16px;
        line-height: 20.8px;
        &.no-stock {
          font-size: 12px;
        }
      }

      .item-bin {
        width: 20px;
        height: 20px;
      }

      .item-delete {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 40px;
      }

      .cart-list-items {
        border: none;
        border-bottom: 1px solid var(--silver-foil);
        // padding: 16px 0px;
        gap: 16px;
        display: grid;
        grid-template-columns: 3fr 1fr 1fr 0.5fr;
        padding: 0 5px;
        align-items: center;
        &.no-stock {
          background: #f8f7f2;
        }
        @media screen and ${media.largeMobileAndUp2Max} {
          grid-template-columns: 3fr 1fr 1fr 0.5fr;
        }
        @media screen and ${media.largeMobileAndUpMax2} {
          grid-template-columns: 4fr 2fr 1fr 1fr;
        }
        @media screen and ${media.largeMobile} {
          grid-template-columns: 5fr 2fr 1fr 1fr;
        }
      }

      .item-name-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        min-width: 0; /* Prevent overflow issues */
        height: 36px;

        // /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
      }

      .value_div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .out-of-stock {
        font-weight: 600;
        font-size: 15px;
        line-height: 20.8px;
      }
      .item-price {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: var(--sand-button-color);
        max-height: 21px;
        width: max-content;
        gap: 4px;
      }

      .button_section {
        box-sizing: border-box;
        margin: 0 auto;

        width: 70px;
        height: 28px;
        border-radius: 4px;
        border: 0.8px solid var(--sand);
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .button_section button {
        height: 24px;
        width: 24px;
        border: none;
        font-size: 14px;
        color: var(--sand);
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .remove_quantity_button {
        height: 24px;
        width: 24px;
      }

      .value_div {
        height: 18px;
        width: 14px;
        font-size: 14px;
        line-height: 18.2px;
        font-weight: 500;
        align: right;
      }

      .coupon-label {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      }

      .coupon-input {
        top: 7.5px;
        left: 113px;
      }

      .apply-button {
        // width:70px;
        width: 100%;
        height: 35px;
        border-radius: 30px;
        border: none;
        background-color: var(--sand);
        color: var(--white);
        font-size: 14px;
        font-weight: 500;
      }

      .apply-button:disabled {
        color: var(--apply-button-disabled-color);
      }

      .coupon-section {
        // display: flex;
        // justify-content: space-between;
        display: grid;
        grid-template-columns: 5fr 3fr;
        gap: 16px;
        align-items: center;
        height: 32px;
        padding: 16px;
      }

      .coupon-button {
        display: flex;
        justify-content: center;
      }

      .coupon-input {
        width: 100%;
        height: 32px;
        padding-top: 1.5px;
        padding-left: 21px;
        background-color: var(--cultured);
        border: none;
        border-radius: 30px;
        color: var(--input-field-text-color);
      }

      .coupon-input::placeholder {
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        color: var(--sand);
      }

      .coupon-inputbox {
        margin-right: 31px;
      }

      .cart-summary {
        padding-bottom: 37px;
        height: 150px;
        border-radius: 16px;
        // margin-right: 4%;
        padding-top: 39px;
        // margin-left: 4%;
        // padding-left: 19px;
        background-color: #f8f8f8;
        gap: 12px;
        // padding-right: 0px 19px;
        padding: 37px 19px;
        border-radius: 20px;
        background: var(--white);
        box-shadow: 0px 4px 18px rgba(111, 104, 85, 0.1);
      }

      .cart-subtotal,
      .cart-total {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      .cart-subtotal .value {
        display: flex;
        justify-content: flex-end;
        font-weight: 400;
        font-size: 16px;
        line-height: 20.8px;
      }

      .cart-subtotal .label,
      .cart-total .label {
        font-weight: 500;
        font-size: 14px;
        line-height: 18.2px;
        color: var(--silver-foil);
      }

      .cart-total .value {
        display: flex;
        justify-content: flex-end;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
      }

      .value {
        display: flex;
        justify-content: flex-end;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }

      .label {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: var(--sku-font-color);
      }

      .horizontal-line {
        width: 100%;
        // height: 1px;
        border: 1px;
        // margin-bottom:16px;
      }

      .moveToCartBtn {
        background: #ffffff;
        position: fixed;
        bottom: 0px;
        left: 0;
        height: 71px;
        width: 100%;
        border: 1px solid #ffffff;
        outline: 1px solid #ffffff;
        margin-right: auto;
        text-align: center;
        margin-left: auto;
      }

      .summary-section {
        margin-bottom: 12px;
        padding: 16px;
      }

      .consent-section {
        margin-bottom: 80px;
        margin-left: 4%;
        display: flex;
        flex-direction: row;
        column-gap: 17px;
      }

      .consent-checkbox {
        width: 30px;
        height: 30px;
        accent-color: var(--input-button-border-color);
      }

      .consent-text {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: var(--sku-font-color);
      }

      .moveToCartBtn button {
        cursor: pointer;
        width: 80%;
        background: #6f6855;
        padding: 10px 50px;
        border-radius: 100px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: #ffffff;
        border: none;
        height: 44px;
      }

      .moveToCartBtn button:disabled {
        color: #b9b9b9;
        background: #f6f6f6;
      }

      .cart-item-line {
        width: 97%;
        margin-bottom: 10px;
        margin-top: 10px;
        border-bottom: 1px solid;
      }

      .employee-info {
        border: 1px solid rgb(204, 204, 204);
        padding: 0 10px;
        background-color: rgb(249, 249, 249);
      }

      .employee-info h3 {
        font-size: 1.2em;
        margin-bottom: 10px;
        color: #333;
      }

      .employee-name,
      .employee-id,
      .employee-email {
        margin: 5px 0;
        font-size: 0.8em;
        color: #555;
      }
      .cart-footer {
        padding: 20px;
      }
    `;
  }}
`;
