import React, { useContext, useEffect, useState } from "react";
import * as S from "./styles/styles";
import backArrow from './../../../globals/svg/back-arrow.svg';
import notificationIcon from "./../../../globals/svg/notification.svg";
import logo from './../../../globals/svg/tresori-golden-logo.svg';
import { useNavigate } from "react-router-dom";
import { ALLOWED_PATHS, BACKARROW_NOT_ALLOWED_PATHS, EMPLOYEES_ROLES, RESTRICTED_PATHS } from "../../../lib/constant";
import { getStaticConstantValue, isCafeteriaManager, isManager, isStoreManager } from "../../../helpers/utlis";
import { ASSISTANCE_PATH, MANAGE_ORDER, NOTIFICATION_LISTING_ROUTE_PATH, ORDER_SUCCESS_PAGE, PLP_CAFETERIA_MENU_PAGE_ROUTE_PATH, PRODUCT_PAGE_ROUTE_PATH, SCAN_ROUTE_PATH } from "../../../routes/routes-constant";
import { useLocation, matchPath } from 'react-router-dom';
import { useAuth } from "../../../Context/AuthContext";
import { DataContext } from "../../../Context/AppContext";
import AddToWishList from "../AddToWishList";
import IconWithCount from "../icon-with-count";
import { getLocalStorage } from "../../../helpers/localStorageUtil";
import { doActionPost } from "../../../helpers/httpRequest";
import { ACTION_TYPE } from "../../../Context/Constant";

const Header: React.FC = () => {
  const { isUserLoggedIn, isEmployeeLoggedIn } = useAuth();
  const [showHeader, setShowHeader] = useState(false);
  const { state, dispatch } = useContext(DataContext);
  const { notification_data, show_wishlist_icon } = state;
  const unseenNotifications = Object.values(notification_data ?? {}).filter(Boolean).length
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const isOnProductPage = Boolean(matchPath(PRODUCT_PAGE_ROUTE_PATH, path))

  const backButtonHandler = () => {
    if (path === ORDER_SUCCESS_PAGE) navigate(SCAN_ROUTE_PATH);
    else navigate(-1);
  };

  useEffect(() => {
    if (getLocalStorage("cognitoId") &&getLocalStorage("notification-section")==="yes" ) getAllNotification();
  }, [getLocalStorage("cognitoId"),getLocalStorage("notification-section")])

  const getAllNotification = () => {
    if (process.env.REACT_APP_GET_ALL_PUSH_NOTIFICATIONS_API) {
      doActionPost({
        url: process.env.REACT_APP_GET_ALL_PUSH_NOTIFICATIONS_API,
        data: {
          "user_id": JSON.parse(getLocalStorage("cognitoId")),
          "start_offset": 0,
          "limit": 4
        }
      })?.then(async (resp: any) => {
        if (resp?.data?.notifications?.length > 0) {
          const notificationPayload: ({ [key: string]: boolean }) = {}
          resp?.data?.notifications?.forEach((notification: { message_id: string | number; seen: string; }) => { notificationPayload[notification.message_id] = (notification.seen === '0') })
          dispatch({ type: ACTION_TYPE.set_notification_data, payload: notificationPayload });
        }
      }
      ).catch((error: any) => {
        console.log("error msg", error);
      })

    }
  }
  useEffect(() => {
    if ((isUserLoggedIn || isEmployeeLoggedIn) && !RESTRICTED_PATHS.includes(path)) setShowHeader(true)
    else setShowHeader(ALLOWED_PATHS.includes(path));
  }, [isUserLoggedIn, isEmployeeLoggedIn, path]);
  return (
    <S.Content>
      {showHeader && (
        <div className="header">
            {!BACKARROW_NOT_ALLOWED_PATHS.includes(path) ?<div className="header-content" onClick={backButtonHandler}>
              <img className="header-back-arrow" src={backArrow} alt="back-btn" />
            </div>:<div></div>}
          <div className="header-content" >
            <img className="header-app-logo" src={logo} alt="logo" onClick={() => {
              if (isEmployeeLoggedIn) {
                if (isStoreManager()) { navigate(MANAGE_ORDER) }
                else if (isCafeteriaManager()) navigate(PLP_CAFETERIA_MENU_PAGE_ROUTE_PATH)
                else navigate(ASSISTANCE_PATH)
              }
              else if (isUserLoggedIn) {
                navigate(SCAN_ROUTE_PATH);
              }
            }} />
          </div>
          {Boolean(show_wishlist_icon && isOnProductPage && isUserLoggedIn) ? < AddToWishList />
            : isCafeteriaManager() ? null
              : <IconWithCount icon={<img className="header-back-arrow" src={notificationIcon} alt="back-btn" onClick={() => navigate(NOTIFICATION_LISTING_ROUTE_PATH)} />} count={unseenNotifications} />}
        </div>
      )}
    </S.Content>
  );
};

export default Header;
