import styled, { css } from "styled-components";
import { media } from "../../../../../helpers/breakpoints";

export const Content = styled.div<any>`
  ${(p: any) => {
    return css`
        @media print {
        .footer,
        #non-printable {
            display: none !important;
        }
        #printable {
            display: block;
        }
        }
      .footer {
        height: var(--footer-height);
        z-index: 9;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        -webkit-box-pack: justify;
        gap: 32px;
        bottom: 0;
        position: fixed;
        width: 100%;
        box-sizing: border-box;
        background-color: var(--white);
        box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 5px;
        padding: 0 10px;
        overflow: scroll;
        @media (height <= 460px) {
          height: 15vh;
        }
        .icon {
          cursor: pointer;
          display: flex;
          flex: 0 0 13%;
          gap: 8px;
          flex-direction: column;
          align-items: center;
          font-size: 10px;
          line-height: 15px;
          font-weight: 500;
          color: #b9b9b9;
          min-width: 40px;
          min-height: 40px;
          &.no-count {
            margin-top: 3px;
          }
          .top {
            background: rgb(111, 104, 85);
            border-radius: 0px 0px 6px 6px;
            height: 5px;
            width: 100%;
            padding-bottom: 2px;
            margin-bottom: 2px;
            @media screen and ${media.tabletLandscapeAndUp} {
              margin-bottom: 24px;
            }
          }
          .top-inactive {
            height: 5px;
            width: 100%;
            padding-bottom: 2px;
            margin-bottom: 2px;
            @media screen and ${media.tabletLandscapeAndUp} {
              margin-bottom: 24px;
            }
          }
          svg {
            width: 26px;
            height: 26px;
          }
          &.active svg {
            color: #6f6855;
            path {
              fill: #6f6855;
            }
          }
          .name {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            color: #b6b2a6;
            margin-bottom: 10px;
            &.active {
              color: #6f6855;
            }
          }
        }
      }

      .footer::-webkit-scrollbar {
        display: none;
      }
    `;
  }}
`;
