import styled, { css } from "styled-components";
import { media } from "../../../../../helpers/breakpoints";

export const Content = styled.div<any>`
  ${(p: any) => {
    return css`
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap:24px;

      .label-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0;

        .title-text {
            color: var(--grey-text);
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.8px;
          }

          .scanner {

          }
      }

      //new search bar

      .searchBar {
          margin-bottom: 20px;
          position: relative;
          z-index: 2;
          .icon {
            position: absolute;
            left: 10px;
            top: 15px;
            svg {
              width: 16px !important;
              height: 20px !important;
              circle {
                stroke: #b9b9b9 !important;
              }
              path {
                fill: #b9b9b9 !important;
              }
            }
          }
          input {
            box-sizing: border-box;
            width: 100%;
            border-radius: 100px;
            border: none;
            outline: none;
            padding: 14px 35px;
            color: #4e4e4e;
            background: #f0f0f0;
          }
          input::placeholder {
            color: #b9b9b9;
          }
        }

      //search bar css
      .search-bar {
        position: relative;
        width: 100%;
      }
      .search-input-container {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        height: 40px;
        z-index: 2;
      }

      .search-icon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        color: #6F6855;
        pointer-events: none;
        z-index: 3;
      }

      .search-input {
        flex-grow: 1;
        box-sizing: border-box;
        width: 100%;
        padding: 12px 40px;
        border: 1px solid #ccc;
        border-radius: 40px;
        font-size: 16px;
        outline: none;
        gap: 10px;
        z-index: 2;
      }

      .search-product::placeholder {
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
      }

      .search-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 41.6px;
        background-color: var(--sand-button-color);
        padding: 0 0.5rem;
        color: var(--white);
        border: none;
        border-radius: 0 4px 4px 0;
        cursor: pointer;
      }

      .search-button:hover {
        background-color: var(--sand-button-color-on-hover);
      }

      .search-icon, .clear-icon {
        width: 1.5rem;
      }

      .search-dropdown {
            width:94%;
            position: absolute;
            top: 0; /* Align with the top of the search input */
            background-color: white;
            z-index: 1; /* Send behind the search bar */
            padding: 50px 10px 10px 10px;
            max-height: 400px;
            overflow-y: auto;
            border: 1px solid #ccc;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            margin-top: 20px; /* Adjust to move it below the input, if necessary */

            @media screen and ${media.largeMobileAndUp}{
              width:95%;
           }
              @media screen and ${media.largeMobileMin}{
              width:97%;
           }
          .item {
              margin-bottom: 15px;
              box-shadow: 0px 4px 18px rgba(111, 104, 85, 0.1);
              margin-bottom: 20px;
          }

          .item-flex-container {
              display: flex;
              align-items: center;
              padding: 4px;
                  margin-bottom: 20px;
          }

          .item-image {
              width: 75px;
              height: 75px;
              object-fit: cover;
              border-radius: 6px;
          }

          .item-details {
              flex-grow: 1;
              color: var(--grey-text);
              font-family: Poppins;
              font-size: 13px;
              font-style: normal;
              font-weight: 600;
              line-height: 18px;
          }

          .item .price {
              color: var(--grey-text);
          }

          .search-item-quantity-changer {
              display: flex;
              flex-direction: column;
              width: 35%;
              justify-content: space-between;

              button {
                width: 100%;
                margin: 0.5rem 0;
                padding: 2px 4px;
                background-color: var(--sand-button-color);
                color: var(--white);
                border: none;
                cursor: pointer;
                font-size: 12px;
              }
            }
      }

      /* Hide scrollbar for Chrome, Safari and Opera */
      .search-dropdown::-webkit-scrollbar {
        display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .search-dropdown {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
      
      .select-button{
                    width: 100%;
                    margin-top: 13px;

                }
      .cart {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
      
      .cart-container {
        // padding: 10px 20px;
      }

      .item-container {
        padding: 30px 0;
        // border-bottom: 2px solid var(--apply-button-color);
      }

      .item {
        padding: 10px;
        box-sizing: border-box;
        border-radius: 4px;
        background: var(--cultured);
        box-shadow: 0px 4px 18px rgba(111, 104, 85, 0.1);
        cursor: pointer;
        margin-bottom:20px;
    }
        .item-out-of-stock {
          border: 0.8px solid var(--sand);
        }
    
    .item-flex-container {
        display: flex;
        padding: 4px;
    }

    .item-child-container .item-flex-container {
        padding: 10px;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid #6F6855;
        background: var(--cultured);
        // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 0px 4px 18px rgba(111, 104, 85, 0.1);
        cursor: pointer;
    }
     
      .item-image {
        width: 75px;
        height: 75px;
        object-fit: cover;
        border-radius: 6px;
      }
      .item-details {
        padding: 0 12px;
        display: flex;
        flex-direction: column;
        width: 50%;
        justify-content: space-around;
        align-items: flex-start;
      }
      .item-details .name, .child-item-details .name {
        color: var(--sand);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18.2px;
      }

      .item-details .out-of-stock {
        color: var(--dark-black);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 17.6px;
      }

      .item-details .price, .child-item-details .price {
        color: var(--silver-foil);
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 15px;
      }
      .item-quantity {
        display: flex;
        flex-direction: column;
        width: 40%;
        justify-content: center;
        justify-content: space-evenly;
        row-gap:7px;
      }
        .add-products-title{
            color: var(--grey-text);
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            text-decoration: underline;
        }
      
      .item-quantity svg {
        align-self: center;
      }

      .child-items-container {
        display: flex;
        overflow-x: auto;
        margin-top: 10px;
        padding: 10px;
    }
    
    .child-item {
        background-color: #f8f8f8;
        margin-right: 20px;
        padding: 10px;
        border-radius: 6px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        min-width: 150px;
        width: 200px;
        min-height: 150px;
        height: 200px;
    }

    .child-item-image {
        width: 100%;
        height: 40%;
        object-fit: cover;
        border-radius: 6px;
      }

      .child-item-details {
        padding: 0 12px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 40%;
        justify-content: space-around;
        align-items: flex-start;
      }

      .child-item-quantity {
        display: flex;
        width: 100%;
        height:20%;
        align-items: center;
        justify-content: space-between;

        .child-item-quantity-changer {
            width: 80%;
        }

        .child-item-delete-icon {
            display: flex;
            justify-content: center;
            width: 20%;
        }

      }

      .quantity-changer {
        display: flex;
        justify-content: space-around;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.3);
      }
      .disabled {
        pointer-events: none;
        opacity: 0.5;
      }
      .quantity-button {
        color: var(--grey-text);
        text-align: right;
        font-family: Poppins;
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: 13px;
        align-self: center;
      }
      .quantity-button svg {
        height: 10px;
        width: 10px;
        font-weight: 700px;
      }
      .payment-slab {
        padding: 0 0 30px 0;
      }
      .cart-total {
        border-top: 2px solid var(--apply-button-color);
      }

      .cost-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
      }
      .cost-info .label{
        color: var(--DARK-BLACK);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20.8px;
        width: 45%;
      }

      span {
        width: 10%;
        text-align: end;
      }
        
      .cost-info .value {
        color: var(--sand);
        text-align: right;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 17.6px;
        width: 45%;
      }

      .total-amount {
        border-top: 1px solid #c6c5c5;
        padding: 30px 0;
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .total-amount .cost-info {
        height: 24px;
      }

      .total-amount p {
        color: var(--dark-black);
        text-align: right;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        width: 45%;
      }
      .cost-total {
        text-align: left !important;
      }
      button {
        width: 100%;
        color: var(--white);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        background: rgb(111, 104, 85);
        border-radius: 100px;
        border: none;
        padding: 7px 48px;
        align-self: center;
      }
      .empty-cart {
        padding: 20px;
      }

      .toggle-details-button {
        display: block; /* Ensures it appears on a new line */
        width: 100%;
        align-self: center; /* Center the button horizontally */
        color: var(--sand-button-color);
        font-size: 12px;
        cursor: pointer;
        text-align: left;
        margin-top: 10px; /* Space between content and button */
    }
    
        .toggle-details-button:hover {
            color: var(--sand-button-hover);
        }
            .modal {
              display: block;
              position: fixed;
              z-index: 5;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              overflow: auto;
              background-color: rgba(0, 0, 0, 0.5);
            }

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  display:flex;
  flex-direction:column;
}
  .related-product-heading{
  margin-bottom:20px;
  }
.item-child-container{
    margin-top: 20px;
  margin-bottom: 20px;
    margin-left: 38px;
    background-color: var(--sku-font-color);
    background: var(--white);
    box-shadow: rgba(0, 0, 0, 0.05) 8px 27px 25px 29px
}
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
      display: flex;
    justify-content: flex-end;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
    `;
  }}
`;

export const Label = styled.label<{inputType?: string}>`
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: var(--grey-text);
  margin-bottom: 5px;
  `;
