import React, { CSSProperties } from "react"
import { PulseLoader } from 'react-spinners'
import * as S from "./styles";

const override: CSSProperties = {
  opacity: 1,
  width: "20px",
  height: "29px",
};
export const Loading: React.FC = () => {

  return (
    <S.View>
      <div className="loader-content">
        <PulseLoader
          color="#6F6855"
          loading={true}
          cssOverride={override}
          size={25}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </S.View>
  )
}
