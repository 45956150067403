import React, { useEffect, useState } from "react";
import * as S from "../styles/styles";
import CheckoutHeader from "../checkout-header";
import apiList, {ADVANCED_AMOUNT_LABEL, ALERT_STATUS, BALANCED_AMOUNT_LABEL, BILLING_ADDRESS_HEADING, CHECKOUT_FIRST_STEP_HEADING, CHECKOUT_LAST_STEP_HEADING, CLICK_AND_COLLECT_CODE, CONITNUE_BUTTON_LABEL, ESTIMATED_TAX_LABEL, GIFT_CART_LABEL, OFFER_LABEL, ORDER_TOTAL_HEADING, PLACE_ORDER_BUTTON_LABEL, SELECTED_BILLING_ADDRESS_HEADING, SELECTED_SHIPMENT_METHOD, SELECTED_SHIPPING_ADDRESS_HEADING, SHIPPING_FEE_LABEL, SOMETHING_WENT_WRONG, SUBTOTAL_LABEL, TOTAL_LABEL, USER_TYPE, progressBar } from "../../../../lib/constant";
import CheckoutProgressBar from "../../../molecules/checkout-progress-bar";
import { useNotification } from "../../../../hooks/useNotification";
import { doActionGet, doActionPost, doActionPut } from "../../../../helpers/httpRequest";
import { Loading } from "../../../atoms/loading/Loading";
import { Address } from "../../my-account/my-profile/types/types";
import { getAttributeDataBasedOnCode, getCountryName } from "../helper";
import { formatPriceAndCurrency, getStaticConstantValue } from "../../../../helpers/utlis";
import { getCostInfo } from "../../cart/helpers";
import CheckoutButton from "../../../atoms/checkout-button/CheckoutButton";
import { CHECKOUT_PAYMENT_PATH, ORDER_SUCCESS_PAGE } from "../../../../routes/routes-constant";
import { useNavigate } from "react-router-dom";
import { getLocalStorage, setLocalStorage } from "../../../../helpers/localStorageUtil";



const CustomerOrderConfirmation: React.FC = () => {
    const [isAPICallRunning,setIsAPICallRunning] =useState(false);
    const { showAlert } = useNotification();
    const navigate = useNavigate();
    const [billingAddress,setBillingAddress] = useState<Address>();
    const [isNewBillingAddress,setIsNewBillingAddress] = useState<boolean>(false);
    const [shippingAddress,setShippingAddress] = useState<Address>();
    const [isNewShippingAddress,setIsNewShippingAddress] = useState<boolean>(false);
    const [deliveryOption ,setDeliveryOption] =useState<string>("");
    const [costInfo, setCostInfo] = useState<{ [key: string]: number; }>();
    const [isGiftCart,setIsGiftCart] =useState<boolean>(false);
    const [isExistingCartGift ,setIsExistingCardGift] =useState<boolean>(false);
    const [countryList , setCountryList] = useState([]);
    const [couponValue,setCouponValue] = useState<string>("");
    const [advancedPayment,setAdvancedPayment] = useState(0);

    useEffect(()=>{
        const fetchData = async()=> {
        await fetchCountryList();
        }
        fetchData();
        
      },[])

    const fetchCountryList=async()=>{
        setIsAPICallRunning(true);
        doActionGet({
          url: apiList.getCountries,
        })?.then((resp: any) => {
            setCountryList(resp?.data);
        }
        ).catch((error: any) => {
          console.error("error msg", error);
          showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
        }).finally(()=>{
          setIsAPICallRunning(false);
        })
    }

    useEffect(()=>{
        setIsAPICallRunning(true);
        doActionGet({
          url: apiList.quoteIdURL,
          userType:USER_TYPE.Customer
        })?.then((resp: any) => {
            setIsExistingCardGift(resp?.data?.extension_attributes?.gift);
            setIsGiftCart(resp?.data?.extension_attributes?.gift);
            setDeliveryOption(resp?.data?.extension_attributes?.delivery_method);
            setCostInfo(getCostInfo(resp?.data?.extension_attributes?.cart_total));
            setCouponValue(resp?.data?.extension_attributes?.coupon_string);
            setAdvancedPayment(resp?.data?.extension_attributes?.selected_advance_payment_option);
            setBillingAddress(resp?.data?.billing_address);
            setLocalStorage("cartData", JSON.stringify(resp?.data));
            // let selctedBillingAddress=resp?.data?.customer?.addresses?.filter((item:Address)=>{
            //     if(item?.default_billing){
            //         return item;
            //     }
            // })
            // if(selctedBillingAddress?.length>0){
            //     setBillingAddress(selctedBillingAddress[0]);
            // }
            // else{
            //     setIsNewBillingAddress(true);
            //     setBillingAddress(resp?.data?.billing_address);
            // }
                if(Number(getLocalStorage("selectedShipmentAddressId"))<1){
                    setIsNewShippingAddress(true);
                   
                }
                setShippingAddress(resp?.data?.extension_attributes?.shipping_assignments[0]?.shipping?.address);
            //}
        }
        ).catch((error: any) => {
          console.error("error msg", error);
          showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
        }).finally(()=>{
          setIsAPICallRunning(false);
        })
    },[])

    const checkoutButtonHandler =()=>{
        navigate(CHECKOUT_PAYMENT_PATH);     
    }

    return (
    <S.Content>
         {isAPICallRunning && <Loading />}
        <div className="customer-checkout">
        <CheckoutHeader headerText={CHECKOUT_FIRST_STEP_HEADING}/>
            <CheckoutProgressBar id={2} title={progressBar[1].title}/>
            {/* <CheckoutHeader headerText={CHECKOUT_FIRST_STEP_HEADING}/>
            <CheckoutProgressBar id={2} title={progressBar[2].title}/> */}
            {/* Billing Address section */}
            {/* <div className="card-section">
                <div className="head-content-row">{SELECTED_BILLING_ADDRESS_HEADING}</div>
                <div className="first-content-row">{isNewBillingAddress?billingAddress?.firstname+" "+billingAddress?.lastname:getAttributeDataBasedOnCode(billingAddress?.extension_attributes?.address_encryption_attribute_data,"firstname")[0]?.value +" "+ getAttributeDataBasedOnCode(billingAddress?.extension_attributes?.address_encryption_attribute_data,"lastname")[0]?.value}</div>
                <div className="second-content-row">{(isNewBillingAddress ? billingAddress?.street :getAttributeDataBasedOnCode(billingAddress?.extension_attributes?.address_encryption_attribute_data,"street")[0]?.value) + " "+ billingAddress?.city + " "+billingAddress?.postcode + " , "+ getCountryName(countryList,billingAddress?.country_id)[0]?.full_name_locale}</div>
            </div> */}
          
          <div className="customer-delivery">
          <div className="address-heading">{SELECTED_BILLING_ADDRESS_HEADING}</div>
             {billingAddress && shippingAddress && <>
                {(getLocalStorage("deliveryCarrierCode")===CLICK_AND_COLLECT_CODE)?<div className="first-address-row">
                  <div className="content-data">NA</div></div>:
                <>
                 <div className="first-address-row">
                 <div className="content-data">{billingAddress?.firstname +" "+ billingAddress?.lastname}</div></div>
                 <div className="address-row">
                    <div className="content-data">{billingAddress?.street+ " "+ billingAddress?.city + " "+billingAddress?.postcode + " , "+ getCountryName(countryList,billingAddress?.country_id)[0]?.full_name_locale}</div></div>
                </>
                }
                </>}
               
            </div>
            
             {/* Shipping Address section */}
             <div className="customer-delivery">
             <div className="address-heading">{SELECTED_SHIPPING_ADDRESS_HEADING}</div>

             {billingAddress && shippingAddress && <>
                {(getLocalStorage("deliveryCarrierCode")===CLICK_AND_COLLECT_CODE)?<div className="first-address-row">
                  <div className="content-data">NA</div></div>:
                <>
                  <div className="first-address-row">{shippingAddress?.firstname +" "+ shippingAddress?.lastname}</div>
                  <div className="address-row">{shippingAddress?.street+ " "+ shippingAddress?.city + " "+shippingAddress?.postcode + " , "+ getCountryName(countryList,shippingAddress?.country_id)[0]?.full_name_locale}</div>
                </>
                }
                </>}
               
            </div>
            {/* Delivery Option section */}
            <div className="customer-delivery">
             <div className="address-heading">{SELECTED_SHIPMENT_METHOD}</div>
             <div className="first-address-row">{deliveryOption}</div>
            </div>
          
            {/* Summary section */}
           {costInfo && <div className="customer-delivery">
             <div className="first-address-row">{ORDER_TOTAL_HEADING}</div>
             <div className="line total-line"></div>
            <div className="cost-info">
                <div className="text-label">{SUBTOTAL_LABEL}</div><span>:</span><p>{formatPriceAndCurrency(costInfo?.['subtotal'])}</p>
            </div>
            <div className="cost-info">
                <div className="text-label">{SHIPPING_FEE_LABEL}</div><span>:</span><p>{formatPriceAndCurrency(costInfo?.['shipping'])}</p>
            </div>
            <div className="cost-info">
                <div className="text-label">{ESTIMATED_TAX_LABEL}</div><span>:</span><p>{formatPriceAndCurrency(costInfo?.['tax'])}</p>
            </div>
            {costInfo?.["discount"] && couponValue &&  <div className="cost-info">
              <div className="text-label">{`${OFFER_LABEL}(${couponValue})`}</div>
              <span>:</span>
              <p>
              -{formatPriceAndCurrency((costInfo?.["discount"] ?costInfo?.["discount"]*(-1):0))}
              </p>
            </div>}
            <div className="line grand-total-line"></div>
            <div className="total-amount">
                <div className="cost-info">
                    <p className="cost-total">{TOTAL_LABEL}</p><span className="cost-total-p">:</span><p className="cost-total-p">{formatPriceAndCurrency(costInfo?.['grand_total'], 2)}</p>
                </div>
                {advancedPayment>0 && costInfo?.['grand_total'] &&
                <>
                <div className="cost-info">
                    <p className="cost-total">{ADVANCED_AMOUNT_LABEL}</p><span className="cost-total-p">:</span><p className="cost-total-p">{formatPriceAndCurrency((((costInfo?.['grand_total'])*(advancedPayment))/100), 2)}</p>
                </div>

                <div className="cost-info">
                <p className="cost-total">{BALANCED_AMOUNT_LABEL}</p><span className="cost-total-p">:</span><p className="cost-total-p">{formatPriceAndCurrency((costInfo?.['grand_total']-((costInfo?.['grand_total'])*(advancedPayment))/100), 2)}</p>
                </div>
                
                </>
                }
            </div>
            </div>}
           
           {/* Footer section */}
           <div className="place-order-button">
            <CheckoutButton isDisabled={false} label={CONITNUE_BUTTON_LABEL} checkoutHandler={checkoutButtonHandler}/>
             </div>  
        </div>
        
    </S.Content>
  );
};

export default CustomerOrderConfirmation;
