import React, { useContext, useEffect, useState } from "react";
import * as S from "../../styles/styles";
import apiList, { ADD_NEW_CARD_HEADING, ALERT_STATUS, BANK_TRANSFER_PAYMENT_METHOD_LABEL, CARD_NO_LABEL, CASH_ON_DELIVERY_CODE, CASH_ON_DELIVERY_METHOD_LABEL, CASH_ON_DELIVERY_METHOD_SUB_HEADING, CVV_LABEL, EXPIRY_DATE_LABEL, NO_AVAILABLE_CARDS, PAY_BUTTON_LABEL, PLACE_ORDER_BUTTON_LABEL, PLACE_ORDER_SUBHEADING, PURCHASE_ORDER_CODE, SAVED_CARD_HEADING, SOMETHING_WENT_WRONG, TRANSACTION_REFERENCE_NUMBER, TRANSACTION_REFERENCE_NUMBER_PLACEHOLDER, USER_TYPE } from "../../../../../lib/constant";
import { CHECKOUT_PAYMENT_CARD_FORM_PATH, ORDER_FAILURE_PAGE, ORDER_SUCCESS_PAGE } from "../../../../../routes/routes-constant";
import { useLocation, useNavigate } from "react-router-dom";
import { doActionGet, doActionPost, doActionPut } from "../../../../../helpers/httpRequest";
import { useNotification } from "../../../../../hooks/useNotification";
import { Loading } from "../../../../atoms/loading/Loading";
import CheckoutButton from "../../../../atoms/checkout-button/CheckoutButton";
import { clearCartLocalStorage, getGrandTotalValue, gtmDataConstructor, raiseAssistanceRequest } from "../../helper";
import { ACTION_TYPE } from "../../../../../Context/Constant";
import { DataContext } from "../../../../../Context/AppContext";
import { paymentMethodProps } from "../../types/types";
import { secondPaymentCompletion_info_gtm } from "../../../../../helpers/GTM";


const Cash:React.FC<paymentMethodProps> = ({isSecondPayment,methodTitle}) => {
    const navigate = useNavigate();
    const [isAPICallRunning, setIsAPICallRunning] = useState(false);
    const { showAlert } = useNotification();
    const [transctionRefNo, setTransactionRefNo] = useState<string>("");
    const { dispatch } = useContext(DataContext);
    const location =useLocation();
    
    const placeOrder = () => {
        setIsAPICallRunning(true);
        if(isSecondPayment){
             //second payment implementation
             if(location?.state && location?.state?.orderId && location?.state?.totalDue){
                doActionPut({
                    url: apiList.setPendingOrder(location?.state?.orderId),
                    data:
                    {
                        "paymentMethod": {
                            "method": CASH_ON_DELIVERY_CODE,
                            "extension_attributes" : {
                                "paid_amount":location?.state?.totalDue,
                                "method_title": methodTitle,
                            }
                        }
                    },
                    userType: USER_TYPE.Customer
                })?.then((resp: any) => {
                    if(resp){
                        raiseAssistanceRequest();
                        navigate(ORDER_SUCCESS_PAGE, { state: { orderId: location?.state?.orderId,isSecondPayment:isSecondPayment, approvalRequired:true, incrementId:location?.state?.incrementId  } })
                        secondPaymentCompletion_info_gtm(location?.state?.totalDue , location?.state?.orderId,CASH_ON_DELIVERY_CODE);
                    }
                    else{
                        showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
                    }
                }
                ).catch((error: any) => {
                    console.log("error msg", error);
                    //navigate(ORDER_FAILURE_PAGE);
                    showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
                }).finally(() => {
                    setIsAPICallRunning(false);
                })
             }
        }
        else{
        doActionPut({
            url: apiList.placeOfflineOrders,
            data:
            {
                "paymentMethod": {
                    "method": CASH_ON_DELIVERY_CODE,
                    "extension_attributes" : {
                        "paid_amount":getGrandTotalValue()
                    }
                }
            },
            userType: USER_TYPE.Customer
        })?.then((resp: any) => {
            if(resp){
                raiseAssistanceRequest();
                gtmDataConstructor("purchase",CASH_ON_DELIVERY_CODE,resp?.data);
                clearCartLocalStorage();
                dispatch({ type: ACTION_TYPE.set_item_count, payload: { count: 0 } })
                navigate(ORDER_SUCCESS_PAGE, { state: { orderId: resp?.data?.order_id ,incrementId:resp?.data?.increment_id , approvalRequired:true } })
            }
            else{
                showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
            }
        }
        ).catch((error: any) => {
            console.log("error msg", error);
            //navigate(ORDER_FAILURE_PAGE);
            showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
        }).finally(() => {
            setIsAPICallRunning(false);
        })
    }
    }

    return (
        <S.Content>
            {isAPICallRunning && <Loading />}
            <div className="customer-delivery">
             <div className="cash-payment-heading">{PLACE_ORDER_SUBHEADING}</div>
            </div>
            <div className="checkout-continue-button">
                <CheckoutButton isDisabled={false} label={PLACE_ORDER_BUTTON_LABEL} checkoutHandler={placeOrder} />
            </div>
        </S.Content>
    );
};

export default Cash;
