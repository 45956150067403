import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as Profile } from '../../../../globals/svg/footerIcons/profile.svg';
import { ReactComponent as InProfile } from '../../../../globals/svg/footerIcons/profile-selected.svg';
import { ReactComponent as InOrders } from '../../../../globals/svg/employee-footer-icons/orders-selected.svg';
import { ReactComponent as Orders } from '../../../../globals/svg/employee-footer-icons/orders.svg';
import { ReactComponent as InRequest } from '../../../../globals/svg/employee-footer-icons/requests-selected.svg';
import { ReactComponent as Request } from '../../../../globals/svg/employee-footer-icons/requests.svg';
import { ReactComponent as InTraining } from '../../../../globals/svg/employee-footer-icons/training-selected.svg';
import { ReactComponent as Training } from '../../../../globals/svg/employee-footer-icons/training.svg';

import * as S from "./styles/styles";
import { MOLECULES_CONSTANT } from "../../Constant";
import { EMPLOYEES, RESTRICTED_PATHS } from "../../../../lib/constant";
import { MANAGE_ORDER, TRAINING_RESOURCES_ROUTE_PATH, ASSISTANCE_PATH, EMPLOYEE_PROFILE_PATH } from "../../../../routes/routes-constant";
import { useAuth } from "../../../../Context/AuthContext";
import { getStaticConstantValue } from "../../../../helpers/utlis";

interface FooterProps {
  activeIndex?: number;
}

const StoreManagerNavigationBar: React.FC<FooterProps> = () => {
  const { isEmployeeLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [showFooter, setShowFooter] = useState(false);

  const footerData = [
    /*    { icon: <Home />, name: MOLECULES_CONSTANT.home.en, selectedIcon: <InHome />, path: TRAINING_RESOURCES_ROUTE_PATH, hasCount: false }, */
    {
      icon: <Request />, name: MOLECULES_CONSTANT.requests.en, selectedIcon: <InRequest />, path: ASSISTANCE_PATH, hasCount: false
    },
    { icon: <Orders />, name: MOLECULES_CONSTANT.orders.en, selectedIcon: <InOrders />, path: MANAGE_ORDER, hasCount: false },
    { icon: <Training />, name: MOLECULES_CONSTANT.training.en, selectedIcon: <InTraining />, path: TRAINING_RESOURCES_ROUTE_PATH, hasCount: false },
    { icon: <Profile />, name: MOLECULES_CONSTANT.profile.en, selectedIcon: <InProfile />, path: EMPLOYEE_PROFILE_PATH, hasCount: false }
  ];

  useEffect(() => {
    const shouldShowFooter = isEmployeeLoggedIn && !RESTRICTED_PATHS.includes(location.pathname) && (getStaticConstantValue('requested_by') === EMPLOYEES.STORE_MANAGER);
    setShowFooter(shouldShowFooter);
  }, [isEmployeeLoggedIn, location.pathname]);

  return (
    <S.Content>
      {showFooter && (
        <div className="footer">
          {footerData.map(({ icon, name, selectedIcon, hasCount, path }) => {
            const isSelected = (location.pathname === path);
            return (
              < div key={name} className={`icon ${!hasCount ? 'no-count' : ''}`} onClick={() => { navigate(path) }}>
                <div className={`${isSelected ? 'top' : 'top-inactive'}`}></div>
                {isSelected ? selectedIcon : icon}
                <span className={`name ${isSelected ? 'active' : ""}`}>{name}</span>
              </div>)
          })}
        </div>
      )
      }
    </S.Content >
  );
};

export default StoreManagerNavigationBar;
