import React, { ChangeEvent, useEffect, useState } from "react";
import apiList, { ADD_AMOUNT_HEADING, ALERT_STATUS, ARE_YOU_SURE_WANT_TO_OPEN_THE_DAY, CURRENCY_DENOMINATION_MISMATCH_ERROR, currencyDenomination, DETAILS_HEADING, DETAILS_SUB_HEADING, OPEN_CASH_REGISTERY, RECEIVED_CASH_BUTTON_LABEL, SOMETHING_WENT_WRONG, USER_TYPE } from "../../../lib/constant";
import { doActionPost } from "../../../helpers/httpRequest";
import { useNotification } from "../../../hooks/useNotification";
import ConfirmationModal from "../../atoms/confirmation-modal/ConfirmationModal";
import { setLocalStorage } from "../../../helpers/localStorageUtil";
import { getStaticConstantValue } from "../../../helpers/utlis";
import { CurrencyValues } from "./types/types";

const OpenCash: React.FC<{employeeId:string,statusChangeHandler:(status:string)=>void}> = ({employeeId,statusChangeHandler}) => {
    const [value, setValue] = useState<number | string>("");
    const { showAlert } = useNotification();
    const [showModal,setShowModal] = useState(false);
    const currency_symbol = getStaticConstantValue('currency_symbol');
    const [values, setValues] = useState<CurrencyValues>({
      '1000': '',
      '500': '',
      '200': '',
      '100': '',
      '50': '',
      '10': '',
      'Dirham': '',
      'Fils': ''
    });
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

    const calculateTotal = (): number => {
      return Object.keys(values).reduce((total, key) => {
        // Check if the key is a valid number (denominations are numbers)
        const denomination = parseFloat(key);
        const quantity = parseFloat(values[key]) || 0;
  
        // Only add to the total if the key is a number
        if (!isNaN(denomination)) {
          return total + denomination * quantity;
        }
        else if(key==="Dirham" || key==="Fils"){
          return total + quantity;
        }
        return total;
      }, 0);
    };
   
    useEffect(() => {
      const total = calculateTotal();
      (total !== Number(value) || value==="")?setIsButtonDisabled(true):setIsButtonDisabled(false);
    }, [values]);
  
    // Handle change in input fields
    const handleCurrencyChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value
      });
    };
  
    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let inputValue = e.target.value;
  
      const validValue = inputValue.match(/^\d*\.?\d*$/); 
  
      if (validValue) {
        const splitDecimal = inputValue.split('.');
        if (splitDecimal.length > 1) {
          inputValue = `${splitDecimal[0]}.${splitDecimal[1].slice(0, 2)}`;
        }
  
        setValue(inputValue);
      }
    };
  

    const openCashHandler = () =>{
        setShowModal(false);
        doActionPost({
            url: apiList.startDayCash,
            data:
            {
                "data": {
                    "employeeId": Number(employeeId),
                    "initialCash": Number(value)
                }

            },
            userType:USER_TYPE.Employee
          })?.then((resp: any) => {
            statusChangeHandler("OPEN");
            setLocalStorage("shiftStatus","OPEN");
          })?.catch((error: any) => {
            console.log("error msg", error);
            showAlert(error?.response?.data?.message, ALERT_STATUS.error);
          })
    }

    const openButtonCashHandler=()=>{
      if(isButtonDisabled){
        showAlert(CURRENCY_DENOMINATION_MISMATCH_ERROR, ALERT_STATUS.error);
      }
      else{
        setShowModal(true)
      }
    }

    return (
        <>
        <ConfirmationModal  isOpen={showModal} message={ARE_YOU_SURE_WANT_TO_OPEN_THE_DAY} onConfirm={openCashHandler} onCancel={()=>setShowModal(false)}/>
            <div className="registery-heading">{OPEN_CASH_REGISTERY}</div>
            <div className="add-amount-heading">{ADD_AMOUNT_HEADING}</div>
            <div className="input-box">

                 <div className="input-section">
                      {value !=="" &&  <span className="currency-symbol-section">{currency_symbol}</span>}
                      <input
                        type="text"
                        value={value}
                        onChange={handleChange}
                        placeholder="Enter Full Amount"
                        className={value===""?"numberInputWithoutSymbol":"numberInput"}
                      />
                    </div>
            </div>
            <div className="amount-denomination">
            <div className="add-amount-main-heading">{DETAILS_HEADING}</div>
            <div className="add-amount-sub-heading">{DETAILS_SUB_HEADING}</div>
            <div className="currency-denomination">
              {currencyDenomination?.map((denomination) => (
              <div className="amount-desc" key={denomination}>
                <label  className="amount-label" htmlFor={denomination}>{denomination}</label>
                <input
                  type="number"
                  id={denomination}
                  name={denomination}
                  value={values[denomination]}
                  onChange={handleCurrencyChange}
                  className="amount-input"
                />
              </div>
            ))}
            </div>
            </div>
                <div className="button-box">
                    <button type="submit" onClick={openButtonCashHandler} className={isButtonDisabled?"received-disabled-button-label":"received-button-label"}>{RECEIVED_CASH_BUTTON_LABEL}</button>
                </div>
        </>
  );
};

export default OpenCash;
