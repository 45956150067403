import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";

export const Content = styled.div`
  width: 100%;
  min-height: var(--main-content-height);
  background: #F8F7F2;
  
  .editProfileTitle {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 110%;
    padding:20px;
  }

  .edit-profile-area{
    background:#ffffff;
    padding:15px 20px;
  }

  .field-area {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
  }

  .field-title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 110%;
    color: #1C1C1C;
  }
  .field-span{
  font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    color: #1C1C1C;
    }
  .input-field {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #1C1C1C;
    background: #F8F7F2;
    border-radius: 6px;
    border:none;
    margin:10px 0;
    padding: 16px 8px;
    
  }
  .preference-msg{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.05em;
color: #1C1C1C;
margin:5px;

}
  .name-part{
  width:43%;
   margin:10px 3px;

    @media (min-width: 600px) {
            width: 47%;
  }
  }

  .non-editable{
  background:#E3E2DE;
  pointer-events:none;

  }
  .name-fields {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .accordion-section-container {
    margin-top: 2rem;
  }

  .title-dropdown {
    margin-bottom:10px;
      background: #F8F7F2;
  }

  .non-editable-mode {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    margin-bottom: 1rem;
    color: var(--grey-text);

    > div > .label {
      font-weight: bold;
    }
  }

  .non-editable-mode-shipping, .non-editable-mode-billing, .details-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .personal-info-section-button {
    background: var(--white);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    color: var(--grey-text);
    padding: 10px 20px;
    font-weight: 600;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin: 0 30px;
    height: 50px;

    .edit-button, .cancel-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .add-new-button {
    background: var(--white);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    color: var(--grey-text);
    padding: 10px 20px;
    font-weight: 400;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin: 10px;
    height: 50px;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .my-preferences {
    color: var(--grey-text);

    span {
      line-height: 24px;
    }

    input[type="checkbox"] {
      line-height: 24px;
    }
  }

  .save-profile-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
  }

  .save-profile-button {
    width: auto;  // Adjusts based on content, with a minimum width to maintain appearance
    min-width: 9rem;  // Keeps the button from becoming too small
    height: 50px;
    background-color: var(--sand-button-color);
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    border: none;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--white);
    text-transform: none;
    padding: 7px 52px;
    cursor: pointer;

    &:hover {
      background-color: var(--sand-button-hover);
    }
  }
    .checkbox-container {
    display: flex;
    flex-direction: column;
       background: #F8F7F2;
       padding:10px 0;
}

.checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Adjust spacing as needed */
}

.custom-checkbox {
    width: 16px;
    height: 16px;
    border: 1px solid #6F6855;
    border-radius: 4px;
    appearance: none;
    outline: none;
    cursor: pointer;
    position: relative;
    margin-right: 8px; 
    background-color: #fff;
}

.custom-checkbox:checked::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #6F6855;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.checkbox-label {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #6F6855;
}

`;

export const Loader = styled(CircularProgress)`
  align-self: center;
`;
