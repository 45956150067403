import React from 'react';
import { Input, InputContainer, Label, ErrorText } from './styles/styles';
import { InputFieldProps } from './types/types';

const InputField: React.FC<InputFieldProps> = ({ label, type, error, ...props }) => {
    return (
        <InputContainer inputType={type}>
            {type !== 'checkbox' && type !== 'radio' && <Label htmlFor={props.id || props.name} inputType={type}>{label}</Label>}
            <Input type={type} hasError={!!error} {...props} />
            {error && <ErrorText>{error}</ErrorText>}
            {(type === 'checkbox' || type === 'radio') && <Label htmlFor={props.id || props.name} inputType={type}>{label}</Label>}
        </InputContainer>
    );
};

export default InputField;