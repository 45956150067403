
import { CART_API_ERRORS, CART_API_URL, CART_CONSTANTS, CART_UPDATE_API_URL, PRODUCT_URL } from "./constants"
import apiList, { ALERT_STATUS, COUPONS, COUPON_SUCCESSFULLY_APPLIED_MSG, COUPON_SUCCESSFULLY_REMOVED_MSG, INVALID_COUPON_MSG, OUT_OF_STOCK, PLEASE_CHECK_TERMS_AND_CONDITIONS, TERMS_AND_CONDITIONS_TEXT, USER_TYPE } from "../../../lib/constant"
import useFetchData from "../../../hooks/useFetchData"
import * as S from "./styles";
import { CartDetails, GTMProductDetail, QuantityHandlerParams } from "./types";
import { formatPriceAndCurrency } from "../../../helpers/utlis";
import { ReactComponent as DeleteItem } from './../../../globals/svg/delete.svg'
import { ReactComponent as SubtractIcon } from "../../../globals/svg/subtract.svg"
import { ReactComponent as AddIcon } from "../../../globals/svg/add.svg"
import React, { useContext, useEffect, useState } from "react";
import { doActionDelete, doActionPut } from "../../../helpers/httpRequest";
import { useNotification } from "../../../hooks/useNotification";
import { getCostInfo, getFormattedItems } from "./helpers";
import { useNavigate } from "react-router-dom";
import { FURNITURE_CHECKOUT_PATH } from "../../../routes/routes-constant";
import { DataContext } from "../../../Context/AppContext";
import { ACTION_TYPE } from "../../../Context/Constant";
import { removeAllItemFromCart_gtm, removeItemFromCart_gtm, updateCart_gtm, viewCart_gtm } from "../../../helpers/GTM";
import { removeLocalStorage, setLocalStorage } from "../../../helpers/localStorageUtil";
import { CartItem, OrganizedCartItems } from "../employee-special-order-cart/special-cart/types/types";
import { ReactComponent as DeleteIcon } from '../../../globals/svg/delete.svg';
import { Loading } from "../../atoms/loading/Loading";
import ConfirmationModal from "../../atoms/confirmation-modal/ConfirmationModal";

const CartView = () => {
  const [localQuantity, setLocalQuantity] = useState<{ [key: string]: number }>({})
  const [isAPICallInProgress, setIsAPICallInProgress] = useState(false)
  const [costInfo, setCostInfo] = useState<{ [key: string]: number; }>()
  const [isCouponApplied, setIsCouponApplied] = useState<boolean>(false);
  const [couponValue, setCouponValue] = useState<string>("");
  const [isCartLocked, setIsCartLocked] = useState(1);
  const [quoteId, setQuoteId] = useState<number>(0);

  const { showAlert } = useNotification();
  const { isLoading, data: cartItems } = useFetchData(CART_API_URL, USER_TYPE.Customer, false)
  const [items,setItems] = useState<any>([]);
  const selectedAdvancePaymentOption = (cartItems as unknown as CartDetails)?.extension_attributes?.selected_advance_payment_option ?? '';
  const cartResp = (cartItems as unknown as CartDetails);
  const navigate = useNavigate();
  const { dispatch } = useContext(DataContext);
  const [formattedItemsForGTM, setFormattedItemsForGTM] = useState<GTMProductDetail[]>([]);
  const [advancePayAmount, setAdvancePayAmount] = useState(0);
  const [balanceDueAmount, setBalanceDueAmount] = useState(0);
  const [isConsentGiven, setIsConsentGiven] = useState<boolean>(false);
  const hasAdvancePaymentOption = Boolean(selectedAdvancePaymentOption) && isCartLocked;
  const [showModal, setShowModal] = useState(false);

  useEffect(()=>{
    setItems((cartItems as unknown as CartDetails)?.items ??[])
  },[cartItems])
  // Advance Payment and Due Balance Calculation
  useEffect(() => {
    let paymentPercentage: number = 0;
    if (selectedAdvancePaymentOption) {
      paymentPercentage = Number(selectedAdvancePaymentOption);
    }

    if (paymentPercentage && costInfo) {
      const advancePay = ((costInfo?.['grand_total'] * paymentPercentage) / 100);
      const balanceDue = (costInfo?.['grand_total'] - (costInfo?.['grand_total'] * paymentPercentage) / 100);
      setAdvancePayAmount(Number(advancePay.toFixed(2))); //round off to 2 digits and then convert it to a number
      setBalanceDueAmount(Number(balanceDue.toFixed(2)));
    }

  }, [costInfo, selectedAdvancePaymentOption]);

  useEffect(() => {

    if (items?.length) {
      viewCart_gtm(items.map((item:any, index:any) => {
        const { name, item_id, price, qty } = item
        let variantData: { [key: string]: string } = {}
        item?.extension_attributes?.attribute_info?.forEach((variant:any) => {
          const modifiedLabel = variant.label
            .toLowerCase()
            .replace(/ /g, '_');
          variantData[modifiedLabel] = variant.value
        })
        return ({ ...variantData, item_name: name, item_id, quantity: qty, price, index })
      }))
      let quantity: { [key: string]: number } = {};
      items.forEach((item:any) => { quantity[String(item.item_id)] = item.qty })
      setLocalQuantity(quantity)
    }
    if (cartItems) {
      setCostInfo(getCostInfo((cartItems as unknown as CartDetails)?.extension_attributes?.cart_total))
      //check and set cart locked status
      const lockedStatus = (cartItems as unknown as CartDetails)?.extension_attributes?.locked ?? 0;
      setIsCartLocked(lockedStatus);
      //get and set quoteId
      const getQuoteId = (cartItems as unknown as CartDetails)?.id ?? 0;
      setQuoteId(getQuoteId);
    }

    if (cartResp?.extension_attributes?.coupon_string) {
      setIsCouponApplied(true);
      setCouponValue(cartResp?.extension_attributes?.coupon_string);
    }
  }, [cartItems, items?.length])

  //Formatting/Preparing Data for GTM
  useEffect(() => {
    if (items?.length) {
      // Mapping the items array to the format required by the dataLayer
      const formattedItems = getFormattedItems(items);

      setFormattedItemsForGTM(formattedItems);
    }

  }, [items?.length]);

  const cartIsLockedMessage = () => {
    showAlert('Cart is Locked, Please Proceed with checkout', ALERT_STATUS.info);
  }

  const quantityComponent = ({ qty, item_id, sku, quote_id, name, variantData }: QuantityHandlerParams) => {
    const handleUpdate = async (valueChange: number) => {

      if (isCartLocked) {
        cartIsLockedMessage();
        return;
      }

      updateCart_gtm([{ ...variantData, item_id, quantity: qty + valueChange, coupon: couponValue, item_name: name }])
      const payload = {
        "cartItem": {
          sku,
          qty: (qty + valueChange),
          quote_id
        }
      }
      try {
        setIsAPICallInProgress(true)
        const response = await doActionPut({
          url: CART_UPDATE_API_URL(item_id),
          userType: USER_TYPE.Customer,
          data: payload
        })
        if (response?.data) {
          dispatch({ type: ACTION_TYPE.increment_cart_item_count, payload: { count: valueChange } })
          setLocalQuantity(pre => ({ ...pre, [item_id]: (response?.data?.qty) }))
          const cartTotal = response?.data?.extension_attributes?.cart_total
          if (cartTotal) setCostInfo(getCostInfo(cartTotal))
        }
      }
      catch (err) { showAlert(CART_API_ERRORS.cart_update_error, ALERT_STATUS.error) }
      finally { setIsAPICallInProgress(false) }
    }

    return <div className={`quantity-changer ${isCartLocked ? 'disabled' : ''}`}>
      <div onClick={() => handleUpdate(-1)} className={`quantity-button ${qty < 2 ? 'disabled' : ''}`}><SubtractIcon /></div>
      {qty}
      <div onClick={() => handleUpdate(1)} className={`quantity-button`}><AddIcon /></div>
    </div>
  }

  const handleDeleteItem = async (qty: number, item_id: number) => {
    if (isCartLocked) {
      cartIsLockedMessage();
      return;
    }

    try {
      setIsAPICallInProgress(true)
      const response = await doActionDelete({
        url: CART_UPDATE_API_URL(item_id),
        userType: USER_TYPE.Customer,
      })
      if (response?.data) {
        let newitems = items?.filter((item:any)=>{
          return item?.item_id !== item_id;
        });
        setItems(newitems);
        dispatch({ type: ACTION_TYPE.decrement_cart_item_count, payload: { count: qty } })
        setLocalQuantity(pre => ({ ...pre, [item_id]: (response?.data?.qty) }))
        setCostInfo(getCostInfo(response?.data))
      }
    }
    catch (err) { showAlert(CART_API_ERRORS.item_removal_error, ALERT_STATUS.error) }
    finally { setIsAPICallInProgress(false) }
  }

  const removeAllItemsInCart = async () => {
    try {
      setIsAPICallInProgress(true)
      const response = await doActionDelete({
        url: apiList.removeAllItems,
        userType: USER_TYPE.Customer,
      })
      if (response?.data) {
        dispatch({ type: ACTION_TYPE.set_item_count, payload: { count: 0 } })
        if (formattedItemsForGTM) {
          removeAllItemFromCart_gtm(formattedItemsForGTM);
        }
        setLocalQuantity({})
        setCostInfo(getCostInfo(response?.data))
      }
    }
    catch (err) { showAlert(CART_API_ERRORS.item_removal_error, ALERT_STATUS.error) }
    finally { setIsAPICallInProgress(false) }
  }

  const couponHandler = () => {

    if (!isCouponApplied) {
      setIsAPICallInProgress(true);
      doActionPut({
        url: apiList.quoteIdURL + "/" + COUPONS + couponValue,
        userType: USER_TYPE.Customer,
      })
        ?.then((resp: any) => {
          if (resp?.data) {
            const cartTotal = resp?.data;
            if (cartTotal) {
              setCostInfo(getCostInfo(cartTotal));
              setIsCouponApplied(true);
              setLocalStorage("coupon_string", couponValue);
              showAlert(
                COUPON_SUCCESSFULLY_APPLIED_MSG,
                ALERT_STATUS.success
              );
            }
          }
        })
        .catch((error: any) => {
          console.log("error msg", error);
          showAlert(INVALID_COUPON_MSG, ALERT_STATUS.error);
        })
        .finally(() => {
          setIsAPICallInProgress(false);
        });
    } else {
      setIsAPICallInProgress(true);
      doActionDelete({
        url: apiList.quoteIdURL + "/" + COUPONS,
        userType: USER_TYPE.Customer,
      })
        ?.then((resp: any) => {
          if (resp?.data) {
            if (resp?.data) {
              const cartTotal = resp?.data;
              if (cartTotal) {
                setCostInfo(getCostInfo(cartTotal));
                setIsCouponApplied(false);
                removeLocalStorage("coupon_string");
                setCouponValue("");
                showAlert(
                  COUPON_SUCCESSFULLY_REMOVED_MSG,
                  ALERT_STATUS.success
                );
              }
            }
          }
        })
        .catch((error: any) => {
          console.log("error msg", error);
          showAlert(INVALID_COUPON_MSG, ALERT_STATUS.error);
        })
        .finally(() => {
          setIsAPICallInProgress(false);
        });
    }
  };

  const redirectToProduct = (sku: string, itemId: number, isSimple: boolean) => {
    navigate(PRODUCT_URL(sku), { state: ({ ...(isSimple ? {} : { itemId }), fromCart: true }) })
  }

  const organizeCartItems = (items: CartItem[]): OrganizedCartItems[] => {
    const parentItems: { [key: number]: OrganizedCartItems } = {};
    items?.forEach(item => {
      if (item?.extension_attributes?.parent_id) {
        const parentId = item?.extension_attributes?.parent_id;
        if (parentItems[parentId]) {
          parentItems[parentId].children = parentItems[parentId].children || [];
          parentItems[parentId].children?.push(item);
        } else {
          parentItems[parentId] = { ...item, children: [item] };
        }
      } else {
        parentItems[item.item_id] = { ...item, children: [] };
      }
    });
    return Object.values(parentItems);
  };

  const checkoutPathHandler = () => {
    // if (isConsentGiven) {
      navigate(FURNITURE_CHECKOUT_PATH)
    // }
    // else {
    //   setShowModal(true);
    // }
  }

  return (
    <S.Content>
      <ConfirmationModal isOpen={showModal} message={PLEASE_CHECK_TERMS_AND_CONDITIONS} onConfirm={() => setShowModal(false)} onCancel={() => setShowModal(false)} />
      {items && items?.length > 0 ? (
        <div className="cart">
          <div className="cart-container">
            <div className="title-text"> {CART_CONSTANTS.cart.en}
              {isCartLocked ? <span className="remove-all-items-button" onClick={removeAllItemsInCart}>{CART_CONSTANTS.clear_cart.en}</span> : <></>}
            </div>
            <div className="item-container">
              {organizeCartItems(items)?.map((item, index) => {
                const {
                  name,
                  price,
                  quote_id,
                  sku,
                  item_id,
                  extension_attributes,
                  product_type
                } = item ?? {};
                if (!localQuantity[item_id]) return null;
                const hasChildren = item?.children && item?.children?.length > 0;
                const productSku = extension_attributes?.configure_parent_sku ?? sku;
                const inStock = item?.extension_attributes?.in_stock;
                let variantData: { [key: string]: string } = {}
                extension_attributes?.attribute_info?.forEach((variant: any) => {
                  const modifiedLabel = variant?.label
                    .toLowerCase()
                    .replace(/ /g, '_');
                  variantData[modifiedLabel] = variant.value
                })
                return (
                  <>
                    <div className="item" key={item_id}>
                      <img
                        className="item-image"
                        src={`${process.env.REACT_APP_PDP_IMAGES_BASE_URL}${extension_attributes?.image}`}
                        alt={`item${index}`}
                        onClick={() => redirectToProduct(productSku, item_id, product_type === 'simple')}
                      ></img>
                      <div
                        className="item-details"
                        onClick={() => redirectToProduct(productSku, item_id, product_type === 'simple')}
                      >
                        {(!inStock && !isCartLocked) ? (<div className="out-of-stock">{OUT_OF_STOCK}</div>) : (<div className="name">{name}</div>)}
                        {!inStock && <div className="name">{name}</div>}
                        <div className="sku">{sku}</div>
                        {variantData && Object.keys(variantData)?.length > 0 && <div className="sku">
                          {"(" + Object.values(variantData)?.join('  ,  ') + ")"}
                        </div>}
                        <div className="price">
                          {formatPriceAndCurrency(price)}
                        </div>
                      </div>
                      <div className="item-quantity">
                        {quantityComponent({
                          qty: localQuantity?.[item_id],
                          item_id,
                          sku,
                          quote_id,
                          name,
                          variantData
                        })}
                        <DeleteItem
                          className={`delete-icon ${isCartLocked ? 'disabled' : ''}`}
                          onClick={() => {
                            removeItemFromCart_gtm([{ ...variantData, name, price, id: item_id }])
                            handleDeleteItem(localQuantity?.[item_id], item_id);
                          }}
                        />
                      </div>
                    </div>
                    {hasChildren && item?.children && (
                      <div className="item-child-container">
                        {item?.children?.map(child => {
                          if (!localQuantity[child.item_id]) return null;
                          return (<div className="item-flex-container" key={child?.item_id} onClick={(e) => e.stopPropagation()}>
                            <img className="item-image" src={`${process.env.REACT_APP_PDP_IMAGES_BASE_URL}${child?.extension_attributes?.image}`} alt={`child${child?.item_id}`} />
                            <div className="item-details">
                              <div className="name">{child?.name}</div>
                              <div className="sku">{child?.sku}</div>
                              <div className="price">{formatPriceAndCurrency(child?.price)}</div>
                            </div>
                            <div className="item-quantity">
                              <div className="item-quantity-changer">
                                {quantityComponent({ qty: localQuantity[child?.item_id], item_id: child?.item_id, sku: child?.sku, quote_id: child?.quote_id, variantData: "", name: item?.name })}
                              </div>
                              <DeleteIcon className={`delete-icon ${isCartLocked ? 'disabled' : ''}`} onClick={() => handleDeleteItem(Number(child?.quote_id), child?.item_id)} />
                            </div>
                          </div>)
                        })}
                      </div>
                    )}
                  </>
                );
              })}
            </div>

            <div className="cost-info">
              <div>{CART_CONSTANTS.sub_total.en}</div>
              <span>:</span>
              <p>
                {formatPriceAndCurrency(costInfo?.["subtotal"])}
              </p>
            </div>

            <div className="cost-info">
              <div>{CART_CONSTANTS.estimating_tax.en}</div>
              <span>:</span>
              <p>
                {formatPriceAndCurrency(costInfo?.["tax"])}
              </p>
            </div>
            {costInfo?.["discount"] && <div className="cost-info">
              <div>{`${CART_CONSTANTS.DISCOUNT.en}(${couponValue})`}</div>
              <span>:</span>
              <p>
                -{formatPriceAndCurrency((costInfo?.["discount"] ? costInfo?.["discount"] * (-1) : 0))}
              </p>
            </div>}
            <div className="total-amount">
              {Number(costInfo?.["grand_total"])>=0 && Number(costInfo?.["shipping"]) >= 0 && (
                <div className="cost-info">
                  <p className="cost-total">{CART_CONSTANTS.total.en}</p>
                  <span>:</span>
                  <p>
                    {formatPriceAndCurrency((Number(costInfo?.["grand_total"]) - Number(costInfo?.["shipping"])), 2)}
                  </p>
                </div>
              )}
              {/* Advance Payment section */}
              {hasAdvancePaymentOption ? (
                <>
                  <div className="cost-info">
                    <p className="cost-total">{CART_CONSTANTS.advance_pay.en}</p><span>:</span><p>{formatPriceAndCurrency(advancePayAmount, 2)}</p>
                  </div>
                  <div className="cost-info">
                    <p className="cost-total">{CART_CONSTANTS.balance_due.en}</p><span>:</span><p>{formatPriceAndCurrency(balanceDueAmount, 2)}</p>
                  </div>
                </>
              ) : <></>}
            </div>

            {/* cupon section */}
            <div className="coupon-section">
              <div className="coupon-title">{CART_CONSTANTS.COUPON_TITLE.en}</div>
              <div className="coupon-field-and-button">
                <div className="coupon-inputbox">
                  <input
                    placeholder={CART_CONSTANTS.OFFER_LABEL.en}
                    className="coupon-input"
                    type="text"
                    value={couponValue}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setCouponValue(e.target.value)
                    }
                    disabled={isCouponApplied || !Boolean(items?.length)}
                  />
                </div>
                <div className="coupon-button">
                  <button
                    className="apply-button"
                    onClick={couponHandler}
                    disabled={couponValue === "" || !Boolean(items?.length)}
                  >
                    {isCouponApplied
                      ? CART_CONSTANTS.REMOVE_LABEL.en
                      : CART_CONSTANTS.APPLY_LABEL.en}
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="consent-section">
              <input className="consent-checkbox" type="checkbox" checked={isConsentGiven}
                onChange={() => { setIsConsentGiven(isConsentGiven => !isConsentGiven) }} />
              <label className="consent-text">{TERMS_AND_CONDITIONS_TEXT}</label>
            </div > */}
            
          </div>
          <div className="moveToCartBtn">
              <button disabled={false} onClick={checkoutPathHandler}>{CART_CONSTANTS.checkout.en}</button>
            </div>
        </div>
      ) : !(isLoading || isAPICallInProgress) ? (
        <div className="cart">
          <div className="cart-container">
            <div className="title-text"> {CART_CONSTANTS.cart.en}</div>
            <div className="empty-cart">{CART_CONSTANTS.empty_cart.en}</div>
          </div>
        </div>
      ) : <Loading />}
    </S.Content>
  );
}

export default CartView