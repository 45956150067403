import { LOGIN_TERMS_AND_CONDITIONS_TEXT } from "../../../../lib/constant";
import { LOGIN_CONSTANTS } from "../constant";
import { PrivacyPolicy } from "../styles/styles";

const LoginPrivacyPolicy = () => {
    return (
        <>
            <PrivacyPolicy>
            <div className="heading">{LOGIN_CONSTANTS.privacy_policy.en}</div>
            <p className="text-content">{LOGIN_TERMS_AND_CONDITIONS_TEXT}</p>
            </PrivacyPolicy>
        </>
    );
}

export default LoginPrivacyPolicy;