
import * as S from "./styles/productQuantityStyles";
import { ReactComponent as SubtractIcon } from "../../../globals/svg/subtract.svg"
import { ReactComponent as AddIcon } from "../../../globals/svg/add.svg"
import { ProductQuantityProps } from "./common/types";
import { formatPrice, getStaticConstantValue } from "../../../helpers/utlis";

const ProductQuantity = ({ price, maxQuantity, quantity, setQuantity }: ProductQuantityProps) => {
    return <S.Content>
        <div className="addToCartContainer">
            <div className="qtyPrice">
                <div className="qty">
                    <div onClick={() => setQuantity(q => q - 1)} className={`quantityButton ${quantity < 2 ? 'disabled' : ''}`}><SubtractIcon /></div>
                    {quantity}
                    <div onClick={() => setQuantity(q => q + 1)} className={`quantityButton ${quantity >= maxQuantity ? 'disabled' : ''}`}><AddIcon /></div>
                </div>
                <div className="price">{getStaticConstantValue("currency_symbol") + ' ' + formatPrice((quantity * price))}</div>
            </div>
        </div>
    </S.Content>
}

export default ProductQuantity;