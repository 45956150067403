import styled from "styled-components"

export const View = styled.div`

    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.1);
    overflow: hidden;
    div {
        align-items: center;
    }

.loader-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width: 200px;
  height: 100px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: black;
  text-align: center;
  z-index: 9999;
  overflow: hidden;
  padding: 10px;
  div {
      align-items: center;
  }
}

.label {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 200;
  font-family: 'Poppins', sans-serif;
  color: var(--sand-button-color);
  background: linear-gradient(90deg, var(--sand-button-color), rgba(255, 255, 255, 0.4));
  background-size: 200%;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: loading 2s infinite;
}

@keyframes loading {
  0% {
      background-position: 0% 50%;
  }
  100% {
      background-position: 100% 50%;
  }
}
`