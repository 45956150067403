import React, { useContext, useEffect, useState } from "react";
import * as S from "../../styles/styles";
import apiList, { ADD_NEW_CARD_HEADING, ALERT_STATUS, CARD_NO_LABEL, CVV_LABEL, EXPIRY_DATE_LABEL, NO_AVAILABLE_CARDS, PAY_BUTTON_LABEL, PLACE_ORDER, PLACE_ORDER_BUTTON_LABEL, SAVED_CARD_HEADING, SOMETHING_WENT_WRONG, STRIPE_PAYMENT_METHOD_CODE, USER_TYPE } from "../../../../../lib/constant";
import { CHECKOUT_PAYMENT_CARD_FORM_PATH, ORDER_FAILURE_PAGE, ORDER_SUCCESS_PAGE } from "../../../../../routes/routes-constant";
import { useLocation, useNavigate } from "react-router-dom";
import { doActionGet, doActionPost, doActionPut } from "../../../../../helpers/httpRequest";
import { useNotification } from "../../../../../hooks/useNotification";
import { Loading } from "../../../../atoms/loading/Loading";
import CheckoutButton from "../../../../atoms/checkout-button/CheckoutButton";
import { clearCartLocalStorage, getGrandTotalValue, gtmDataConstructor } from "../../helper";
import { DataContext } from "../../../../../Context/AppContext";
import { ACTION_TYPE } from "../../../../../Context/Constant";
import { paymentMethodProps } from "../../types/types";
import { secondPaymentCompletion_info_gtm } from "../../../../../helpers/GTM";


const CustomerCardListing: React.FC<paymentMethodProps> = ({isSecondPayment,methodTitle}) => {
    const navigate = useNavigate();
    const [isAPICallRunning, setIsAPICallRunning] = useState(false);
    const { showAlert } = useNotification();
    const [savedCardList, setSavedCardList] = useState([]);
    const [selectedId, setSelectedId] = useState<string>("");
    const { dispatch } = useContext(DataContext);
    const location = useLocation()

    useEffect(() => {
        setIsAPICallRunning(true);
        doActionGet({
            url: apiList.getCustomerSavedCart,
            userType: USER_TYPE.Customer
        })?.then((resp: any) => {
            if (resp?.data && Array.isArray(resp?.data) && resp?.data.length > 0) {
                setSavedCardList(resp.data[0]);
            }
        }
        ).catch((error: any) => {
            console.error("error msg", error);
            showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
        }).finally(() => {
            setIsAPICallRunning(false);
        })
    }, [])


    const placeOrder = () => {
        setIsAPICallRunning(true);
        if(isSecondPayment){
        //second payment implementation
        if(location?.state && location?.state?.orderId && location?.state?.totalDue){
            doActionPut({
                url: apiList.setPendingOrder(location?.state?.orderId),
                data:
                {
                    "paymentMethod": {
                        "method": STRIPE_PAYMENT_METHOD_CODE,
                        "extension_attributes": {
                            "paid_amount":location?.state?.totalDue,
                            "method_title": methodTitle,
                            "payment_id":selectedId
                        }
                    }
                },
                userType: USER_TYPE.Customer
            })?.then((resp: any) => {
                if(resp){
                    navigate(ORDER_SUCCESS_PAGE, { state: { orderId: resp?.data?.order_id ,isSecondPayment:isSecondPayment,incrementId:resp?.data?.increment_id } })
                    secondPaymentCompletion_info_gtm(location?.state?.totalDue , location?.state?.orderId,STRIPE_PAYMENT_METHOD_CODE);
                }
                else{
                    showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
                }
            }
            ).catch((error: any) => {
                console.log("error msg", error);
                navigate(ORDER_FAILURE_PAGE, { state: { failureMsg: error?.response?.data?.message } });
                showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
            }).finally(() => {
                setIsAPICallRunning(false);
            })
         }
        }
        else{
            doActionPost({
                url: apiList.placeOrder,
                data:
                {
                    "paymentMethod": {
                        "method": STRIPE_PAYMENT_METHOD_CODE,
                        "additional_data": {
                            "save_payment_method": true,
                            "payment_method": selectedId,
                            "cc_save": true
                        },
                         "extension_attributes" : {
                            "paid_amount": getGrandTotalValue()
                        }
                    },
                },
                userType: USER_TYPE.Customer
            })?.then((resp: any) => {
                if(resp){
                    gtmDataConstructor("purchase",STRIPE_PAYMENT_METHOD_CODE,resp?.data);
                    clearCartLocalStorage();
                    dispatch({ type: ACTION_TYPE.set_item_count, payload: { count: 0 } })
                   navigate(ORDER_SUCCESS_PAGE, { state: { orderId: resp?.data?.order_id ,incrementId:resp?.data?.increment_id } })
                }
                else{
                    showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
                }
            }
            ).catch((error: any) => {
                console.log("error msg", error);
                navigate(ORDER_FAILURE_PAGE, { state: { failureMsg: error?.response?.data?.message } });
                //showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
            }).finally(() => {
                setIsAPICallRunning(false);
            })
        }
    

    }

    return (
        <S.Content>
            {isAPICallRunning && <Loading />}
            <div className="customer-delivery">
            <div className="checkout-card-section">
                <div className="card-heading">{SAVED_CARD_HEADING}</div>
                <div className="new-address-section" onClick={() => { navigate(CHECKOUT_PAYMENT_CARD_FORM_PATH,{ state: { isSecondPayment:isSecondPayment ,orderId: location?.state?.orderId , totalDue:location?.state?.totalDue,incrementId:location?.state?.incrementId } }) }}>
                    <div className="add-new-address">{ADD_NEW_CARD_HEADING}</div>
                </div>
            </div>
            <div className="checkout-card-data">
                {savedCardList?.length > 0 && savedCardList.map((item: any, index: number) => {
                    return (
                        <React.Fragment key={item?.id}>
                            <div className="card-payment-section">
                                <div className="card-address-data">
                                    <div className="first-address-card-row">
                                        <div className="address-name">{item?.billing_details?.name}</div>
                                        <div className="address-radio">
                                            <input className="custom-radio" type="radio" id="address-radio-button" value={item?.id} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedId(item?.id)} checked={item?.id === selectedId}></input>
                                        </div>
                                    </div>
                                    <div className="card-number-row">
                                        <div className="card-number-label">{CARD_NO_LABEL}</div>
                                        <div className="card-number-label">:</div>
                                        <div className="card-number"> {item?.card?.last4 ? "**** **** **** " + item?.card?.last4 : ""}</div>
                                    </div>
                                    <div className="card-exp-row">
                                        <div className="card-number-row">
                                            <div className="card-number-label">{EXPIRY_DATE_LABEL}</div>
                                            <div className="card-number-label">:</div>
                                            <div className="card-number"> {item?.card?.exp_month + "/" + item?.card?.exp_year}</div>
                                        </div>
                                        <div className="card-number-row">
                                            <div className="card-number-label">{CVV_LABEL}</div>
                                            <div className="card-number-label">:</div>
                                            <div className="card-number"> {"***"}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </React.Fragment>
                    )

                })}
            </div>
            {savedCardList?.length === 0 && <>{NO_AVAILABLE_CARDS}</>}
            </div>
           <div className="place-order-button">
                <CheckoutButton isDisabled={selectedId === ""} label={PLACE_ORDER_BUTTON_LABEL} checkoutHandler={placeOrder} />
            </div>
        </S.Content>
    );
};

export default CustomerCardListing;

