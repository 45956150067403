import { LOGIN_TERMS_AND_CONDITIONS_TEXT } from "../../../../lib/constant";
import { LOGIN_CONSTANTS } from "../constant";
import { TermsAndCondition } from "../styles/styles";

const LoginTermsAndCondition = () => {
    return (
        <>
            <TermsAndCondition>
                <div className="heading">{LOGIN_CONSTANTS.terms_conditions.en}</div>
                <p className="text-content">{LOGIN_TERMS_AND_CONDITIONS_TEXT}</p>
            </TermsAndCondition>
        </>
    );
}

export default LoginTermsAndCondition;