import { USER_TYPE } from "../lib/constant";

//Add the key in respective array which is not required when switching from one login-type to another.
const customerKeys = ["user-token", "user_quoteId", 'user_quoteId', 'billingAddress', 'shippingAddress', 'selectedShipmentAddressId', 'newCreatedBillingAddress', 'newCreatedAddress', 'deliveryCarrierCode', 'cartItems']
const employeeKeys = ["quoteId", "cartItems", "productSyncToPlp", "id-token", "msadAccessToken", "employee_email", "access-token"];

export const clearPreviousLoginData = (type: "Customer" | "Employee" | "Other") => {
  let itemToRemove = type === USER_TYPE.Customer ? customerKeys : employeeKeys;
  itemToRemove.forEach(key => {
    localStorage.removeItem(key);
  })
}

export function getAPIToken(type: string): string {
  switch (type) {
    case USER_TYPE.Customer:
      return getLocalStorage("user-token");
    case USER_TYPE.Employee:
      return getLocalStorage("access-token");
    default:
      return String(process.env.REACT_APP_API_INTEGRATION_TOKEN);
  }
}

/**
 * Util to set data in local storage
 */
export const setLocalStorage = (key: string, value: string) => {
  return localStorage.setItem(key, value);
};

export const getLocalStorage = (key: string): string => {
  return localStorage.getItem(key) ?? "";
};

export const removeLocalStorage = (key: string) => {
  return localStorage.removeItem(key);
};
