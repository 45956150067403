import {
  INITIAL_PAGE_ROUTE_PATH,
  EMPLOYEE_LOGIN_ROUTE_PATH,
  LOGIN_ROUTE_PATH,
  CHECKOUT_PAYMENT_PATH,
  FURNITURE_CHECKOUT_PATH,
  CHECKOUT_ORDER_CONFIRMATION_PAGE,
  SCAN_ROUTE_PATH,
  LOGIN_PRIVACY_POLICY_ROUTE_PATH,
  LOGIN_TERMS_AND_CONDITION_ROUTE_PATH,
  NOTIFICATION_PERMISSION_PATH,
  CAFETERIA_CASH_REGISTERY,
  ASSISTANCE_PATH,
  ORDER_SUCCESS_PAGE,
  ORDER_FAILURE_PAGE,
  MANAGE_ORDER,
  ORDER_CONFIRMATION_PAGE_ROUTE_PATH,
} from "../routes/routes-constant";

export const APP_HEADING = "Tresori PWA ";
export const SCANNER_LINK = "Scan";
export const LOGIN_TITLE = "Sign In";
export const LOGIN_SUBTITLE =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero.";
export const LOGIN_CONTENT_HEADING = "Login With";
export const GOOGLE_LOGIN_BUTTON_LABEL = "Google";
export const APPLE_LOGIN_BUTTON_LABEL = "Apple";
export const TERMS_AND_CONDITION_CTA = "terms & Conditions";
export const PRIVACY_AND_POLICY_CTA = "Privacy policy";
export const LOGIN_TERMS_AND_POLICY = "By continuing you agree to our";
export const LOGIN_TERMS_AND_CONDITIONS_TEXT = `We respect your privacy and are committed to protecting it through our compliance with this privacy policy (“Policy”). This Policy describes the types of information we may collect from you or that you may provide (“Personal Information”) in the “Tresori” mobile application (“Mobile Application” or “Service”) and any of its related products and services (collectively, “Services”), and our practices for collecting, using, maintaining, protecting, and disclosing that Personal Information. It also describes the choices available to you regarding our use of your Personal Information and how you can access and update it.

This Policy is a legally binding agreement between you (“User”, “you” or “your”) and this Mobile Application developer (“Operator”, “we”, “us” or “our”). If you are entering into this agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this agreement, in which case the terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this agreement, you must not accept this agreement and may not access and use the Mobile Application and Services. By accessing and using the Mobile Application and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Policy. This Policy does not apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage.

Collection of personal information

You can access and use the Mobile Application and Services without telling us who you are or revealing any information by which someone could identify you as a specific, identifiable individual. If, however, you wish to use some of the features offered in the Mobile Application, you may be asked to provide certain Personal Information (for example, your name and e-mail address).

We receive and store any information you knowingly provide to us when you fill any forms in the Mobile Application. When required, this information may include the following:

Account details (such as user name, unique user ID, password, etc)
Contact information (such as email address, phone number, etc)
Basic personal information (such as name, country of residence, etc)
Geolocation data of your device (such as latitude and longitude)
Information about other individuals (such as your family members, friends, etc)

You can choose not to provide us with your Personal Information, but then you may not be able to take advantage of some of the features in the Mobile Application. Users who are uncertain about what information is mandatory are welcome to contact us.

Privacy of children

We do not knowingly collect any Personal Information from children under the age of 18. If you are under the age of 18, please do not submit any Personal Information through the Mobile Application and Services. If you have reason to believe that a child under the age of 18 has provided Personal Information to us through the Mobile Application and Services, please contact us to request that we delete that child’s Personal Information from our Services.

We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce this Policy by instructing their children never to provide Personal Information through the Mobile Application and Services without their permission. We also ask that all parents and legal guardians overseeing the care of children take the necessary precautions to ensure that their children are instructed to never give out Personal Information when online without their permission.

Use and processing of collected information

We act as a data controller and a data processor when handling Personal Information, unless we have entered into a data processing agreement with you in which case you would be the data controller, and we would be the data processor.

Our role may also differ depending on the specific situation involving Personal Information. We act in the capacity of a data controller when we ask you to submit your Personal Information that is necessary to ensure your access and use of the Mobile Application and Services. In such instances, we are a data controller because we determine the purposes and means of the processing of Personal Information.

We act in the capacity of a data processor in situations when you submit Personal Information through the Mobile Application and Services. We do not own, control, or make decisions about the submitted Personal Information, and such Personal Information is processed only in accordance with your instructions. In such instances, the User providing Personal Information acts as a data controller.

In order to make the Mobile Application and Services available to you, or to meet a legal obligation, we may need to collect and use certain Personal Information. If you do not provide the information that we request, we may not be able to provide you with the requested products or services. Any of the information we collect from you may be used for the following purposes:

Create and manage user accounts
Send administrative information
Improve user experience
Protect from abuse and malicious users
Respond to legal requests and prevent harm
Run and operate the Mobile Application and Services

Processing your Personal Information depends on how you interact with the Mobile Application and Services, where you are located in the world and if one of the following applies: (i) you have given your consent for one or more specific purposes; (ii) provision of information is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof; (iii) processing is necessary for compliance with a legal obligation to which you are subject; (iv) processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in us; (v) processing is necessary for the purposes of the legitimate interests pursued by us or by a third party. We may also combine or aggregate some of your Personal Information in order to better serve you and to improve and update our Mobile Application and Services.

 Note that under some legislations we may be allowed to process information until you object to such processing by opting out, without having to rely on consent or any other of the legal bases. In any case, we will be happy to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a contract.

Disclosure of information

 Depending on the requested Services or as necessary to complete any transaction or provide any Service you have requested, we may share your information with our affiliates, contracted companies, and service providers (collectively, “Service Providers”) we rely upon to assist in the operation of the Mobile Application and Services available to you and whose privacy policies are consistent with ours or who agree to abide by our policies with respect to Personal Information. We will not share any personally identifiable information with third parties and will not share any information with unaffiliated third parties.

Service Providers are not authorized to use or disclose your information except as necessary to perform services on our behalf or comply with legal requirements. Service Providers are given the information they need only in order to perform their designated functions, and we do not authorize them to use or disclose any of the provided information for their own marketing or other purposes.

Retention of information

We will retain and use your Personal Information for the period necessary to comply with our legal obligations, to enforce our agreements, resolve disputes, and unless a longer retention period is required or permitted by law.

We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally. Once the retention period expires, Personal Information shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification, and the right to data portability cannot be enforced after the expiration of the retention period.

Social media features

Our Mobile Application and Services may include social media features, such as the Facebook and Twitter buttons, Share This buttons, etc (collectively, “Social Media Features”). These Social Media Features may collect your IP address, what page you are visiting on our Mobile Application and Services, and may set a cookie to enable Social Media Features to function properly. Social Media Features are hosted either by their respective providers or directly on our Mobile Application and Services. Your interactions with these Social Media Features are governed by the privacy policy of their respective providers.

Links to other resources

The Mobile Application and Services contain links to other resources that are not owned or controlled by us. Please be aware that we are not responsible for the privacy practices of such other resources or third parties. We encourage you to be aware when you leave the Mobile Application and Services and to read the privacy statements of each and every resource that may collect Personal Information.

Information security

We secure information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. We maintain reasonable administrative, technical, and physical safeguards in an effort to protect against unauthorized access, use, modification, and disclosure of Personal Information in our control and custody. However, no data transmission over the Internet or wireless network can be guaranteed.

Therefore, while we strive to protect your Personal Information, you acknowledge that (i) there are security and privacy limitations of the Internet which are beyond our control; (ii) the security, integrity, and privacy of any and all information and data exchanged between you and the Mobile Application and Services cannot be guaranteed; and (iii) any such information and data may be viewed or tampered with in transit by a third party, despite the best efforts.

Data breach

In the event we become aware that the security of the Mobile Application and Services has been compromised or Users’ Personal Information has been disclosed to unrelated third parties as a result of external activity, including, but not limited to, security attacks or fraud, we reserve the right to take reasonably appropriate measures, including, but not limited to, investigation and reporting, as well as notification to and cooperation with law enforcement authorities. In the event of a data breach, we will make reasonable efforts to notify affected individuals if we believe that there is a reasonable risk of harm to the User as a result of the breach or if notice is otherwise required by law. When we do, we will post a notice in the Mobile Application, send you an email.

Changes and amendments

We reserve the right to modify this Policy or its terms related to the Mobile Application and Services at any time at our discretion. When we do, we will post a notification in the Mobile Application, send you an email to notify you. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided.

An updated version of this Policy will be effective immediately upon the posting of the revised Policy unless otherwise specified. Your continued use of the Mobile Application and Services after the effective date of the revised Policy (or such other act specified at that time) will constitute your consent to those changes. However, we will not, without your consent, use your Personal Information in a manner materially different than what was stated at the time your Personal Information was collected.

Acceptance of this policy

You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and using the Mobile Application and Services and submitting your information you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use the Mobile Application and Services. This privacy policy was created with the help of WebsitePolicies.com

Contacting us

If you have any questions, concerns, or complaints regarding this Policy, the information we hold about you, or if you wish to exercise your rights, we encourage you to contact us using the details below:

info@tresori.com

We will attempt to resolve complaints and disputes and make every reasonable effort to honor your wish to exercise your rights as quickly as possible and in any event, within the timescales provided by applicable data protection laws.

This document was last updated on August 28, 2022`;
export const LOGIN_USER = "customers";
export const LOGIN_EMPLOYEE = "employee/authentication/";
export const LOGOUT_USER_CONSTANT_URL = `https://${process.env.REACT_APP_POOL_DOMAIN}/logout?client_id=${process.env.REACT_APP_USER_POOL_CLIENT_ID}`;
export const EMPLOYEE_TYPE = "employee";
export const CUSTOMER_TYPE = "customer";
export const CUSTOMER_DETAILS = "customers/me";
export const ADD_OR_UPDATE_CUSTOMER_ADDRESS = "customers/address";
export const DELETE_CUSTOMER_ADDRESS = "customer-address";
export const COUNTRY_DETAILS = "directory/countries";

//Employee cart constants
export const EAT_IN_LABEL = "Eat In";
export const TAKE_AWAY_LABEL = "Take Away";
export const YOUR_ORDER_LABEL = "Order Summary";
export const COUPON_LABEL = "Enter Offer";
export const APPLY_LABEL = "Apply";
export const REMOVE_LABEL = "Remove";
export const SUBTOTAL_LABEL = "Sub Total";
export const TAX_AND_FEE_LABEL = "Tax & Fee";
export const TOTAL_LABEL = "Total";
export const CART_BUTTON_LABEL = "Proceed to Payment";
export const TABLE_NOTES_PLACEHOLDER = "Enter table number";
export const PLEASE_FILL_TABLE_NO_BEFORE_PROCEEDING ="Please enter table no before proceeding!";
export const PLEASE_ENTER_RETURN_REFERENCE_NUMBER= "Please enter payment return reference number";

//Employee checkout constants
export const PAYMENT_MODE_LABEL = "Payment Mode";
export const BANKTRANSFER_CODE = "purchaseorder";
export const FREE_CODE = "free";
export const PAYMENT_REFERENCE_CODE_PLACEHOLDER =
  "Enter payment reference number";
export const ORDER_RECEIPT_LABEL = "Receive order receipt";
export const ORDER_RECEIPT_INPUT_PLACEHOLDER = "Enter your email";
export const COMPLETE_ORDER_BUTTON_LABEL = "Complete Order";
export const TERMS_AND_CONDITIONS_TEXT =
  "I agree to terms and conditions of Tresori and willing to make this payment.";
export const ORDER_CONFIRMATION_TEXT = " is Completed.";
export const ORDER_CONFIRMATION_INITIALS = "Order #";
export const PLEASE_ENTER_PAYMENT_REF_NUMBER_ERROR_MSG =
  "Please enter payment reference number.";
export const PLEASE_ENTER_CUSTOMER_EMAIL = "Please enter customer email.";
export const PLEASE_ENTER_VALID_EMAIL = "Please enter a valid email.";
//Admin Cart apis
export const FETCH_QUOTE = "carts/";
export const QUOTE_ITEMS = "items/";
export const CART_TOTALS = "totals";
export const COUPONS = "coupons/";
export const GET_PAYMENT_METHODS = "/payment-methods";
export const DELIVERY_INFO = "delivery-info";
export const PLACE_ORDER = "order";

export const CUSTOMERS_PENDING_ORDER_LIST = "Customer's pending orders";
export const NO_PENDING_ORDER_FOUND = "No Pending Order Found";
export const AMOUNT_LABEL = "Paid Amount";
export const APPROVED_BUTTON_LABEL = "Approve";
export const REJECTED_BUTTON_LABEL = "Decline";
export const SEARCH_BY_CUSTOMER_NAME_OR_TRANSACTION_ID =
  "Search by customer name or tranaction ref no.";
export const PAYMENT_METHOD = "Payment Method";
export const ORDER_AMOUNT = "Order Amount";
//Customer cart apis
export const REMOVE_ALL_ITEMS_FROM_CART = "carts/mine/removeAll";

//Need Help/Assistance APIs
export const ASSISTANCE_ACTIVE_REQUEST = "assistance/inquiries";
export const ASSISTANCE_CUSTOMER_CART = "assistance/customer-cart";
export const ASSISTANCE_STORE_USERS = "assistance/store-users";

//Customer-Checkout
export const ESTIMATE_SHIPPING = "carts/mine/estimate-shipping-methods";
export const SET_SHIPMENT_INFORMATION = "carts/mine/shipping-information";
export const GET_AVAILABLE_PAYMENT_METHODS = "carts/mine/payment-methods";
export const CUSTOMER_PLACE_ORDER = "carts/mine/payment-information";
export const CUSTOMER_GIFT_CART = "carts/mine/gift-cart/";
export const GET_CUSTOMER_SAVED_CARDS = "cards";
export const PLACE_OFFLINE_ORDERS = "carts/mine/order";
//Employee -customer's pending approval list
export const GET_PENDING_PAYMENT = "order-payments";
export const GET_PANDING_PAYMENT_METHOD_LIST = "order/payment-methods";

//Training Resources API
export const TRAINING_RESOURCES = "trainingmedia";
export const TRAINING_RESOURCES_CATEGORIES = "trainingmedia/categories";

//Notification Constants
export const AUTHENTICATION_SUCCESS = "Authentication successful";
export const AUTHENTICATION_FAILURE = "Authentication failed";
export const AUTHENTICATION_ERROR = "Error during authentication";
export const LOGIN_FAILED = "Login failed";
export const SOMETHING_WENT_WRONG = "Something went wrong!";
export const COUPON_SUCCESSFULLY_APPLIED_MSG =
  "Coupon has been successfully applied!.";
export const COUPON_SUCCESSFULLY_REMOVED_MSG =
  "Coupon has been successfully removed!.";
export const INFORMATION_SUCCESSFULLY_SAVED_MSG =
  "Cart updated successfully!.";
export const PRODUCT_REMOVED_SUCCESSFULLY =
  "Product has been successfully removed from cart!.";
export const PRODUCT_QTY_UPDATED_MSG = "Product quantity has been updated!.";
export const INVALID_COUPON_MSG = "Coupon is not valid!.";

//Customer checkout constants
export const CASH_ON_DELIVERY_CODE = "cashondelivery";
export const CASH_ON_DELIVERY_METHOD_LABEL = "Cash Payment";
export const CASH_ON_DELIVERY_METHOD_SUB_HEADING =
  "Please pay cash at the counter.";
export const CHECKOUT_FIRST_STEP_HEADING = "Checkout";
export const SHIPPING_ADDRESS_HEADING = "Delivery Address";
export const DELIVERY_OPTION_HEADING = "Delivery Options";
export const BILLING_ADDRESS_HEADING = "Billing Address";
export const DELIVERY_ADDRESS_HEADING = "Delivery Address";
export const SELECTED_BILLING_ADDRESS_HEADING = "Billing Address";
export const SELECTED_SHIPPING_ADDRESS_HEADING = "Delivery Address";
export const ADD_NEW_ADDRESS_HEADING = "New Address";
export const NO_ADDRESS_FOUND = "No Address Found ";
export const CONTINUE_BUTTON_LABEL = "Continue";
export const DELIVERY_OPTION_ADDRESS_HEADING = "Delivery Options";
export const DELIVERY_OPTION_SUB_HEADING = "Delivery Options Available";
export const PROCEED_TO_CHECKOUT_BUTTON_LABEL = "Proceed to checkout";
export const SAVE_TO_ADDRESS_BOOK = "Save to address book";
export const NEW_ADDRESS_FROM_SHIPPING = "New Address From Delivery";
export const NEW_ADDRESS_FROM_BILLING = "New Address From Billing";
export const CHECKOUT_LAST_STEP_HEADING = "Payment";
export const SELECTED_SHIPMENT_METHOD = "Delivery Method";
export const SHIPPING_FEE_LABEL = "Delivery Fee";
export const ESTIMATED_TAX_LABEL = "Tax";
export const PLACE_ORDER_BUTTON_LABEL = "Place Order";
export const THANK_YOU_TEXT = "Thank You!";
export const ORDER_FAILURE_TEXT = "Purchase Failure";
export const FINAL_ORDER_MESSAGE_INITIAL_TEXT = "Your Order ";
export const FINAL_PAYMENT_MESSAGE_INITIAL_TEXT = "Your Payment For Order ";
export const PAYMENT_APPROVAL_PENDING = "Your Payment Approval Is Pending";
export const FINAL_ORDER_MESSAGE_LAST_TEXT = " is Completed.";
export const ORDER_DELIVERY_TRACKING_INITIAL_TEXT =
  "Please Check the Delivery Status at ";
export const ORDER_DELIVERY_TRACKING_LAST_TEXT = " Order Tracking Pages.";
export const CONTINUE_SHOPPING_LABEL = "Continue Shopping";
export const ORDER_FAILURE_SUB_HEADING =
  "Bank Balance issue, please check your bank balance in order to continue.";
export const TRY_AGAIN_LABEL = "Try Again";
export const PAYMENT_METHOD_SUB_HEADING = "Payment Methods";
export const SAVED_CARD_HEADING = "Saved Cards";
export const ADD_NEW_CARD_HEADING = "New Card";
export const GET_PUBLISHABLE_KEY = "publishable-key";
export const ENTER_CARD_DETAILS = "Please enter card details";
export const PAY_BUTTON_LABEL = "Pay";
export const SAVED_CARD_LABEL = "Place Order";
export const GIFT_CART_LABEL =
  "This order is a gift (Prices will not be shown on the delivery note)";
export const IS_THAT_A_GIFT = "Is this a gift?";
export const BACK_TO_CART_LABEL = "Back to cart";
export const BACK_TO_MENU_LABEL = "Back to Menu";
export const IS_BILLING_ADDRESS_SAME_AS_SHIPPING =
  "Use same as delivery address";
export const CARD_NO_LABEL = "Card No";
export const NO_AVAILABLE_CARDS = "No card exists.Please add card details ";
export const EXPIRY_DATE_LABEL = "Exp. Date";
export const CVV_LABEL = "CVV";
export const CARD_NAME_PLACEHOLDER = "Enter card holder name";
export const OFFER_LABEL = "Offer";
export const ADVANCED_AMOUNT_LABEL = "Advanced Payment";
export const BALANCED_AMOUNT_LABEL = "Balance Payment";
export const STRIPE_PAYMENT_METHOD_CODE = "stripe_payments";
export const PURCHASE_ORDER_CODE = "purchaseorder";
export const CHEQUE_PURCHASE_ORDER_CODE = "chequepurchaseorder";
export const BANK_TRANSFER_PAYMENT_METHOD_LABEL = "Bank Transfer Payment";
export const BANK_TRANSFER_LABEL = "Bank Transfer";
export const CHECQUE_LABEL = "Cheque";
export const TRANSACTION_REFERENCE_NUMBER = "Fill in Transaction Number";
export const TRANSACTION_REFERENCE_NUMBER_PLACEHOLDER = "Add Number";
export const CHEQUE_NUMBER = "Enter Number";
export const CHEQUE_NUMBER_PLACEHOLDER = "Add cheque number";
export const BANK_NAME_PLACEHOLDER = "Add bank name";
export const SAVED_CARD_CHECKBOX_LABEL = "Do you want to save card";
export const ORDER_DETAILS_PAGE = "Go To Order Detail";
export const SELECT_BUTTON_LABEL = "Select";
export const SELECTED_BUTTON_LABEL = "Selected";
export const ENTER_CUSTOMER_NAME = "Please enter customer name first";
export const NO_INVOICE_FOUND =
  "Invoice is not ready yet . It will be available once generated.";
export const MY_ORDER_DETAIL_HEADING = "Order Details";

export const PLEASE_CHECK_TERMS_AND_CONDITIONS =
  "Please check terms & conditions.";
export const PAYMENT_STATUS_UPDATED_SUCCESSFULLY =
  "Payment status updated successfully";
export const OUT_OF_STOCK = "Out Of Stock";
export const YOUR_CART_IS_LOCKED = "Your Cart is Locked";
export const WE_WILL_CALL_YOU_ONCE_PAYMENT_DONE =
  "We'll call you once payment is confirmed*";
export const NOTIFICATION_HEADING_LABEL = "Notifications";
export const TOTAL_COUNT_LABEL = "Total";
export const SEEN_COUNT_LABEL = "Seen";
export const UNSEEN_COUNT_LABEL = "Unseen";
export const DATA_UPDATED_SUCCESSFULLY = "Data updated successfully";
export const SHOW_MORE_LABEL = "Show more";
export const SELECT_ANOTHER_ADDRESS = "Select another delivery address";
export const SELECT_ANOTHER_BILLING_ADDRESS = "Select another billing address";
export const ADD_NEW_SHIPPING_ADDRESS = "Add a new delivery address";
export const ADD_NEW_BILLING_ADDRESS = "Add a new billing address";
export const SAVE_BUTTON_LABEL = "Save";
export const CONITNUE_BUTTON_LABEL = "Continue";
export const SELECT_SHIPMENT_ADDRESS = "Please select delivery address";
export const SELECT_BILLING_ADDRESS = "Please select billing address";
export const SELECT_SHIPMENT_METHOD = "Please select any delivery method";
export const ORDER_TOTAL_HEADING = "Order Total";
export const PLACE_ORDER_SUBHEADING =
  "Place Order and an assistant will be with you shortly";
export const BANK_NAME = "ICICI Bank";
export const ACCOUNT_NUMBER = "12356789";
export const ACCOUNT_NAME = "Tresori Trading";
export const IBAN_NUMBER = "00000000000";
export const BANK_NAME_TITLE = "Bank Name";
export const ACCOUNT_NAME_TITLE = "Account Name";
export const ACCOUNT_NUMBER_TITLE = "Account Number";
export const IBAN_TITLE = "Iban";
export const OPEN_CASH_REGISTERY = "Open Cash Registry";
export const ADD_AMOUNT_HEADING = "Add Amount";
export const DETAILS_HEADING ="Details";
export const DETAILS_SUB_HEADING = "Add the number of each bill";
export const RECEIVED_CASH_BUTTON_LABEL = "Received Open Cash";
export const CLOSE_CASH_REGISTERY = "Close Cash Registry";
export const SUMMARY_HEADING = "Summary";
export const DATE_LABEL = "Date:";
export const OPENING_AMOUNT_LABEL = "Opening Amount:";
export const CASH_AMOUNT_LABEL = "Total Cash Sales:";
export const TOTAL_CREDIT_SALES_LABEL = "Total Credit Card Sales:";
export const TOTAL_SALES_OF_DAY = "Total Sales Of The Day:";
export const CLOSING_CASH_LABEL = "Closing Cash";
export const CONFIRM_CLOSE_CASH_LABEL = "Confirm Closing Cash";
export const ARE_YOU_SURE_WANT_TO_CLOSE_THE_DAY =
  "Are you sure you want to close your day";
export const ARE_YOU_SURE_WANT_TO_OPEN_THE_DAY =
  "Are you sure you want to open your day";
export const NOTIFICATION_PERMISSION_HEADING = "Get Notified, Stay informed";
export const NOTIFICATION_PERMISSION_SUBHEADING =
  "Take control of your app experience. Allow notifications, to receive timely updates and exclusive offers.";
export const YES_NOTIFY_ME_LABEL = "Yes, notify me";
export const MAY_BE_LATER_TEXT = "Maybe later";
export const APP_NOTIFICATION_HEADING = "App Notifications";
export const OPEN_REQUEST_HEADING = "Open Notifications Settings";
export const APP_NOTIFICATION_SUB_HEADING =
  "Never miss a message, click the link above to easily enable notifications from us.";
//Customer checkout progress bar
export const progressBar = [
  { id: 1, title: "Personal Info", path: FURNITURE_CHECKOUT_PATH },
  { id: 2, title: "Payment", path: CHECKOUT_PAYMENT_PATH },
  { id: 3, title: "Confirmation", path: CHECKOUT_ORDER_CONFIRMATION_PAGE },
];

export const BACKARROW_NOT_ALLOWED_PATHS=[
  SCAN_ROUTE_PATH,
  EMPLOYEE_LOGIN_ROUTE_PATH,
  CAFETERIA_CASH_REGISTERY,
  ASSISTANCE_PATH,
  ORDER_SUCCESS_PAGE,
  ORDER_FAILURE_PAGE,
  MANAGE_ORDER,
  LOGIN_PRIVACY_POLICY_ROUTE_PATH,
  NOTIFICATION_PERMISSION_PATH,
  ORDER_CONFIRMATION_PAGE_ROUTE_PATH
]

export const currencyDenomination= ['1000','500','200','100','50','10','Dirham','Fils']

//Alert Statuses
export const ALERT_STATUS = {
  error: "error",
  success: "success",
  info: "info",
} as const;

export const QUNATITY_ERROR_MSG =
  "There isn't a quantity set to the cart for this product.";
export const ADDTOWISHLIST_SUCCESS_MSG =
  "Product added successfully to the wish list";
export const ADDTOWISHLIST_ERROR_MSG =
  "Error while adding product to the wish list";
export const MyAccount = "my-account";
//Hooks Error
export const USE_NOTIFICATION_HOOK_ERROR =
  "useNotification must be used within a NotificationProvider";

export const NO_NOTIFICATION_EXISTS = "No notification exists";

const ALL_PRODUCT =
  "products?searchCriteria[filter_groups][0][filters][1][field]=status&searchCriteria[filter_groups][0][filters][1][value]=1&searchCriteria[filter_groups][0][filters][0][field]=category_id&searchCriteria[filter_groups][0][filters][0][value]=";
const CAFETERIA_PLP_PRODUCT =
  "products/list?searchCriteria[filter_groups][0][filters][1][field]=status&searchCriteria[filter_groups][0][filters][1][value]=1&searchCriteria[filter_groups][0][filters][0][field]=category_id&searchCriteria[filter_groups][0][filters][0][value]=";
const CONSTANT_API = "tresori/getconstant";
const wishlist = "wishlist";
const orderDetails = "customer-orders/";
const orderByEmployeeDetails = "orders/";
const manageOrderDetails = "employee-order/";
const manageOrderList = "employee-orders";
// const manageOrderList = "employee-orders?searchCriteria[filter_groups][1][filters][0][field]=created_at&searchCriteria[filter_groups][1][filters][0][condition_type]=from&searchCriteria[filter_groups][1][filters][0][value]=2024-09-05%00:00:00";
const manageOrderCount = "order-count/is_open/0";
const manageOrderStatus = "order/order-status";

export const PAYMENT_HAS_BEEN_UPDATED_SUCCESSFULLY =
  "Payment has been updated successfully";
export const PLEASE_ENTER_PAYMENT_REF_NUMBER =
  "Please enter payment reference number ";
export const CURRENCY_DENOMINATION_MISMATCH_ERROR="Your input cash doesn't match with the currency denomination";
export const PRINT_NOTE="Order receipt will be available on order detail page for print";
export const CLICK_AND_COLLECT_CODE = "clickandcollectshipping";
export const CLICK_AND_COLLECT_METHOD_NAME ="Click and Collect";
export const REASON_FOR_CANCELLATION="Comment";
export const REASON_FOR_CANCELLATION_PLACEHOLDER="Please provide reason for cancellation ";
export const REF_NO_FOR_CANCELLATION="Transaction No ";
export const REF_NO_CANCELLATION_PLACEHOLDER="Please provide transaction ref no ";

//Welcome Screen Messages
export const LANGUAGES_IN_DIFFERENT_LANGUAGES = [
  "Welcome",
  "مرحباً",
  "Bienvenue",
  "Bienvenidos",
];

export const LOGOUT_MESSAGES = {
  error_messages: {
    no_user_found: {
      en: "No current user",
    },
    no_signed_in_user_found: {
      en: "No signed in user found.",
    },
    session_not_found: {
      en: "Account session not found.",
    },
    email_not_found: {
      en: "Email for account not found.",
    },
    user_not_authenticated: {
      en: "The user is not authenticated",
    },
    logout_failed: {
      en: "Logout failed due to an internal error.",
    },
    authentication_needed: {
      en: "User needs to be authenticated to call this API",
    },
    unknown_error: {
      en: "Unknown error",
    },
  },
};
const apiList = {
  userLogin: process.env.REACT_APP_API_URL + `${LOGIN_USER}`,
  employeeLogin: process.env.REACT_APP_API_URL + `${LOGIN_EMPLOYEE}`,
  getAllProducts: process.env.REACT_APP_API_URL + `${ALL_PRODUCT}`,
  getAllCafeteriaProducts:
    process.env.REACT_APP_API_URL + `${CAFETERIA_PLP_PRODUCT}`,
  //Admin cart
  getCartDetails: process.env.REACT_APP_API_URL + `${FETCH_QUOTE}`,

  //Customer Cart
  removeAllItems:
    process.env.REACT_APP_API_URL + `${REMOVE_ALL_ITEMS_FROM_CART}`,

  staticConstantAPI: process.env.REACT_APP_API_URL + `${CONSTANT_API}`,
  //customer-checkout
  estimateShippingAPI: process.env.REACT_APP_API_URL + `${ESTIMATE_SHIPPING}`,
  setShippingInformation:
    process.env.REACT_APP_API_URL + `${SET_SHIPMENT_INFORMATION}`,
  getAvailablePaymentMethods:
    process.env.REACT_APP_API_URL + `${GET_AVAILABLE_PAYMENT_METHODS}`,
  getPublishableKey: process.env.REACT_APP_API_URL + `${GET_PUBLISHABLE_KEY}`,
  placeOrder: process.env.REACT_APP_API_URL + `${CUSTOMER_PLACE_ORDER}`,
  setGiftCart: process.env.REACT_APP_API_URL + `${CUSTOMER_GIFT_CART}`,
  getCustomerSavedCart:
    process.env.REACT_APP_API_URL + `${GET_CUSTOMER_SAVED_CARDS}`,
  placeOfflineOrders: process.env.REACT_APP_API_URL + `${PLACE_OFFLINE_ORDERS}`,

  //My-profile and preferences
  customerDetails: process.env.REACT_APP_API_URL + `${CUSTOMER_DETAILS}`,
  getCountries: process.env.REACT_APP_API_URL + `${COUNTRY_DETAILS}`,
  customerAddress:
    process.env.REACT_APP_API_URL + `${ADD_OR_UPDATE_CUSTOMER_ADDRESS}`,
  deleteCustomerAddress:
    process.env.REACT_APP_API_URL + `${DELETE_CUSTOMER_ADDRESS}`,

  //Need Help/Assistance
  getActiveRequests:
    process.env.REACT_APP_API_URL +
    `${ASSISTANCE_ACTIVE_REQUEST}?searchCriteria[sortOrders][0][field]=id&searchCriteria[sortOrders][0][direction]=DESC`,
  getAssistanceCustomerCart:
    process.env.REACT_APP_API_URL + `${ASSISTANCE_CUSTOMER_CART}`,

  //wishlist
  wishlist: process.env.REACT_APP_API_URL + `${wishlist}`,
  quoteIdURL: `${process.env.REACT_APP_API_URL}carts/mine`,

  //orders
  getOrders: (orderId: string, userEmail: string) =>
    process.env.REACT_APP_API_URL +
    orderDetails +
    (orderId ||
      `?searchCriteria[filterGroups][0][filters][0][field]=customer_email&searchCriteria[filterGroups][0][filters][0][value]=${userEmail}&searchCriteria[filterGroups][0][filters][0][conditionType]=eq?&searchCriteria=all&searchCriteria[sortOrders][0][field]=entity_id&searchCriteria[sortOrders][0][direction]=DESC`),
  cancelOrReturnOrder: (orderID: string, toCancel: boolean) =>
    process.env.REACT_APP_API_URL +
    `order/mine/${orderID}/${toCancel ? "cancel" : "return"}`,
  getOrderBysEmployee: (customer_id: String, order_id: string) =>
    process.env.REACT_APP_API_URL +
    orderByEmployeeDetails +
    `?searchCriteria[filterGroups][0][filters][0][field]=customer_id&searchCriteria[filterGroups][0][filters][0][value]=${customer_id}&searchCriteria[filterGroups][0][filters][0][conditionType]=eq${
      order_id
        ? `&searchCriteria[filterGroups][1][filters][0][field]=entity_id&searchCriteria[filterGroups][1][filters][0][value]=${order_id}&searchCriteria[filterGroups][1][filters][0][conditionType]=eq`
        : ""
    }&searchCriteria[sortOrders][0][field]=entity_id&searchCriteria[sortOrders][0][direction]=DESC`,

  //manageOrder
  manageOrderDetails: process.env.REACT_APP_API_URL + manageOrderDetails,
  manageOrderList: process.env.REACT_APP_API_URL + manageOrderList,
  getEmployeeAllOrders:process.env.REACT_APP_API_URL+"customer-orders/?searchCriteria[filterGroups][0][filters][0][conditionType]=eq?&searchCriteria=all&searchCriteria[sortOrders][0][field]=entity_id&searchCriteria[sortOrders][0][direction]=DESC",
  manageOrderStatus: process.env.REACT_APP_API_URL + manageOrderStatus,
  manageOrderCount: process.env.REACT_APP_API_URL + manageOrderCount,

  //assistance
  getEnquiries: `${process.env.REACT_APP_API_URL}assistance/customer-inquiries`,
  getEnquiryById: (id: string | number) =>
    `${process.env.REACT_APP_API_URL}assistance/inquiry/${id} `,
  createEquiry: `${process.env.REACT_APP_API_URL}assistance/create-inquiry`,
  getStoreUsers: `${process.env.REACT_APP_API_URL}assistance/store-users`,

  //Training Resources
  getTrainingResources: `${process.env.REACT_APP_API_URL}` + TRAINING_RESOURCES,
  getTrainingResourcesCategories:
    `${process.env.REACT_APP_API_URL}` + TRAINING_RESOURCES_CATEGORIES,
  //get pending order list
  getPendingOrderRequest: process.env.REACT_APP_API_URL + GET_PENDING_PAYMENT,
  approvePendingPayment: (id: number, status: number) =>
    `${process.env.REACT_APP_API_URL}update-payment/${id}/status/${status} `,
  getPendingPaymentMethodList:
    process.env.REACT_APP_API_URL + GET_PANDING_PAYMENT_METHOD_LIST,
  setPendingOrder: (orderId: number) =>
    `${process.env.REACT_APP_API_URL}order/payment/${orderId}`,
  getInvoiceDetails: (orderId: number) =>
    `${process.env.REACT_APP_API_URL}invoices/orderId/${orderId}`,
  getInvoiceDetailsForEmployees:(orderId: number) =>
    `${process.env.REACT_APP_API_URL}invoices?searchCriteria[filter_groups][0][filters][0][field]=order_id&searchCriteria[filter_groups][0][filters][0][value]=${orderId}`,
  getShipmentDetails: (orderId: number) =>
    `${process.env.REACT_APP_API_URL}shipments/orderId/${orderId}`,
  getShipmentDetailsForEmployees:(orderId: number) =>
    `${process.env.REACT_APP_API_URL}shipments?searchCriteria[filter_groups][0][filters][0][field]=order_id&searchCriteria[filter_groups][0][filters][0][value]=${orderId}`,
  getCurrentRegisteryStatus: (emailId: string) =>
    `${process.env.REACT_APP_API_URL}employee/shift/status/${emailId}`,
  storeUsers: `${process.env.REACT_APP_API_URL}assistance/store-users`,
  getEnquiriesByEmployeeEmail: (email: string) =>
    `${process.env.REACT_APP_API_URL}assistance/inquiries?searchCriteria[filterGroups][0][filters][1][field]=current_handler_email&searchCriteria[filterGroups][0][filters][1][value]=${email}&searchCriteria[filterGroups][0][filters][1][conditionType]=eq`,
  getCustomerCart: (enquiryID: string) =>
    `${process.env.REACT_APP_API_URL}assistance/customer-cart/${enquiryID}`,
  setPaymentInformation: (paymentEntityId: number, paymentRefNum: string) =>
    `${process.env.REACT_APP_API_URL}order/update-payment/${paymentEntityId}/reference_number/${paymentRefNum}`,
  startDayCash: `${process.env.REACT_APP_API_URL}employee/shift/start`,
  closeDayCash: `${process.env.REACT_APP_API_URL}employee/shift/end`,
  getShiftReport: (employeeId: number) =>
    `${process.env.REACT_APP_API_URL}employee/shift/report/${employeeId}`,
  //notification-action
  setNotificationAction: (notificicationUrl: string) =>
    `${process.env.REACT_APP_API_URL + notificicationUrl}`,
  getcartDetails: `${process.env.REACT_APP_API_URL}carts/mine`,
};

export const USER_TYPE = {
  Customer: "Customer",
  Employee: "Employee",
  Other: "Other",
};

//MS-AD Constants
export const MSAD_BASE_URL = "https://login.microsoftonline.com/";

export const RESTRICTED_PATHS = [
  INITIAL_PAGE_ROUTE_PATH,
  EMPLOYEE_LOGIN_ROUTE_PATH,
  LOGIN_ROUTE_PATH,
  NOTIFICATION_PERMISSION_PATH,
];

export const ALLOWED_PATHS = [
  SCAN_ROUTE_PATH,
  LOGIN_PRIVACY_POLICY_ROUTE_PATH,
  LOGIN_TERMS_AND_CONDITION_ROUTE_PATH,
];

//SCANDIT CONFIGURATION CONSTANTS DATA
export const SCANDIT_CONFIG = {
  license_key: `${process.env.REACT_APP_SCANDIT_LICENSE_KEY}`,
  library_location: `${process.env.REACT_APP_SCANDIT_LIBRARY_LOCATION}`,
};

// axios http constants
export const SESSION_EXPIRED = {
  en: "Your session has expired. Please log in again.",
};

export default apiList;

export const orderStatusType: any = {
  pending: "pending",
  preparing: "preparing",
  "Ready for Delivery": "ready_for_delivery",
  Delivered: "complete",
};
export const EMPLOYEES = {
  STORE_MANAGER: "Store Manager",
  CAFETERIA_MANAGER: ["Barista", "Senior Barista", "Junior Barista"],
  INTERIOR_DESIGNER: "Interior Designer",
  ADMINISTRATOR: "Administrator",
  SENIOR_PLANNER: "Senior Planner",
  DESIGN_EXPERT: "Design Expert",
};

export const EMPLOYEES_ROLES = {
  MANAGER: ["Store Manager", "Barista"],
  DESIGNER: ["Interior Designer"],
  REPRESENTATIVE: ["Administrators", "Senior Planner", "Design Expert"],
};

export const ALLOWED_EMPLOYEE_TO_SEE_PROJECT_SCREEN_CTA = [
  EMPLOYEES.STORE_MANAGER,
  EMPLOYEES.ADMINISTRATOR,
  EMPLOYEES.DESIGN_EXPERT,
  EMPLOYEES.INTERIOR_DESIGNER,
  EMPLOYEES.SENIOR_PLANNER,
]
