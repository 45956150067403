import React, { useEffect, useState } from 'react';
import InputField from '../../../../molecules/input/InputField';
import * as S from './styles/styles';
import { doActionPost, doActionPut } from '../../../../../helpers/httpRequest';
import apiList, { ALERT_STATUS, IS_BILLING_ADDRESS_SAME_AS_SHIPPING, NEW_ADDRESS_FROM_BILLING, NEW_ADDRESS_FROM_SHIPPING, SAVE_TO_ADDRESS_BOOK, USER_TYPE } from '../../../../../lib/constant';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useNotification } from '../../../../../hooks/useNotification';
import { AxiosError } from 'axios';
import { ValidationErrors, handleValidation, isValidForm, validateForm, validateStreet } from './AddressFormUtility';
import { Address, Country, CountryRegion, CustomerDetails } from '../types/types';
import { Loading } from '../../../../atoms/loading/Loading';
import { removeLocalStorage, setLocalStorage } from '../../../../../helpers/localStorageUtil';
import { FURNITURE_CHECKOUT_PATH, LOGIN_ROUTE_PATH, MY_ACCOUNT_BILLING_ADDRESS_LIST_PAGE_ROUTE_PATH, MY_ACCOUNT_SHIPPING_ADDRESS_LIST_PAGE_ROUTE_PATH } from '../../../../../routes/routes-constant';
import { MY_ACCOUNT_MY_ADDRESSES_LIST_PAGE_ROUTE_PATH } from '../../../../../routes/routes-constant';
import { ADDRESS_FORM_STRINGS, ADDRESS_FORM_UI_STRINGS } from './constants';
import { fetchCountries } from '../helpers/dataProvider';
import { INPUT_FIELDS_PLACEHOLDERS, INPUT_REQUIRED_FIELDS_LABELS, VALIDATION_CONSTANTS } from '../constants';
import { addNewAddress_gtm, editAddress_gtm } from '../../../../../helpers/GTM/profileSectionGTM';
import { manageIndexedDB } from '../../../../../helpers/indexedDB';

const AddressFormNew: React.FC = () => {
    const [formData, setFormData] = useState<Address>({
        id: undefined,
        customer_id: undefined,
        street: [''],
        city: '',
        region: {
            region_code: '',
            region: '',
            region_id: undefined
        },
        postcode: '',
        country_id: '',
        region_id: undefined,
        telephone: '',
        firstname: '',
        lastname: '',
        default_shipping: false,
        default_billing: false,
        isBillingSameAsShipping: false
    });

    const [errors, setErrors] = useState<ValidationErrors>({});

    const [addresses, setAddresses] = useState<Address[]>([]);
    const [countries, setCountries] = useState<Country[]>([]);
    const [regions, setRegions] = useState<CountryRegion[]>([]);
    const [customerDetails, setCustomerDetails] = useState<CustomerDetails>();
    const [hasRegions, setHasRegions] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    // const [addressType, setAddressType] = useState('');
    const [actionType, setActionType] = useState('');
    const location = useLocation();
    const { showAlert } = useNotification();
    const navigate = useNavigate();
    const [isRedirectedFromCheckout, setIsRedirectedFromCheckout] = useState<Boolean>(false);
    const [isRedirectedFromBillingCheckout, setIsRedirectedFromBillingCheckout] = useState<Boolean>(false);
    const [isShippingUsedAsBilling, setIsShippingUsedAsBilling] = useState<Boolean>(false);
    //Get Action Type
    useEffect(() => {
        if (location.state?.actionType) {
            const type = location.state?.actionType;
            setActionType(type);
        }
        if (location.state?.redirectedFrom && location.state.redirectedFrom === NEW_ADDRESS_FROM_SHIPPING) {
            setIsRedirectedFromCheckout(true);
            if (location.state?.isBillingDataAvailable) {
                setIsShippingUsedAsBilling(true);
            }
        }
        if (location.state?.redirectedFrom && location.state.redirectedFrom === NEW_ADDRESS_FROM_BILLING) {
            setIsRedirectedFromBillingCheckout(true);

        }
    }, [location.state]);

    //Get List of All Addresses
    useEffect(() => {
        // fetchAddresses();
        if (location.state?.allAddresses) {
            const addressData = location.state?.allAddresses;
            setAddresses(addressData);
        }
    }, [location.state]);

    //Get Current Address Details
    useEffect(() => {
        if (location.state?.currentAddressDetails) {
            const details = location.state?.currentAddressDetails;

            setIsEditMode(true);

            // Update form data using extracted values or fallback to defaults
            setFormData({
                id: details?.id || undefined,
                customer_id: details?.customer_id || undefined,
                street: details?.street || [],
                city: details?.city || details?.city || '',
                region: {
                    region_code: details?.region.region_code || '',
                    region: details?.region.region || '',
                    region_id: details?.region.region_id || undefined
                },
                postcode: details?.postcode || details?.postcode || '',
                country_id: details?.country_id || '',
                region_id: details?.region_id || undefined,
                telephone: details?.telephone || details?.telephone || '',
                firstname: details?.firstname || '',
                lastname: details?.lastname || '',
                default_shipping: details?.default_shipping || false,
                default_billing: details?.default_billing || false,
            });
        }
    }, [location.state]);

    //Get Customer Details
    useEffect(() => {
        if (location.state?.customerDetails) {
            const customerData = location.state.customerDetails;
            setCustomerDetails(customerData);
        }
    }, [location.state]);

    useEffect(() => {
        const getCountries = fetchCountries(showAlert);
        getCountries.then((countries) => {
            setCountries(countries);
        });
    }, []);

    const handleBlur = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent) => {
        const { name, value } = event.target;
        handleValidation(name, value, setFormData, setErrors, true);
    };

    const handleStreetBlur = (index: number, value: string) => {
        const streetError = validateStreet(value, true);

        setErrors(prev => ({
            ...prev,
            street: {
                ...prev.street,
                [index]: streetError  // Store the error for the specific index
            }
        }));

    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent) => {
        const target = event.target as HTMLInputElement;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (target.type === 'checkbox') {
            setFormData(prev => ({ ...prev, [name]: value }));
        } else {
            handleValidation(name, value, setFormData, setErrors);
        }
    };

    const handleStreetChange = (index: number, value: string) => {

        const streetError = validateStreet(value);

        if (value.length <= VALIDATION_CONSTANTS.street.maxLength) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                street: prevFormData.street?.map((item, i) => i === index ? value : item),
            }));
        }

        setErrors(prev => ({
            ...prev,
            street: {
                ...prev.street,
                [index]: streetError  // Store the error for the specific index
            }
        }));
    };

    const addStreetField = () => {
        setFormData((prevFormData) => {
            // Ensure only up to two street fields can be added
            const currentStreets = prevFormData.street || [];
            if (currentStreets.length < 2) {
                return {
                    ...prevFormData,
                    street: [...currentStreets, '']
                };
            }
            return prevFormData;
        });

        //Remove any previous error messages
        setErrors(prev => ({
            ...prev,
            street: {
                ...prev.street,
                [1]: ''  // Store the error for the specific index
            }
        }));
    };

    const removeStreetField = (index: number) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            street: prevFormData.street?.filter((_, i) => i !== index),
        }));
    };

    const setupRegionsForCountry = (countryId: string) => {
        const selectedCountry = countries.find(country => country.id === countryId);
        const regionsAvailable = selectedCountry?.available_regions || [];
        setRegions(regionsAvailable);
        setHasRegions(!!regionsAvailable.length);
    };

    useEffect(() => {
        if (formData.country_id) {
            setupRegionsForCountry(formData.country_id);
        }
    }, [countries, formData.country_id]);

    const handleCountryChange = (event: SelectChangeEvent) => {
        const countryId = event.target.value;
        setFormData(prev => ({
            ...prev,
            country_id: countryId,
        }));
        setupRegionsForCountry(countryId);
    };

    const handleStateChangeSelectedFromDropdown = (event: SelectChangeEvent) => {
        const selectedRegion = regions.find(r => r.id === event.target.value);
        setFormData(prev => ({
            ...prev,
            region: {
                region_code: selectedRegion?.code || '',
                region: selectedRegion?.name || '',
                region_id: selectedRegion ? parseInt(selectedRegion.id, 10) : undefined
            },
            region_id: selectedRegion ? parseInt(selectedRegion.id, 10) : undefined
        }));
    }

    const handleSaveDetails = async () => {
        const isAddingFirstAddress = addresses.length === 0;

        let payload;

        if (isEditMode && formData.id) {
            // If we're editing an existing address, send only the modified address with its ID.
            payload = {
                address: {
                    ...formData,
                    region: {
                        region_id: formData?.region?.region_id || 0,
                        region: formData?.region?.region || '',
                        region_code: formData?.region?.region_code || ''
                    },
                    region_id: formData?.region?.region_id || 0,
                    default_billing: formData?.default_billing,
                    default_shipping: formData?.default_shipping
                }
            };
        } else {
            // If we're adding a new address, send it without an ID.
            payload = {
                address: {
                    ...formData,
                    id: undefined, // Ensure no ID is set for a new address
                    region: {
                        region_id: formData?.region?.region_id || 0,
                        region: formData?.region?.region || '',
                        region_code: formData?.region?.region_code || ''
                    },
                    region_id: formData?.region?.region_id || 0,
                    default_billing: addresses.length === 0 || formData?.default_billing,
                    default_shipping: addresses.length === 0 || formData?.default_shipping
                }
            };
        }

        let updatedAddress = {
            ...formData,
            region: {
                region_id: formData?.region?.region_id || 0,
                region: formData?.region?.region || '',
                region_code: formData?.region?.region_code || ''
            },
            region_id: formData?.region?.region_id || 0,
            default_billing: formData?.default_billing,
            default_shipping: formData?.default_shipping
        };

        if (!isEditMode) {
            // Assuming new addresses don't have an ID yet.
            delete updatedAddress.id;
        }

        const { isValid, newErrors } = validateForm(formData);

        setErrors(newErrors);

        if (isValid) {
            console.log('Form is valid, proceed with saving');
            try {
                if (isRedirectedFromCheckout) {
                    formData.id = Math.random();
                    setLocalStorage("newCreatedAddress", JSON.stringify(formData));
                    setLocalStorage("selectedShipmentAddressId", String(formData.id));
                    if (isShippingUsedAsBilling && formData.isBillingSameAsShipping) {
                        formData.id = Math.random();
                        setLocalStorage("newCreatedBillingAddress", JSON.stringify(formData));
                    }
                    console.log("new form", formData);
                    //  navigate(FURNITURE_CHECKOUT_PATH,{state:{newAddress:formData,newAddressId:formData.id}});
                    navigate(FURNITURE_CHECKOUT_PATH);
                }
                else if (isRedirectedFromBillingCheckout) {
                    formData.id = Math.random();
                    setLocalStorage("newCreatedBillingAddress", JSON.stringify(formData));
                    console.log("new form", formData);
                    //  navigate(FURNITURE_CHECKOUT_PATH,{state:{newAddress:formData,newAddressId:formData.id}});
                    navigate(FURNITURE_CHECKOUT_PATH);
                }
                else {

                    delete payload.address.isBillingSameAsShipping;

                    const resp = await doActionPost({ url: apiList.customerAddress, userType: USER_TYPE.Customer, data: payload });

                    if (resp && resp.data) {
                        showAlert(isEditMode ? ADDRESS_FORM_STRINGS.updateSuccess.en : ADDRESS_FORM_STRINGS.addSuccess.en, ALERT_STATUS.success);
                        // Update local address list
                        if (isEditMode) {
                            setAddresses(addresses.map(addr => addr.id === formData.id ? updatedAddress : addr));

                            //Call The Edit Address GTM Events
                            editAddress_gtm(payload.address)
                        } else {
                            // Add the new address with an id returned from the backend if possible
                            setAddresses([...addresses, { ...updatedAddress, id: resp.data.id }]);

                            //Call The Add New Address GTM Events
                            addNewAddress_gtm(payload.address)
                        }
                        navigate(MY_ACCOUNT_MY_ADDRESSES_LIST_PAGE_ROUTE_PATH);
                    } else {
                        throw new Error(ADDRESS_FORM_STRINGS.updateError.en);
                    }
                }
            } catch (error) {
                const axiosError = error as AxiosError; // Type assertion to handle errors from Axios
                console.error('Failed to update address:', axiosError.message);
                showAlert(axiosError.response?.status === 401 ? ADDRESS_FORM_STRINGS.loginNeeded.en : ADDRESS_FORM_STRINGS.genericUpdateError.en, ALERT_STATUS.error);
                if (axiosError.response?.status === 401) {
                    removeLocalStorage('user-token');
                    manageIndexedDB({ key: 'userToken', toDelete: true })
                    navigate(LOGIN_ROUTE_PATH);
                }
            }
        } else {
            console.log('Form is invalid, check errors');
            showAlert(ADDRESS_FORM_STRINGS.formInvalidError.en, ALERT_STATUS.error);
        }
    };

    return (
        <S.Content>
            <div className="address-title">{actionType} Address</div>
            <div className="address-form-container">
                <InputField type="text" className="input-box" name="firstname" value={formData.firstname} placeholder={INPUT_FIELDS_PLACEHOLDERS.firstname} onBlur={handleBlur} onChange={handleChange} error={errors.firstname} />
                <InputField type="text" className="input-box" name="lastname" value={formData.lastname} placeholder={INPUT_FIELDS_PLACEHOLDERS.lastname} onBlur={handleBlur} onChange={handleChange} error={errors.lastname} />

                {formData.street?.map((street, index) => (
                    <div key={index}>
                        <InputField
                            type="text"
                            className="input-box"
                            name={`street${index}`}
                            value={street}
                            placeholder={INPUT_FIELDS_PLACEHOLDERS.street}
                            onBlur={(e) => handleStreetBlur(index, e.target.value)}
                            onChange={(e) => handleStreetChange(index, e.target.value)}
                            error={errors.street && errors.street[index]}
                        />
                        {index > 0 && (
                            <span className="street-action-button" onClick={() => removeStreetField(index)}>
                                {ADDRESS_FORM_UI_STRINGS.actions.removeStreet.en}
                            </span>
                        )}
                    </div>
                ))}

                {(formData.street || []).length < 2 && (
                    <span className="street-action-button" onClick={addStreetField}>
                        {ADDRESS_FORM_UI_STRINGS.actions.addStreet.en}
                    </span>
                )}

                <InputField type="text" className="input-box" name="city" value={formData.city} placeholder={INPUT_FIELDS_PLACEHOLDERS.city} onBlur={handleBlur} onChange={handleChange} error={errors.city} />
                {/* Country Dropdown */}
                <div className="dropdown-container">
                    <FormControl fullWidth size='small'>
                        <Select displayEmpty labelId="country-select-label" id="country" name="country_id" value={formData.country_id} onChange={handleCountryChange}
                            inputProps={{ 'aria-label': 'Without label' }}
                            renderValue={(selected) => {
                                if (!selected) {
                                    return <span>{ADDRESS_FORM_UI_STRINGS.actions.selectCountry.en}</span>;
                                }
                                return countries?.find(c => c.id === selected)?.full_name_english || 'Select Country';
                            }}
                        >
                            <MenuItem disabled value="">
                                <span>{ADDRESS_FORM_UI_STRINGS.actions.selectCountry.en}</span>
                            </MenuItem>
                            {countries?.length > 0 ? (
                                countries?.map(country => (
                                    <MenuItem key={country?.id} value={country?.id}>{country?.full_name_english}</MenuItem>
                                ))
                            ) : (
                                <MenuItem value="" disabled>
                                    Loading...
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>
                {/* Conditional State Selection */}
                {hasRegions && (
                    <div className="dropdown-container">
                        <FormControl fullWidth size='small'>
                            <Select displayEmpty labelId="state-select-label" id="state" name="region" value={formData.region?.region_id?.toString() || ''} onChange={handleStateChangeSelectedFromDropdown}
                                inputProps={{ 'aria-label': 'Without label' }}
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return <span>{ADDRESS_FORM_UI_STRINGS.actions.selectState.en}</span>;
                                    }
                                    return regions?.find(r => r.id === selected)?.name || 'Select State';
                                }}
                            >
                                <MenuItem disabled value="">
                                    <span>{ADDRESS_FORM_UI_STRINGS.actions.selectState.en}</span>
                                </MenuItem>
                                {regions?.map(region => (
                                    <MenuItem key={region?.id} value={region?.id.toString()}>{region?.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                )}
                <InputField type="text" className="input-box" name="postcode" value={formData.postcode} placeholder={INPUT_FIELDS_PLACEHOLDERS.zip_code} onBlur={handleBlur} onChange={handleChange} error={errors.postcode} />
                <InputField type="tel" className="input-box" name="telephone" value={formData.telephone} placeholder={INPUT_FIELDS_PLACEHOLDERS.telephone} onBlur={handleBlur} onChange={handleChange} error={errors.telephone} />
                {!(isRedirectedFromCheckout || isRedirectedFromBillingCheckout) &&
                    <div className="default-address-checkbox">
                        <InputField
                            label="Default Delivery Address"
                            className="input-checkbox"
                            type="checkbox"
                            name="default_shipping"
                            checked={formData?.default_shipping}
                            onChange={handleChange}
                            disabled={isEditMode && addresses.length === 1}
                        />
                        <InputField
                            label="Default Billing Address"
                            className="input-checkbox"
                            type="checkbox"
                            name="default_billing"
                            checked={formData?.default_billing}
                            onChange={handleChange}
                            disabled={isEditMode && addresses.length === 1}
                        />
                    </div>
                }
                {(isRedirectedFromCheckout || isRedirectedFromBillingCheckout) &&
                    <>
                        <InputField
                            label={SAVE_TO_ADDRESS_BOOK}
                            type="checkbox"
                            name="default_shipping"
                            checked={formData?.default_shipping}
                            onChange={handleChange}
                        />
                    </>
                }
                <div className="save-button-container">
                    <button className="save-button" onClick={handleSaveDetails}
                        disabled={!isValidForm(formData)}
                    >{ADDRESS_FORM_UI_STRINGS.actions.saveButton.en}</button>
                </div>
            </div>
        </S.Content>
    );
};

export default AddressFormNew;
