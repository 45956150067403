export const SHIPPING_ADDRESS_UI = {
    shipping_address_title: {
        en: "Delivery Address",
        ar: "عنوان الشحن"
    },
    add_new: {
        en: "Add New",
        ar: "إضافة جديد"
    },
    saved_addresses: {
        en: "saved addresses",
        ar: "العناوين المحفوظة"
    },
    edit: {
        en: "Edit",
        ar: "تعديل"
    },
    remove: {
        en: "Remove",
        ar: "إزالة"
    },
    set_as_default_billing: {
        en: "Set as default billing",
        ar: ""
    },
    set_as_default_shipping: {
        en: "set as default delivery",
        ar: ""
    },
    no_address_selected: {
        en: "No address selected for editing",
        ar: "لم يتم تحديد عنوان للتحرير"
    },
    default_label: {
        en: "Default",
        ar: "افتراضي"
    },
    street: {
        en: "Street:",
        ar: "الشارع:"
    },
    city: {
        en: "City:",
        ar: "المدينة:"
    },
    state_province_area: {
        en: "State/province/area:",
        ar: "الدولة/المقاطعة/المنطقة:"
    },
    zip_code: {
        en: "Zip code:",
        ar: "الرمز البريدي:"
    },
    country: {
        en: "Country:",
        ar: "البلد:"
    },
    telephone: {
        en: "Telephone:",
        ar: "هاتف:"
    }
};

export const SHIPPING_ADDRESS_MESSAGES = {
    no_address_selected_shipping: {
        en: "No address selected for setting as default delivery.",
        ar: "لم يتم تحديد عنوان لضبطه كعنوان شحن افتراضي."
    },
    no_address_selected_billing: {
        en: "No address selected for setting as default billing.",
        ar: "لم يتم تحديد عنوان لضبطه كعنوان فوترة افتراضي."
    },
    no_address_selected_edit: {
        en: "No address selected for editing",
        ar: "لم يتم تحديد عنوان للتحرير"
    },
    address_update_success: {
        en: " address set as default successfully",
        ar: " تم ضبط العنوان كافتراضي بنجاح"
    },
    update_failed: {
        en: "Failed to set default address. Please try again later.",
        ar: "فشل في ضبط العنوان الافتراضي. حاول مرة أخرى لاحقاً."
    },
    please_log_in: {
        en: "Please log in",
        ar: "الرجاء تسجيل الدخول"
    }
};

