import styled, { css } from "styled-components";
import PatternInGrey from '../../../../globals/images/Background/PatternInGrey.svg';
import { media } from "../../../../helpers/breakpoints";

export const Content = styled.div`
  height: 100vh;
  width: 100%;
  background-image: url(${PatternInGrey});
  background-size: cover; /* Ensures image covers the entire container */
  background-position: center; /* Centers the image horizontally and vertically */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  overflow: hidden;

  .top-logo {
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    width: calc(8.125rem * 2);
  }

  .ms-account-button {
    width: 22rem;
    height: 50px;
    background-color: var(--sand-button-color);
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    border: none;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--white);
    text-transform: none;
    padding: 8px 52px;
    cursor: pointer;
    &:hover {
      background-color: var(--sand-button-color-on-hover);
    }
  }

  .title {
    width: 15.25rem;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #4E4E4E;
    margin-bottom: 1rem;
  }

  .ms-account-button-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 22rem;
  }

  .bottom-logo {
    position: absolute;
    width: 2rem;
    height: 2rem;
    bottom: 2.5rem;
    left: 2.5rem;
  }
`;
