import { FC, useEffect, useState } from "react";
import { formatPriceAndCurrency } from "../../../../helpers/utlis";
import { useNavigate, useParams } from "react-router-dom";
import { Address, OrderItem, paymentValues } from "./types";
import * as S from "./styles/orderDetailsStyles";
import { INFO_LIST, PAYMENT_DETAILS, SHIPMENT_DETAILS, ORDER_DETAILS_PAGE_LABELS, NO_DETAILS_FOUND, SHIPMENT_LIST } from "./constants";
import { capitalizeFirstLetter, getAddress, mergeProducts } from "./orderHelpers";
import apiList, { ALERT_STATUS, GIFT_CART_LABEL, MY_ORDER_DETAIL_HEADING, NO_INVOICE_FOUND, SOMETHING_WENT_WRONG, USER_TYPE } from "../../../../lib/constant";
import useFetchData from "../../../../hooks/useFetchData";
import { CUSTOMER_PENDING_PAYMENT_ROUTE_PATH } from "../../../../routes/routes-constant";
import { Loading } from "../../../atoms/loading/Loading";
import { Box, Tab, Tabs } from "@mui/material";
import { doActionGet } from "../../../../helpers/httpRequest";
import { useNotification } from "../../../../hooks/useNotification";
import { Accordion, AccordionDetails, AccordionSummary } from "../../../molecules/accordion/accordionHelpers";

const ShipmentDetails: React.FC<{invoiceData:any}> = ({invoiceData}) => {
    
    const [expanded, setExpanded] = useState<string>('');
    
    return <S.Content>
        {(invoiceData && invoiceData?.items?.length>0 )?<>
        {invoiceData?.items.map((invoice: any) => {
        return(
            <div >
                <Accordion
                onChange={() => { setExpanded(expanded => expanded === String(invoice?.increment_id) ? '' : String(invoice?.increment_id)) }}
                expanded={expanded === String(invoice?.increment_id)}
                key={String(invoice?.increment_id)}
            >
                <AccordionSummary>
                    <div className="shipment-summary">
                    <div className="text">{`Shipment #${invoice?.increment_id}`}</div>
                    {invoice?.tracks && invoice?.tracks.length>0 &&  <div className="text customer-detail-value">{`(Tracking Id : ${invoice?.tracks[0]?.track_number})`}</div>}
                    </div>
                </AccordionSummary>
                <AccordionDetails >
                            <table className="product-table">
                                <thead >
                                    <tr className="table-info bottom-border table-info-margin ">
                                        {SHIPMENT_LIST.map(title => <th key={title} className="text1 newTittle">{title}</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {mergeProducts(invoice?.items)?.map((item: any) => {
                                        const { sku: itemSku, qty, name, base_price: price, price_incl_tax: subtotal } = item
                                        let variantData: { [key: string]: string } = {}
                                        item?.extension_attributes?.attribute_info?.forEach((variant: any) => {
                                            variantData[variant.label] = variant.value
                                        })
                                        return <tr key={itemSku + 'tr'} className="table-info ">
                                            <td className='text2'><tr>{name}</tr><tr> {variantData && Object.keys(variantData)?.length > 0 && <div className="variant-data">
                                                {"(" + Object.values(variantData)?.join('  ,  ') + ")"}
                                            </div>}</tr></td>
                                            <td className="text2 value">{itemSku}</td>
                                            <td className="text2">{qty}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>    
                </AccordionDetails>
                </Accordion>
            </div> )
         } )}</>:<div>{NO_DETAILS_FOUND}</div>}
    </S.Content>

}

export default ShipmentDetails

