
import { CardElement, PaymentElement, PaymentRequestButtonElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useContext, useEffect, useRef, useState } from 'react';
import { getLocalStorage } from '../../../../../helpers/localStorageUtil';
import { cardStyle } from '../../styles/styles';
import apiList, { ALERT_STATUS, CARD_NAME_PLACEHOLDER, ENTER_CARD_DETAILS, ENTER_CUSTOMER_NAME, PAY_BUTTON_LABEL, SAVED_CARD_CHECKBOX_LABEL, SAVED_CARD_LABEL, SOMETHING_WENT_WRONG, STRIPE_PAYMENT_METHOD_CODE, USER_TYPE } from '../../../../../lib/constant';
import CheckoutButton from '../../../../atoms/checkout-button/CheckoutButton';
import { doActionPost, doActionPut } from '../../../../../helpers/httpRequest';
import { useNotification } from '../../../../../hooks/useNotification';
import { Loading } from '../../../../atoms/loading/Loading';
import { ORDER_FAILURE_PAGE, ORDER_SUCCESS_PAGE } from '../../../../../routes/routes-constant';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearCartLocalStorage, getGrandTotalValue, getTotalValue, gtmDataConstructor } from '../../helper';
import { checkout_payment_info_gtm, secondPaymentCompletion_info_gtm } from '../../../../../helpers/GTM';
import { getStaticConstantValue } from '../../../../../helpers/utlis';
import { DataContext } from '../../../../../Context/AppContext';
import { ACTION_TYPE } from '../../../../../Context/Constant';
import { paymentMethodProps } from '../../types/types';

const PaymentForm: React.FC<paymentMethodProps> = ({isSecondPayment,methodTitle}) => {
  const [disabled, setDisabled] = useState(true);
  const { dispatch } = useContext(DataContext);
  const stripe = useStripe();
  const [isCardSaved,setIsCardSaved] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const customerInputRef = useRef<HTMLInputElement>(null);
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  ///const elements = useElements();
  const [error, setError] = useState(null);
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const [isAPICallRunning, setIsAPICallRunning] = useState(false);
  const { showAlert } = useNotification();
  const navigate = useNavigate();
  const [customerCardName, setCustomerCardName] = useState("");
  const currencyCode = getStaticConstantValue("currency_code");
  const cartData = JSON.parse(getLocalStorage("cartData"));
  let cartSubTotalData = getTotalValue(cartData?.extension_attributes?.cart_total, "grand_total");
  let value = cartSubTotalData ? cartSubTotalData.value : 0;
  let advancPaymentPercentage = cartData?.extension_attributes?.selected_advance_payment_option;
  value = (value > 0 && advancPaymentPercentage && advancPaymentPercentage > 0) ? ((value * advancPaymentPercentage) / 100) : value;
  const location = useLocation();

  var options: any = {
    mode: 'payment',
    amount: location?.state?.isSecondPayment ? (Math.round(Number(location?.state?.totalDue))>0?Math.round(Number(location?.state?.totalDue)):1): Math.round(Number(getGrandTotalValue())),
    currency: currencyCode.toLowerCase(),
    paymentMethodCreation: "manual",
  };
  var elements: any = stripe?.elements(options);
  var paymentElement = elements?.create('payment');
  paymentElement?.mount('#payment-element');

  useEffect(() => {
    if (stripe) {
      console.log("Under stripe ");
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Demo total',
          amount: (location?.state?.isSecondPayment && location?.state?.totalDue )?Math.round(Number(location?.state?.totalDue)) : Math.round(Number(value))
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      console.log("After stripe ");
      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        console.log('canMakePayment result:', result); // Debugging output
        if (result) {
          setPaymentRequest(pr);
        } else {
          console.log('Google Pay not available'); // Debugging output
        }
      }).catch((error) => {
        console.error('Error with canMakePayment:', error); // Debugging output
      });
  
    }
  }, [stripe]);

  var placeOrder = function (event: any) {
    event.preventDefault();
    const billingAddressData = getLocalStorage("billingAddress")?JSON.parse(getLocalStorage("billingAddress")):{};
    if (customerInputRef.current && customerInputRef.current.value ==="") {
       showAlert(ENTER_CUSTOMER_NAME, ALERT_STATUS.error);
    }
    else{
      elements?.submit().then(function () {
        stripe?.createPaymentMethod({
          elements: elements,
          params: {
            billing_details: {
              name:customerInputRef.current?customerInputRef.current.value:"",
              address: {
                line1: billingAddressData?.street && billingAddressData?.street.length > 0 ? billingAddressData?.street[0] : "",
                line2: billingAddressData?.street && billingAddressData?.street.length > 1 ? billingAddressData?.street[1] : "",
                city: billingAddressData?.city,
                postal_code: billingAddressData?.postcode,
                country: billingAddressData?.country_id,
              }
            }
          },
  
  
        }).then(function (result) {
          if (result && result.paymentMethod) {
            setIsAPICallRunning(true);
            if(location?.state?.isSecondPayment){
             //second payment implementation
        if(location?.state?.orderId && location?.state?.totalDue){
          doActionPut({
              url: apiList.setPendingOrder(location?.state?.orderId),
              data:
              {
                  "paymentMethod": {
                      "method": STRIPE_PAYMENT_METHOD_CODE,
                      "extension_attributes": {
                          "paid_amount":location?.state?.totalDue,
                           "method_title": methodTitle,
                          "payment_id":result.paymentMethod.id
                      }
                  }
              },
              userType: USER_TYPE.Customer
          })?.then((resp: any) => {
              if(resp){
                  navigate(ORDER_SUCCESS_PAGE, { state: { orderId: resp?.data?.order_id,isSecondPayment:location?.state?.isSecondPayment,incrementId:resp?.data?.increment_id   } })
                  secondPaymentCompletion_info_gtm(location?.state?.totalDue , location?.state?.orderId, STRIPE_PAYMENT_METHOD_CODE);
              }
              else{
                  showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
              }
          }
          ).catch((error: any) => {
              console.error("error msg", error);
              navigate(ORDER_FAILURE_PAGE, { state: { failureMsg: error?.response?.data?.message } });
              showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
          }).finally(() => {
              setIsAPICallRunning(false);
          })
       }

            }
            else{
             
            doActionPost({
              url: apiList.placeOrder,
              data:
              {
                "paymentMethod": {
                  "method": "stripe_payments",
                  "additional_data": {
                    "save_payment_method": inputRef?.current ?  inputRef?.current?.checked:false,
                    "payment_method": result.paymentMethod.id,
                    "cc_save": inputRef?.current ?  inputRef?.current?.checked:false
                  },
                  "extension_attributes" : {
                    "paid_amount": Number(getGrandTotalValue())
                }
                },
              },
              userType: USER_TYPE.Customer
            })?.then((resp) => {
              if(resp){
                gtmDataConstructor("purchase",STRIPE_PAYMENT_METHOD_CODE,resp?.data);
                clearCartLocalStorage();
                dispatch({ type: ACTION_TYPE.set_item_count, payload: { count: 0 } })
                navigate(ORDER_SUCCESS_PAGE, { state: { orderId: resp?.data?.order_id ,incrementId:resp?.data?.increment_id } })
              }
              else{
                showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
            }
            }
            ).catch((error) => {
              console.error("error msg", error);
              navigate(ORDER_FAILURE_PAGE, { state: { failureMsg: error?.response?.data?.message } });
              //showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
            }).finally(() => {
              setIsAPICallRunning(false);
            })
          }
          }
        });
      });
    }
  }

  return (
    <>
      {isAPICallRunning && <Loading />}
      <form onSubmit={placeOrder}>
      <div className="customer-delivery">
      <div className='card-holder-heading'>{CARD_NAME_PLACEHOLDER}</div>
      <div className="second-content-row">
        <input className="input-box" type="text" placeholder={CARD_NAME_PLACEHOLDER} ref={customerInputRef}></input>
      </div>
        <div id="payment-element"></div>
        {paymentRequest && <PaymentRequestButtonElement options={{paymentRequest}} />}
       
        <div className="consent-section">
          {/* <input className="consent-checkbox" type="checkbox" ref={inputRef} checked={isCardSaved}/> */}
          <input
        className="consent-checkbox"
        type="checkbox"
        ref={inputRef}
      />
          <label className="consent-text">{SAVED_CARD_CHECKBOX_LABEL}</label>
          </div >
          </div>
        <div className="checkout-continue-button payment-button">
          <CheckoutButton isDisabled={!stripe} label={SAVED_CARD_LABEL} checkoutHandler={() => { }} />
        </div>
        </form>
    
    </>
  );
};
export default PaymentForm;
