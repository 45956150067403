import React, { createContext, useContext, useState, ReactNode } from 'react';
import { CustomerData } from '../components/organisms/login/types/types';
import { NotificationListType } from '../components/organisms/notification-listing/types/types';

interface GlobalStateProps {
  encryptedEmail: string | null;
  setEncryptedEmail: (email: string | null) => void;
  customerData: CustomerData | undefined;
  setCustomerData: (customerData: CustomerData | undefined) => void;
  notificationListData:any| undefined;
  setNotificationListData:(notificationListData:any |undefined) =>void;
}

const GlobalStateContext = createContext<GlobalStateProps | undefined>(undefined);

export const useGlobalState = (): GlobalStateProps => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider');
  }
  return context;
};

interface GlobalStateProviderProps {
  children: ReactNode;
}

export const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({ children }) => {
  const [encryptedEmail, setEncryptedEmail] = useState<string | null>(null);
  const [customerData, setCustomerData] = useState<CustomerData | undefined>();
  const [notificationListData,setNotificationListData] = useState<NotificationListType>();

  return (
    <GlobalStateContext.Provider value={{ encryptedEmail, setEncryptedEmail, customerData, setCustomerData ,notificationListData,setNotificationListData}}>
      {children}
    </GlobalStateContext.Provider>
  );
};