import React, { useEffect, useState } from "react";
import * as S from "../styles/styles";
import OrderSuccessLogo from '../../../../globals/svg/order-success-icon.svg';
import OrderHoldLogo from "../../../../globals/svg/holdIcon.svg";
import { CHECKOUT_FIRST_STEP_HEADING, CONTINUE_SHOPPING_LABEL, FINAL_ORDER_MESSAGE_INITIAL_TEXT, FINAL_ORDER_MESSAGE_LAST_TEXT, FINAL_PAYMENT_MESSAGE_INITIAL_TEXT, ORDER_DELIVERY_TRACKING_INITIAL_TEXT, ORDER_DELIVERY_TRACKING_LAST_TEXT, PAYMENT_APPROVAL_PENDING, progressBar, THANK_YOU_TEXT, WE_WILL_CALL_YOU_ONCE_PAYMENT_DONE } from "../../../../lib/constant";
import { useLocation, useNavigate } from "react-router-dom";
import CheckoutButton from "../../../atoms/checkout-button/CheckoutButton";
import { SCAN_ROUTE_PATH } from "../../../../routes/routes-constant";
import CheckoutHeader from "../checkout-header";
import CheckoutProgressBar from "../../../molecules/checkout-progress-bar";

const OrderSuccess: React.FC = () => {
   const[orderId,setOrderId]= useState<string>("");
   const [incrementId,setIncrementId] = useState<string>("");
   const location = useLocation();
   const navigate = useNavigate();

   useEffect(()=>{
        if(location.state?.orderId){
            setOrderId(location.state?.orderId);
        }
        if(location.state?.incrementId){
          setIncrementId(location.state?.incrementId);

        }
   },[location.state])
   
    return (
    <S.Content >
      <CheckoutHeader headerText={CHECKOUT_FIRST_STEP_HEADING}/>
      <CheckoutProgressBar id={3} title={progressBar[2].title}/>
        <div className="order-success">
        {!location?.state?.approvalRequired && <><img  src={OrderSuccessLogo} alt="Order Success Logo" className="order-success-logo" />
        <div className="success-main-heading">{THANK_YOU_TEXT}</div>
        <div className="success-sub-heading">{location?.state?.isSecondPayment?FINAL_PAYMENT_MESSAGE_INITIAL_TEXT :FINAL_ORDER_MESSAGE_INITIAL_TEXT} <span className="order-id" onClick={() => { navigate(`/order/${String(orderId)}`) }}>{"#"+incrementId }</span> { FINAL_ORDER_MESSAGE_LAST_TEXT}</div>
        <div className="success-sub-heading">{ORDER_DELIVERY_TRACKING_INITIAL_TEXT} <span className="order-id" onClick={() => { navigate(`/order/${String(orderId)}`) }}>{ORDER_DELIVERY_TRACKING_LAST_TEXT}</span>        {location?.state?.approvalRequired && <span className="success-sub-heading">{PAYMENT_APPROVAL_PENDING}</span>}</div>
        </>}
        {location?.state?.approvalRequired && <><img  src={OrderHoldLogo} alt="Order Success Logo" className="order-success-logo" />
        <div className="success-main-heading">{THANK_YOU_TEXT}</div>
        <div className="success-sub-heading">{WE_WILL_CALL_YOU_ONCE_PAYMENT_DONE}</div>
        </>}
        </div>
        {/* Footer section */}
        <div className="checkout-continue-button">
            <CheckoutButton isDisabled={false} label={CONTINUE_SHOPPING_LABEL} checkoutHandler={()=>{ navigate(SCAN_ROUTE_PATH);}}/>
             </div>
    </S.Content>
  );
};

export default OrderSuccess;
