import styled, { css } from "styled-components";
import { media } from "../../../../helpers/breakpoints";
import { Button } from "@mui/material";

export const Content = styled.div<any>`
  ${(p: any) => {
    return css`
      .employee-cash-registery{
      margin-top:32px;
      // margin-left:30px;
      // margin-right:30px;
       margin-left:20px;
      margin-right:20px;
      }
      .currency-denomination{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 8px;
        @media screen and ${media.largeMobileAndUpMax2}{
        grid-template-columns: 1fr 1fr 1fr;
      }
      }
      .registery-heading{
      font-weight:500;
      font-size:16px;
      line-height:17.6px;
      color:#6F6855;
      margin-bottom:24px;
      }
      .registery-new-heading{
      font-weight:700;
      font-size:16px;
      line-height:17.6px;
      color:#6F6855;
      margin-bottom:24px;
      }
      .registery-new-print-heading{
      display:none;
      }
      .add-amount-heading{
      font-weight:500;
      font-size:18px;
      line-height:19.8px;
      color:#1C1C1C;
      margin-bottom:24px;
      }
        .add-amount-main-heading{
      font-weight:500;
      font-size:18px;
      line-height:19.8px;
      color:#1C1C1C;
      margin-bottom:8px;
      }
      .add-amount-sub-heading{
       font-weight:400;
      font-size:16px;
      line-height:19.8px;
      color:#1C1C1C;
      margin-bottom:24px;
      }
      .amount-label{
      font-weight:500;
      font-size:10px;
      line-height:15px;
      color:#B6B2A6;
      margin-bottom:4px;
      }
      .amount-input{
        padding:12px 16px 12px 16px;
      border:1px solid #B6B2A6;
      border-radius:40px;
      outline: none; 
      width: 67%;

      }
      .amount-desc{
      display:flex;
      flex-direction:column;
      }
      .numberInput{
      width:88%;
      padding:12px 16px 12px 16px;
      border:1px solid #B6B2A6;
      border-radius:40px;
      outline: none; 
       font-weight:500;
      font-size:14px;
      line-height:15px;
      padding-left:45px;
      }
      .numberInputWithoutSymbol{
      width:88%;
      padding:12px 16px 12px 16px;
      border:1px solid #B6B2A6;
      border-radius:40px;
      outline: none; 
       font-weight:500;
      font-size:14px;
      line-height:15px;
      }
      .input-section{
      position: relative;
      }
      .currency-symbol-section{
       position: absolute; 
       left: 10px;
        top: 8px;
      }
      .received-button-label{
      padding :7px 52px 7px 52px;
      border-radius:100px;
      border:none;
      height:50px;
       font-weight:600;
      font-size:16px;
      line-height:24px;
      color:white;
      background-color:rgba(111, 104, 85, 1);
      }
      .received-disabled-button-label{
       padding :7px 52px 7px 52px;
      border-radius:100px;
      border:none;
      height:50px;
       font-weight:600;
      font-size:16px;
      line-height:24px;
      color:white;
      background-color:rgba(111, 104, 85, 1);
      opacity:50%;
      }
      .amount-denomination{
      margin-top:32px;
      }
      .received-button-label:disabled{
      opacity:50%;
      }
      .button-box{
      display:flex;
      justify-content:center;
      margin-top:60px;
      }
      .cash-summary-section{
        background: var(--white);
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.05);
        padding:16px ;
        border-radius:10px;
  }
        .summary-content-section{
        margin-top:24px;
        }
        .summary-label{
        font-weight:500;
        font-size:16px;
        line-height:17.6px;
        color:rgba(111, 104, 85, 1);
        }
        .summary-row{
        display:flex;
        justify-content:space-between;
        margin-bottom:12px;

        }
        .close-amount{
        margin-top:40px;
        }
     `;
  }}
`;

export const PrintButton = styled(Button)({
  marginLeft: 'auto',
  '@media print': {
      display: 'none',
  },
});

export const PrintWrapper = styled('div')({
  '@media print': {
      margin: '50px',
      padding: '50px',
  },
});

export const globalPrintStyles = `
  @media print {
    .print-space{
    padding:100px;
    }
     .registery-new-print-heading{
      font-weight:700;
      font-size:16px;
      line-height:17.6px;
      color:#6F6855;
      margin-bottom:24px;
      display:block
      }
      .employee-cash-registery{
      margin-top:32px;
      // margin-left:30px;
      // margin-right:30px;
       margin-left:20px;
      margin-right:20px;
      }
      .currency-denomination{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 8px;
        @media screen and ${media.largeMobileAndUpMax2}{
        grid-template-columns: 1fr 1fr 1fr;
      }
      }
      .registery-heading{
      font-weight:500;
      font-size:16px;
      line-height:17.6px;
      color:#6F6855;
      margin-bottom:24px;
      }
      .registery-new-heading{
      font-weight:700;
      font-size:16px;
      line-height:17.6px;
      color:#6F6855;
      margin-bottom:24px;
      }
      .add-amount-heading{
      font-weight:500;
      font-size:18px;
      line-height:19.8px;
      color:#1C1C1C;
      margin-bottom:24px;
      }
        .add-amount-main-heading{
      font-weight:500;
      font-size:18px;
      line-height:19.8px;
      color:#1C1C1C;
      margin-bottom:8px;
      }
      .add-amount-sub-heading{
       font-weight:400;
      font-size:16px;
      line-height:19.8px;
      color:#1C1C1C;
      margin-bottom:24px;
      }
      .amount-label{
      font-weight:500;
      font-size:10px;
      line-height:15px;
      color:#B6B2A6;
      margin-bottom:4px;
      }
      .amount-input{
        padding:12px 16px 12px 16px;
      border:1px solid #B6B2A6;
      border-radius:40px;
      outline: none; 
      width: 67%;

      }
      .amount-desc{
      display:flex;
      flex-direction:column;
      }
      .numberInput{
      width:88%;
      padding:12px 16px 12px 16px;
      border:1px solid #B6B2A6;
      border-radius:40px;
      outline: none; 
       font-weight:500;
      font-size:14px;
      line-height:15px;
      padding-left:45px;
      }
      .numberInputWithoutSymbol{
      width:88%;
      padding:12px 16px 12px 16px;
      border:1px solid #B6B2A6;
      border-radius:40px;
      outline: none; 
       font-weight:500;
      font-size:14px;
      line-height:15px;
      }
      .input-section{
      position: relative;
      }
      .currency-symbol-section{
       position: absolute; 
       left: 10px;
        top: 8px;
      }
      .received-button-label{
      padding :7px 52px 7px 52px;
      border-radius:100px;
      border:none;
      height:50px;
       font-weight:600;
      font-size:16px;
      line-height:24px;
      color:white;
      background-color:rgba(111, 104, 85, 1);
      }
      .amount-denomination{
      margin-top:32px;
      }
      .received-button-label:disabled{
      opacity:50%;
      }
      .button-box{
      display:flex;
      justify-content:center;
      margin-top:60px;
      }
      .cash-summary-section{
        background: var(--white);
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.05);
        padding:16px ;
        border-radius:10px;
  }
        .summary-content-section{
        margin-top:24px;
        }
        .summary-label{
        font-weight:500;
        font-size:16px;
        line-height:17.6px;
        color:rgba(111, 104, 85, 1);
        }
        .summary-row{
        display:flex;
        justify-content:space-between;
        margin-bottom:12px;

        }
        .close-amount{
        margin-top:40px;
        }
      
  }
`;