import { FormControl, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { SAVE_BUTTON_LABEL } from "../../../../../../../lib/constant";
import InputField from "../../../../../../molecules/input/InputField";
import { INPUT_FIELDS_PLACEHOLDERS, VALIDATION_CONSTANTS } from "../../../constants";
import { handleValidation, isValidForm, validateStreet, ValidationErrors } from "../../../shared-components/AddressFormUtility";
import { ADDRESS_FORM_UI_STRINGS } from "../../../shared-components/constants";
import { useState, useEffect } from "react";
import { Address, CountryRegion } from "../../../types/types";
import { initialFormData, myAddressFormProps } from "../../types/types";

const MyAddressForm: React.FC<myAddressFormProps> = ({ addressType, countryData, isNewAddressExpand, setIsNewAddressExpand, newAddressSaveHandler, setDefaultShippingAddressDetails, setDefaultBillingAddressDetails, setIsExpanded }) => {

    const [hasRegions, setHasRegions] = useState(false);
    const [formData, setFormData] = useState<Address>(initialFormData);
    const [errors, setErrors] = useState<ValidationErrors>({});
    const [regions, setRegions] = useState<CountryRegion[]>([]);

    const resetForm = () => {
        setFormData(initialFormData);
        setHasRegions(false);
        setRegions([]);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent) => {
        const target = event.target as HTMLInputElement;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        handleValidation(name, value, setFormData, setErrors);
    };

    const handleBlur = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent) => {
        const { name, value } = event.target;
        handleValidation(name, value, setFormData, setErrors, true);
    };

    const handleStreetBlur = (index: number, value: string) => {
        const streetError = validateStreet(value, true);

        setErrors(prev => ({
            ...prev,
            street: {
                ...prev.street,
                [index]: streetError  // Store the error for the specific index
            }
        }));

    };

    const handleStreetChange = (index: number, value: string) => {

        const streetError = validateStreet(value);

        if (value.length <= VALIDATION_CONSTANTS.street.maxLength) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                street: prevFormData.street?.map((item, i) => i === index ? value : item),
            }));
        }

        setErrors(prev => ({
            ...prev,
            street: {
                ...prev.street,
                [index]: streetError  // Store the error for the specific index
            }
        }));
    };

    const setupRegionsForCountry = (countryId: string) => {
        const selectedCountry = countryData.find(country => country.id === countryId);
        const regionsAvailable = selectedCountry?.available_regions || [];
        setRegions(regionsAvailable);
        setHasRegions(!!regionsAvailable.length);
    };

    useEffect(() => {
        if (formData.country_id) {
            setupRegionsForCountry(formData.country_id);
        }
    }, [countryData, formData.country_id]);

    const handleCountryChange = (event: SelectChangeEvent) => {
        const countryId = event.target.value;
        setFormData(prev => ({
            ...prev,
            country_id: countryId,
        }));
        setupRegionsForCountry(countryId);
    };

    const handleStateChangeSelectedFromDropdown = (event: SelectChangeEvent) => {
        const selectedRegion = regions.find(r => r.id === event.target.value);
        setFormData(prev => ({
            ...prev,
            region: {
                region_code: selectedRegion?.code || '',
                region: selectedRegion?.name || '',
                region_id: selectedRegion ? parseInt(selectedRegion.id, 10) : undefined
            },
            region_id: selectedRegion ? parseInt(selectedRegion.id, 10) : undefined
        }));
    }

    const saveButtonHandler = () => {

        const resp = newAddressSaveHandler(formData);
        if (resp) {
            if (addressType === "Ship" && setDefaultShippingAddressDetails) {
                setDefaultShippingAddressDetails(resp);
            }
            if (addressType === "Bill" && setDefaultBillingAddressDetails) {
                setDefaultBillingAddressDetails(resp);
            }
            resetForm();
        }
        setIsNewAddressExpand(false);
        setIsExpanded(false);

    }

    return (
        <>
            {isNewAddressExpand &&
                <>
                    <div className="input-form">
                        <InputField type="text" className="input-box" name="firstname" value={formData.firstname} placeholder={INPUT_FIELDS_PLACEHOLDERS.firstname} onBlur={handleBlur} onChange={handleChange} error={errors.firstname} />
                        <InputField type="text" className="input-box" name="lastname" value={formData.lastname} placeholder={INPUT_FIELDS_PLACEHOLDERS.lastname} onBlur={handleBlur} onChange={handleChange} error={errors.lastname} />
                        {formData.street?.map((street, index) => (
                            <div key={index}>
                                <InputField
                                    type="text"
                                    className="input-box"
                                    name={`street${index}`}
                                    value={street}
                                    placeholder={INPUT_FIELDS_PLACEHOLDERS.street}
                                    onBlur={(e) => handleStreetBlur(index, e.target.value)}
                                    onChange={(e) => handleStreetChange(index, e.target.value)}
                                    error={errors.street && errors.street[index]}
                                />
                                {index > 0 && (
                                    <></>
                                )}
                            </div>
                        ))}
                        <InputField type="text" className="input-box" name="city" value={formData.city} placeholder={INPUT_FIELDS_PLACEHOLDERS.city} onBlur={handleBlur} onChange={handleChange} error={errors.city} />
                        <InputField type="text" className="input-box" name="postcode" value={formData.postcode} placeholder={INPUT_FIELDS_PLACEHOLDERS.zip_code} onBlur={handleBlur} onChange={handleChange} error={errors.postcode} />
                        <InputField type="tel" className="input-box" name="telephone" value={formData.telephone} placeholder={INPUT_FIELDS_PLACEHOLDERS.telephone} onBlur={handleBlur} onChange={handleChange} error={errors.telephone} />
                        <div className="dropdown-container">
                            <FormControl fullWidth size='small'>
                                <Select displayEmpty labelId="country-select-label" id="country" name="country_id" value={formData.country_id} onChange={handleCountryChange}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    renderValue={(selected) => {
                                        if (!selected) {
                                            return <span>{ADDRESS_FORM_UI_STRINGS.actions.selectCountry.en}</span>;
                                        }
                                        return countryData?.find(c => c.id === selected)?.full_name_english || 'Select Country';
                                    }}
                                >
                                    <MenuItem disabled value="">
                                        <span>{ADDRESS_FORM_UI_STRINGS.actions.selectCountry.en}</span>
                                    </MenuItem>
                                    {countryData?.length > 0 ? (
                                        countryData?.map(country => (
                                            <MenuItem key={country?.id} value={country?.id}>{country?.full_name_english}</MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value="" disabled>
                                            Loading...
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                        {/* Conditional State Selection */}
                        {hasRegions && (
                            <div className="dropdown-container">
                                <FormControl fullWidth size='small'>
                                    <Select displayEmpty labelId="state-select-label" id="state" name="region" value={formData.region?.region_id?.toString() || ''} onChange={handleStateChangeSelectedFromDropdown}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        renderValue={(selected) => {
                                            if (!selected) {
                                                return <span>{ADDRESS_FORM_UI_STRINGS.actions.selectState.en}</span>;
                                            }
                                            return regions?.find(r => r.id === selected)?.name || 'Select State';
                                        }}
                                    >
                                        <MenuItem disabled value="">
                                            <span>{ADDRESS_FORM_UI_STRINGS.actions.selectState.en}</span>
                                        </MenuItem>
                                        {regions?.map(region => (
                                            <MenuItem key={region?.id} value={region?.id.toString()}>{region?.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        )}
                    </div>
                    <div className="button-section">
                        <button className="save-button" onClick={saveButtonHandler} type="button" disabled={!isValidForm(formData)}>{SAVE_BUTTON_LABEL}</button>
                    </div>
                </>
            }
        </>
    )
}

export default MyAddressForm;